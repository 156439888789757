import { Link, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { InfoSquareFill } from 'react-bootstrap-icons';

type Props = {
  input?: string;
  titleText?: string;
  description?: string;
  textComplexityColor?: string;
};

const ComplexityScore = ({
  input = '0',
  titleText = 'Complexity Score',
  description = '',
  textComplexityColor = '#fff',
}: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
    >
      <span
        style={{
          position: 'absolute',
          left: '0',
          color: textComplexityColor,
          top: '100%',
        }}
      >
        <Tooltip
          placement="bottom-end"
          title={
            <Typography variant="caption">
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />{' '}
              <Link
                href="https://de.wikipedia.org/wiki/Lix_(Lesbarkeitsindex)"
                target="__blank"
                color="inherit"
                sx={{
                  color: textComplexityColor,
                  '&:hover': {
                    color: textComplexityColor,
                  },
                }}
                underline="always"
              >
                Link
              </Link>
              .
            </Typography>
          }
        >
          <InfoSquareFill
            className="text-white"
            style={{ marginRight: '.5em' }}
          />
        </Tooltip>

        {`${titleText}: ${input}`}
      </span>
    </Grid>
  );
};

export default ComplexityScore;
