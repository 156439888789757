import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  FileEarmarkFontFill,
  FileEarmarkTextFill,
} from 'react-bootstrap-icons';
import styles from '../TextAreaField.module.css';
import theme from '../../../theme/themeConfig';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { UserSelector } from 'src/redux/user/selector';

type Props = {
  switchInput: (value: boolean) => void;
  fileInput: boolean;
};

const InputTypeChangeButton = ({ switchInput, fileInput }: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        paddingBottom: '1em',
        padding: matchesSM ? 'auto' : '10px 3%',
      }}
    >
      <Grid
        item
        xs="auto"
      >
        <Button
          onClick={() => switchInput(false)}
          className={`${styles['sourceButton']} ${
            !hasGroup ? '' : fileInput ? '' : styles['active']
          }`}
          disabled={!hasGroup}
          sx={{ textTransform: 'capitalize' }}
        >
          <div>
            <FileEarmarkFontFill className={styles['sourceButtonIcon']} />
          </div>
          <div>{t('input.inputTextButton')}</div>
        </Button>
      </Grid>
      <Grid
        item
        xs="auto"
      >
        <Button
          onClick={() => switchInput(true)}
          className={`${styles['sourceButton']} ${
            !hasGroup ? '' : fileInput ? styles['active'] : ''
          }`}
          disabled={!hasGroup}
        >
          <div>
            <FileEarmarkTextFill className={styles['sourceButtonIcon']} />
          </div>
          <div>
            <div style={{ textTransform: 'capitalize' }}>
              {t('input.inputFilesButton')}
            </div>
            {/* <div
              className={styles['bottomText']}
              style={{ textTransform: 'none' }}
            >
              .pdf, .docx, .txt
            </div> */}
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default InputTypeChangeButton;
