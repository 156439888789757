import { LoadingButton } from '@mui/lab';
import { Typography, Grid } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PasswordInput from 'src/coreUI/passwordInput/PasswordInput';
import { setNewPasswordAction } from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';

type Props = {
  style?: object;
  email: string;
};

const ResetPasswordForm = ({ style = {}, email }: Props) => {
  const distpatch: any = useDispatch();
  const [inputValue, setInputValue] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });
  const { confirmPassword, password } = inputValue;

  const { t } = useTranslation('common');

  const loading = useSelector(
    (state: any) => supabaseSelector(state).loading,
    shallowEqual
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue({ ...inputValue, [e?.target.name]: e?.target.value });
  };
  const handleConfirm = async () => {
    await distpatch(setNewPasswordAction(password, email));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      localStorage.removeItem('token');
    });
    return () => {
      window.removeEventListener('beforeunload', (event) => {
        event.preventDefault();
      });
    };
  });

  return (
    <Grid
      item
      container
      xs="auto"
      sx={{ marginTop: '8em', width: 'fit-content', ...style }}
      spacing={1.5}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          {t('setNewPassword.newPasswordPrompt')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h5"
          gutterBottom
        >
          {email}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <PasswordInput
          value={password}
          handleChange={handleChange}
          label={t('signupForm.password')}
          name={'password'}
          style={{ width: '24em' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <PasswordInput
          value={confirmPassword}
          handleChange={handleChange}
          label={t('signupForm.passwordConfirm')}
          name={'confirmPassword'}
          style={{ width: '24em' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={isEmpty(password) || !isEqual(password, confirmPassword)}
          onClick={handleConfirm}
          sx={{ textTransform: 'capitalize' }}
          disableElevation
        >
          {t('setNewPassword.confirm')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;
