import { LoadingButton } from '@mui/lab';
import React, { RefObject, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { newUserSignup } from 'src/redux/user/actions';
import { UserSelector } from 'src/redux/user/selector';
import { isEmpty } from 'lodash';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { useNavigate } from 'react-router-dom';

type Props = {};

const CompanyField = (props: Props) => {
  const { t } = useTranslation(['common', 'A11y'], {
    nsMode: 'fallback',
  });
  const history = useNavigate();

  const forenameRef: RefObject<any> = useRef();
  const surnameRef: RefObject<any> = useRef();
  const companyName: RefObject<any> = useRef();
  const categoryRef: RefObject<any> = useRef();
  const categoryFreeTextRef: RefObject<any> = useRef();
  const [showCategoryFreeText, setShowCategoryFreeText] =
    useState<boolean>(false);

  const dispatch: any = useDispatch();

  const loading = useSelector(
    (state: any) => UserSelector(state).loading,
    shallowEqual
  );

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  async function handleSubmit() {
    // store all data before signing up
    const categoryText =
      categoryRef.current.value === 'other'
        ? categoryFreeTextRef.current.value
        : categoryRef.current.value;
    if (
      !isEmpty(forenameRef.current.value) &&
      !isEmpty(surnameRef.current.value) &&
      !isEmpty(companyName.current.value) &&
      !isEmpty(categoryText)
    ) {
      const newUserData = {
        forename: forenameRef.current.value,
        surname: surnameRef.current.value,
        category: categoryText,
        company_name: companyName.current.value,
        terms_accepted: true,
        data_privacy_accepted: true,
        email: supabaseUser?.email,
      };
      if (localStorage.getItem('signup_form')) {
        await dispatch(newUserSignup(newUserData, history));
      }
    }
  }

  const handleRoleChange = (e: React.ChangeEvent<any>) => {
    if (e?.target.value === 'other') {
      setShowCategoryFreeText(true);
    } else {
      setShowCategoryFreeText(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="forename">
              <Form.Label>{t('signupForm.firstName')}</Form.Label>
              <Form.Control
                type="text"
                ref={forenameRef}
                required
                id="forename"
              />
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>{t('signupForm.lastName')}</Form.Label>
              <Form.Control
                type="text"
                ref={surnameRef}
                required
                id="surname"
              />
            </Form.Group>
            <Form.Group controlId="companyName">
              <Form.Label>{t('signupForm.companyName')}</Form.Label>
              <Form.Control
                type="text"
                ref={companyName}
                required
                id="companyName"
              />
            </Form.Group>
            <Form.Group controlId="categorySelect">
              <Form.Label>{t('signupForm.role')}</Form.Label>
              <Form.Control
                as="select"
                ref={categoryRef}
                aria-label={t('roleSelect')}
                onChange={handleRoleChange}
                required
                id="categorySelect"
                style={{
                  background: '#fff',
                }}
              >
                <option value="translator">{t('signupForm.translator')}</option>
                <option value="officeManager">
                  {t('signupForm.officeManagement')}
                </option>
                <option value="other">{t('signupForm.other')}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group
              hidden={!showCategoryFreeText}
              controlId="categoryFreeText"
            >
              <Form.Label>{t('signupForm.specifyRole')}</Form.Label>
              <Form.Control
                type="text"
                ref={categoryFreeTextRef}
                required={showCategoryFreeText}
                id="categoryFreeText"
              />
            </Form.Group>
            <LoadingButton
              type="button"
              loading={loading}
              variant="contained"
              // disabled={isEmpty(otpValue)}
              sx={{ textTransform: 'none', p: '8px 3em' }}
              id="otp-submit"
              onClick={handleSubmit}
            >
              {t('signupForm.complete_register')}
            </LoadingButton>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyField;
