import React from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { CloudUpload } from 'react-bootstrap-icons';
import styles from './imageUpload.module.css';
import { LangWords } from './image.types';
type Props = {
  isDragAccept: boolean;
  isDragReject: boolean;
  open: () => void;
  dragRef: any;
  langWords: LangWords;
};

const DragDropComp = ({
  isDragAccept,
  isDragReject,
  open,
  dragRef,
  langWords,
}: Props) => {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        justifyContent: 'center',
        background: theme.palette.secondary.main,
        padding: 2,
        borderWidth: 2,
        borderRadius: 1,
        borderColor: isDragAccept
          ? theme.palette.primary.main
          : isDragReject
            ? theme.palette.error.main
            : 'transparent',
        borderStyle: 'dashed',
      }}
    >
      <Grid
        item
        xs={12}
        mb={2}
      >
        <CloudUpload size={'2em'} />
      </Grid>
      <Grid
        item
        xs={12}
        mb={2}
      >
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            whiteSpace: 'normal',
          }}
        >
          {langWords.dragUploadImage}
        </Typography>
      </Grid>
      <Grid
        item
        xs={11}
        mb={2}
      >
        <div className={styles['separator']}>
          &nbsp;&nbsp;{langWords.dragAndDropSplitterText}&nbsp;&nbsp;
        </div>
      </Grid>
      <Grid
        item
        xs={11}
      >
        <Button
          variant="contained"
          sx={{ textTransform: 'none' }}
          onClick={open}
          fullWidth
          disableElevation
        >
          {langWords.buttonUpload}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DragDropComp;
