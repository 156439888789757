import React from 'react';
import { createContext, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import styles from './SynonymsField.module.css';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import { SynonymsFieldProps, SynonymsContextType } from './synonyms.types';
import SynonymsList from './SynonymsList';
import SynonymsSource from './SynonymsSource';

export const SynonymsFieldContext = createContext({});
const SynonymsField = ({
  getSynonymsData,
  emptyStateValueSynonyms,
  refreshSession,
  generalAlertMessage,
  noWordEnteredAlertMessage,
  multipleWordEnteredAlertMessage,
  synonymsList,
  inputPlaceholder,
  loadingText,
  sourceText,
  loadSynonymsText,
  loading,
  style = {},
  copyText,
  copiedText,
  emptyListText,
}: SynonymsFieldProps) => {
  // states
  const [showAlert, setShowAlert] = useState(false);
  const [synonymsInputState, setSynonymsInputState] = useState('');
  const [alertMessage, setAlertMessage] = useState(generalAlertMessage);
  // const theme = useTheme()
  // methods
  const isSynonymInputOnlyOneWord = (originalRange: string) => {
    if (originalRange?.trim() === '' || /\s/g.test(originalRange?.trim())) {
      let alertMsg =
        originalRange?.trim() === ''
          ? noWordEnteredAlertMessage
          : multipleWordEnteredAlertMessage;
      setAlertMessage(alertMsg);
      setShowAlert(true);
      emptyStateValueSynonyms({ synonymsList: null });
      return false;
    } else {
      return true;
    }
  };

  const getSynonyms = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const originalRange = synonymsInputState;
    if (!isSynonymInputOnlyOneWord(originalRange)) {
      return;
    }
    refreshSession();
    await getSynonymsData(originalRange);
  };

  const resetSynomysList = () => {
    setSynonymsInputState('');
    setShowAlert(false);
    emptyStateValueSynonyms({ synonymsList: null });
  };

  const handleClearInput = () => {
    setSynonymsInputState('');
    emptyStateValueSynonyms({ synonymsList: null });
  };

  const value: SynonymsContextType = useMemo(
    () => ({ copiedText, copyText }),
    [copiedText, copyText]
  );

  // ui
  return (
    <SynonymsFieldContext.Provider value={value}>
      {/* <div style={{ ...style, color: '#000' }}> */}
      <Grid
        item
        container
        xs={12}
        spacing={1}
      >
        {showAlert && (
          <Grid
            item
            xs={12}
          >
            <Alert
              severity="error"
              onClose={() => setShowAlert(false)}
              className="alertFontSize"
            >
              {' '}
              {alertMessage}
            </Alert>
          </Grid>
        )}
        {/* <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1,mr:0 },
            width:'100%',
          }}
          noValidate
          autoComplete="off"
        >  */}
        <Grid
          item
          xs={12}
        >
          <TextField
            id="formSynonymsInput"
            label={inputPlaceholder}
            value={synonymsInputState}
            type="text"
            onChange={(e: any) => {
              if (isEmpty(e.target.value)) {
                resetSynomysList();
              } else {
                setSynonymsInputState(e.target.value);
                setShowAlert(false);
              }
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                getSynonyms(e);
              }
            }}
            className={styles['input-synonyms']}
            size="small"
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-notchedOutline': {
                paddingRight: 0,
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#374d5d !important',
              },
              '& .Mui-focused': {
                color: '#374d5d !important',
              },
            }}
            InputProps={{
              endAdornment: !isEmpty(synonymsInputState) ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearInput}
                    size="small"
                  >
                    <CloseIcon sx={{ color: '#374d5d' }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button
            variant="contained"
            onClick={getSynonyms}
            disabled={loading}
            className={styles['load-synonyms-button']}
            type="submit"
            fullWidth
            sx={{
              textTransform: 'initial',
              letterSpacing: '0.5px',
              backgroundColor: '#374d5d !important',
              '&:hover': { backgroundColor: '#374d5d !important' },
            }}
          >
            {/* In leichte Sprache übersetzen */}
            {loading ? (
              <>
                <CircularProgress
                  size={20}
                  sx={{ color: '#fff' }}
                />
                <span style={{ color: '#fff', marginLeft: '5px' }}>
                  {loadingText}...
                </span>
              </>
            ) : (
              <span>{loadSynonymsText}</span>
            )}
          </Button>
        </Grid>
        {synonymsList ? (
          synonymsList.length > 0 ? (
            <Grid
              item
              xs={12}
            >
              <SynonymsList synonyms={synonymsList} />
            </Grid>
          ) : (
            emptyListText && (
              <Grid
                item
                xs={12}
              >
                <Alert
                  severity="success"
                  color="warning"
                  icon={false}
                  sx={{ justifyContent: 'center' }}
                >
                  {emptyListText}
                </Alert>
              </Grid>
            )
          )
        ) : null}
        <Grid
          item
          xs={12}
        >
          <SynonymsSource sourceText={sourceText} />
        </Grid>
      </Grid>
      {/* </div> */}
    </SynonymsFieldContext.Provider>
  );
};

export default SynonymsField;
