import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InfoSquareFill } from 'react-bootstrap-icons';
import styles from './TranslationInput.module.css';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

type Props = {};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    fontSize: '.8rem',
  },
});

const TranslationInputHeader = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        marginBottom: '0.2rem !important',
      }}
      id="originaltext-info-header"
    >
      <Grid
        item
        xs={5}
        className={styles['tooltip-col']}
      >
        <CustomWidthTooltip
          placement="bottom-end"
          className={styles['translation-textarea-infobox']}
          title={
            <>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{
                  __html: t('originaltext.warning', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </>
          }
        >
          <InfoSquareFill className="text-white translation-info-box" />
        </CustomWidthTooltip>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left',
        }}
      >
        <Form.Label
          className={
            'text-light ' + styles['language-label'] + ' input-box-lang-label'
          }
        >
          {t('originaltext.label')}
        </Form.Label>
      </Grid>
    </Grid>
  );
};

export default TranslationInputHeader;
