import OrderFormService from 'src/services/orderForm.service';
import {
  LOADING,
  STOP_LOADING,
  GET_USER_PREFILL,
  USER_PURCHASE,
} from './types';
import { OrderData } from 'src/types/orderForm.types';
import Sentry from '../../config/sentryConfig';
import { appMessageHandling } from 'src/utils/errorHandler';

export const getUserPrefill = () => async (dispatch: any) => {
  try {
    const res = await OrderFormService.getUserPrefillAPI();
    return dispatch({
      type: GET_USER_PREFILL,
      payload: res.data,
    });
  } catch (e: any) {
    return Sentry.captureMessage(`Get user prefill error: ${e}`, 'error');
  }
};

export const userPurchase = (data: OrderData) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await OrderFormService.userPurchaseAPI(data);
    localStorage.setItem('IN_PURCHASE_FLOW', 'false');
    return dispatch({
      type: USER_PURCHASE,
    });
  } catch (e: any) {
    appMessageHandling(
      dispatch,
      'Es gab einen Fehler bei der Bestellung. Bitte versuchen Sie es erneut.',
      'error'
    );
    return Sentry.captureMessage(`User purchase error: ${e}`, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
