import {
  GridCsvGetRowsToExportParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { GlossaryEntriesSelector } from 'src/redux/glossaryEntries/selector';
import RowToDisplay from './RowToDisplay';
import { useCallback, useEffect, useState } from 'react';
import { getGlossaryEtnries } from 'src/redux/glossaryEntries/actions';

type Props = {};

const CustomeToolbar = (props: Props) => {
  const [entries, setEntries] = useState('10');
  const dispatch: any = useDispatch();

  const glossaryList = useSelector(
    (state: any) => GlossaryEntriesSelector(state).glossaryEntriesList,
    shallowEqual
  );

  const filterValue = useSelector(
    (state: any) => GlossaryEntriesSelector(state).filters,
    shallowEqual
  );

  const fetchFilterUpdate = useCallback(() => {
    dispatch(
      getGlossaryEtnries({
        page: 1,
        page_size: Number(entries),
        ...filterValue,
      })
    );
  }, [dispatch, entries, filterValue]);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      fetchFilterUpdate();
    }, 500);

    return () => clearTimeout(timeOut);
  }, [fetchFilterUpdate]);

  return (
    <GridToolbarContainer sx={{ height: '4em' }}>
      <GridToolbarExport
        csvOptions={{
          fileName: 'Erklaerungen',
          getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
            return Object.values(glossaryList).map(
              (item: { id: number }) => item.id
            );
          },
        }}
      />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <RowToDisplay
        setEntries={setEntries}
        entries={entries}
      />
    </GridToolbarContainer>
  );
};

export default CustomeToolbar;
