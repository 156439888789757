import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FixMe } from '../../types/types';
import styles from './GlossaryEditTextarea.module.css';
import { useGridApiContext } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { updateGlossaryEntry } from 'src/redux/glossaryEntries/actions';

type Props = {
  id: string | number;
  value: string;
  field: string;
  activeId: string | number;
  row: FixMe;
  headerName: string;
  role?: string;
};

const GlossaryEditTextarea = ({
  id,
  value,
  field,
  activeId,
  row,
  headerName,
  role = undefined,
}: Props) => {
  const { t } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  const apiRef = useGridApiContext();
  const dispatch: any = useDispatch();
  const [userEditText, setUserEditText] = useState(value);

  const handleChange = async (event: FixMe) => {
    apiRef.current.setEditCellValue(
      { id, field, value: event?.target.value },
      event
    );
    setUserEditText(event?.target.value);
  };

  const handleRef = (element: FixMe) => {
    if (row.id === activeId && element) {
      document
        ?.querySelector<HTMLTextAreaElement>(`textarea#edit-textarea-${id}`)
        ?.focus();
    }
  };

  const handleSaveButton = async (event: FixMe) => {
    // send put request
    if (field === 'word') {
      await updateGlossaryListState(userEditText, row.explanation);
    } else if (field === 'explanation') {
      await updateGlossaryListState(row.word, userEditText);
    }
    apiRef.current.stopCellEditMode({
      id: id,
      field: field,
    });
  };

  const updateGlossaryListState = (word: FixMe, explanation: FixMe) => {
    dispatch(
      updateGlossaryEntry({
        glossaryID: row.id,
        word: word,
        explanation: explanation,
      })
    );
  };

  return (
    <Form.Group
      className={styles['edit-container']}
      role={role}
    >
      <Form.Control
        id={`edit-textarea-${id}`}
        ref={handleRef}
        as="textarea"
        value={value}
        onChange={handleChange}
        onKeyDown={(e: FixMe) => e.stopPropagation()}
        rows={row.id === activeId ? 10 : 2}
        style={{ resize: 'none' }}
        aria-label={`${t('word')} '${row.word}': ${headerName} ${t('edit')}`}
      />
      <Button
        className={styles['save-button']}
        onClick={handleSaveButton}
      >
        {t('save')}
      </Button>
    </Form.Group>
  );
};

export default GlossaryEditTextarea;
