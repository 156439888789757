import { Col, Form, Row } from 'react-bootstrap';

const ContainerField = (props) => {
  return (
    <Row>
      <Col className="col-4 text-left mb-2">
        <Form.Label column={true}>{props.label}</Form.Label>
      </Col>
      <Col>
        <Form.Control
          placeholder={props.value}
          disabled
        />
      </Col>
    </Row>
  );
};

export default ContainerField;
