export const CREATE_GLOSSARY = 'CREATE_GLOSSARY';
export const SEARCH_GLOSSARY = 'SEARCH_GLOSSARY';
export const LOADING = 'LOADING_GLOSSARY';
export const STOP_LOADING = 'STOP_LOADING_GLOSSARY';
export const LOADING_AI = 'LOADING_GLOSSARY_AI';
export const STOP_LOADING_AI = 'STOP_LOADING_GLOSSARY_AI';
export const SHOW_AI_SEARCH_UNAUTH_ALERT = 'SHOW_AI_SEARCH_UNAUTH_ALERT';
export const AI_WORD_EXPLANATION = 'AI_WORD_EXPLANATION';
export const GET_GLOSSARY_TAGS = 'GET_GLOSSARY_TAGS';
export const DELETE_GLOSSARY = 'DELETE_GLOSSARY';
export const EMPTY_STATE_VALUE = 'EMPTY_STATE_VALUE_GLOSSARY';
export const SELECTED_TAGS_LIST = 'SELECTED_TAGS_LIST';
export const OWN_EXPLANATIONS = 'OWN_EXPLANATIONS';
export const SHOW_GLOSSARY_COPY_RESTRICTION_ALERT =
  'SHOW_GLOSSARY_COPY_RESTRICTION_ALERT';
export const DIFFICULT_WORDS_TO_SHOW = 'DIFFICULT_WORDS_TO_SHOW';
export const DIFFICULT_WORDS = 'DIFFICULT_WORDS';
export const UPDATE_GLOSSARY_ITEM = 'UPDATE_GLOSSARY_ITEM';
