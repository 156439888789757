import React from 'react';
import ClearInputPlugin from './ClearInputPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useSelector, shallowEqual } from 'react-redux';
import { TranslateSelector } from 'src/redux/translation/selector';

type Props = {};

const ClearPluginWrapper = (props: Props) => {
  const emptyInput = useSelector(
    (state: any) => TranslateSelector(state).emptyInput,
    shallowEqual
  );

  return !emptyInput ? (
    <>
      <ClearInputPlugin />
      <ClearEditorPlugin />
    </>
  ) : (
    <></>
  );
};

export default ClearPluginWrapper;
