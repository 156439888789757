import { createSelector } from 'reselect';

export interface UserPrefillReducer {
  loading: boolean;
  userPrefil: null | object;
  purchase: boolean;
}
interface RootState {
  userPrefill: UserPrefillReducer;
}

// This used to memorize the selector value
export const UserPrefillSelector = createSelector(
  (state: RootState) => state.userPrefill,
  (config: UserPrefillReducer) => {
    return {
      loading: config.loading,
      userPrefil: config.userPrefil,
      purchase: config.purchase,
    };
  }
);
