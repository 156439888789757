import { useContext } from 'react';
import styles from './TranslationOutput.module.css';
import CustomeSelectMenu from 'src/coreUI/customeSelect/CustomeSelect';
import { langOption } from '../fixedData';
import { useSelector, shallowEqual } from 'react-redux';
import { UserSelector } from 'src/redux/user/selector';
import { TranslationOutputContext } from './TranslationOutput';
import { Form } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

type Props = {};

const SelectLangMenu = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const { outputLanguage, handleOutputLanguageTypeChange } = useContext(
    TranslationOutputContext
  );
  const userPermissionsEasy = useSelector(
    (state: any) => UserSelector(state).userPermissionsEasy,
    shallowEqual
  );
  const userPermissionsPlain = useSelector(
    (state: any) => UserSelector(state).userPermissionsPlain,
    shallowEqual
  );

  return (
    <Grid
      item
      md={7}
      // xs="auto"
      textAlign={'start'}
      sx={{
        whiteSpace: 'nowrap',
        //   overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {userPermissionsPlain || userPermissionsEasy ? (
        <CustomeSelectMenu
          options={langOption(userPermissionsEasy, userPermissionsPlain, t)}
          handleOnClick={(value) => {
            handleOutputLanguageTypeChange(value);
          }}
          defaultSelected={outputLanguage}
          ariaLabel={t('toolbox.separator.selectSeparator')}
          classes={'w-auto d-inline-flex ' + styles['output-dropdown']}
        />
      ) : (
        <Form.Label
          className={'text-light label-height ' + styles['language-label']}
          style={{ textTransform: 'capitalize' }}
        >
          {t('output.easyLang')}
        </Form.Label>
      )}
    </Grid>
  );
};

export default SelectLangMenu;
