import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

type Props = {
  loading: boolean;
  children: any;
  size?: string;
};

const LoadingHOC = ({ loading, children, size = '20px' }: Props) => {
  return loading ? (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        size={size}
        aria-label={'loading'}
      />
    </Box>
  ) : (
    children
  );
};

export default LoadingHOC;
