import feedbackService from '../../services/feedback.service';
import { FeedbackFormInput } from '../../types/feedback.types';
import { appMessageHandling } from '../../utils/errorHandler';
import { SEND_FEEDBACK, LOADING, STOP_LOADING } from './types';

export const sendFeedback =
  (data: FeedbackFormInput) => async (dispatch: any) => {
    try {
      const res = await feedbackService.createFeedback(data);
      dispatch({
        type: SEND_FEEDBACK,
        payload: res,
      });
      return appMessageHandling(
        dispatch,
        'success.create_feedback_success',
        'success'
      );
    } catch (e: any) {
      return appMessageHandling(
        dispatch,
        'error.create_feedback_error',
        'error'
      );
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
