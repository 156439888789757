import {
  SummaryInput,
  SummaryReducer,
} from '@summ-ai-github/summ-frontend-package/components/sumaryFieldComp';
import SummaryField from '@summ-ai-github/summ-frontend-package/components/sumaryFieldComp';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SummarySelector } from '../../redux/summary/selector';
import { TranslateSelector } from '../../redux/translation/selector';
import { HistorySelector } from '../../redux/translationHistory/selector';
import { useTranslation } from 'react-i18next';
import {
  getSummary,
  resetSummary,
  updateSummary,
} from 'src/redux/summary/actions';

type Props = {
  inputTextValue: string;
};

const SummaryCompWrapper = ({ inputTextValue }: Props) => {
  const { t } = useTranslation(['summary', 'A11y'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const loading = useSelector(
    (state: any) => SummarySelector(state).loading,
    shallowEqual
  );

  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const summarySelector = useSelector(
    (state: any) => SummarySelector(state).summary,
    shallowEqual
  );

  return (
    <SummaryField
      inputTextValue={inputTextValue}
      getSummary={(data: SummaryInput) => {
        dispatch(getSummary(data));
      }}
      resetSummary={(data: SummaryReducer) => {
        dispatch(resetSummary(data));
      }}
      updateSummary={(data: { id: number; feedback: number }) => {
        dispatch(updateSummary(data));
      }}
      transjob={transjob}
      historyJob={historyJob}
      loading={loading || false}
      summarySelector={summarySelector}
      localizedTexts={{
        noSummaryText: t('noSummary'),
        regenerateText: t('regenerate'),
        likeText: t('like'),
        dislikeText: t('dislike'),
        regenerate_summary_aria_label: t('regenerate_summary'),
        like_summary_feedback_aria_label: t('like_summary_feedback'),
        dislike_summary_feedback_aria_label: t('dislike_summary_feedback'),
      }}
    />
  );
};

export default SummaryCompWrapper;
