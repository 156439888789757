import summaryService from 'src/services/sumary.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  CLEAR_SUMMARY,
  CREATE_SUMMARY,
  LOADING,
  STOP_LOADING,
  UPDATE_SUMMARY,
} from './types';
import Sentry from '../../config/sentryConfig';
import { SummaryInput } from 'src/types/summary.types';
import { SummaryReducer } from './selector';

export const getSummary = (data: SummaryInput) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const res = await summaryService.createSummaryApi(data);
    dispatch({
      type: CREATE_SUMMARY,
      payload: res.data,
    });
    return appMessageHandling(
      dispatch,
      'success.create_summary_success',
      'success'
    );
  } catch (e: any) {
    Sentry.captureMessage(`Create summary Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, 'error.create_summary_error', 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const updateSummary =
  (data: { id: number; feedback: number }) => async (dispatch: any) => {
    try {
      const res = await summaryService.updateSummaryApi(data);
      dispatch({
        type: UPDATE_SUMMARY,
        payload: res.data,
      });
      return appMessageHandling(
        dispatch,
        'success.update_summary_success',
        'success'
      );
    } catch (e: any) {
      Sentry.captureMessage(`Update sumaary Api Key Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        'error.update_summary_error',
        'error'
      );
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const resetSummary = (value: SummaryReducer) => (dispatch: any) => {
  return dispatch({
    type: CLEAR_SUMMARY,
    payload: value,
  });
};
