import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'src/redux/supabase/actions';

const SignoutButton = () => {
  const history = useNavigate();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  async function handleLogout(e) {
    e.preventDefault();
    try {
      await dispatch(logoutAction());
      history('/');
    } catch {
      console.log('lgoout error');
    }
  }

  return (
    <Button
      variant="light"
      className="w-50 text-center"
      type="submit"
      onClick={handleLogout}
    >
      {t('logout')}
    </Button>
  );
};

export default SignoutButton;
