import Grid from '@mui/material/Grid';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { InfoSquareFill } from 'react-bootstrap-icons';
import styles from './TranslationOutput.module.css';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

type Props = {};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '.8rem',
  },
});

const InfoIcon = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Grid
      item
      md={3.5}
      // xs="auto"
      textAlign={'left'}
    >
      <CustomWidthTooltip
        className={styles['translation-textarea-infobox']}
        title={
          <>
            <div
              className="text-left"
              dangerouslySetInnerHTML={{
                __html: t('warningAlert', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </>
        }
        placement="bottom-start"
      >
        <InfoSquareFill className="text-white translation-info-box" />
      </CustomWidthTooltip>
    </Grid>
  );
};

export default InfoIcon;
