import { Grid, IconButton, Tooltip } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CopyButtomComp from '../../coreUI/copyButtonComp/CopyButtomComp';
import { summaryOutput } from './summary.types';
import React from 'react';

type Props = {
  getTheSummary: (use_cache: boolean) => void;
  summarySelector: summaryOutput | null;
  updateSummary: (data: { id: number; feedback: number }) => void;
  regenerateText: string;
  likeText: string;
  dislikeText: string;
  regenerate_summary_aria_label: string;
  like_summary_feedback_aria_label: string;
  dislike_summary_feedback_aria_label: string;
};

const ActionButton = ({
  getTheSummary,
  updateSummary,
  summarySelector,
  regenerateText,
  likeText,
  dislikeText,
  regenerate_summary_aria_label,
  like_summary_feedback_aria_label,
  dislike_summary_feedback_aria_label,
}: Props) => {
  const handleClick = (value: number) => {
    if (summarySelector) {
      updateSummary({ id: summarySelector.id, feedback: value });
    }
  };
  return (
    summarySelector && (
      <Grid
        item
        container
        xs={12}
        display={'flex'}
        justifyContent={'end'}
      >
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={regenerateText}
            placement="top"
          >
            <IconButton
              aria-label={regenerate_summary_aria_label}
              onClick={() => getTheSummary(false)}
            >
              <AutorenewIcon
                fontSize="small"
                sx={{ color: '#374d5d !important' }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={likeText}
            placement="top"
          >
            <IconButton
              aria-label={like_summary_feedback_aria_label}
              onClick={() => handleClick(1)}
            >
              {summarySelector && summarySelector.feedback === 1 ? (
                <ThumbUpIcon
                  fontSize="small"
                  sx={{ color: '#374d5d !important' }}
                />
              ) : (
                <ThumbUpOffAltIcon
                  fontSize="small"
                  sx={{ color: '#374d5d !important' }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={dislikeText}
            placement="top"
          >
            <IconButton
              aria-label={dislike_summary_feedback_aria_label}
              onClick={() => handleClick(0)}
            >
              {summarySelector && summarySelector.feedback === 0 ? (
                <ThumbDownIcon
                  fontSize="small"
                  sx={{ color: '#374d5d !important' }}
                />
              ) : (
                <ThumbDownOffAltIcon
                  fontSize="small"
                  sx={{ color: '#374d5d !important' }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <CopyButtomComp
            value={summarySelector && summarySelector.translated_summary}
            buttonStyle={{ position: 'relative' }}
            size="small"
          />
        </Grid>
      </Grid>
    )
  );
};

export default ActionButton;
