import React, { Dispatch, SetStateAction } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { FeedbackText } from '../../fixedData';
import { useTranslation } from 'react-i18next';

type Props = {
  updateSimpleTextUserEdited: (value: FeedbackText) => void;
  setShowTooltipOutput: (value: boolean) => void;
  setShowFeedbackButtons: (value: boolean) => void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
};

const AdjustButtonComp = ({
  updateSimpleTextUserEdited,
  setShowTooltipOutput,
  setShowFeedbackButtons,
  setShowSubmitAdjustedButton,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Form.Group>
      <Button
        className="mt-2 w-100"
        onClick={() => {
          updateSimpleTextUserEdited('adjusted');
          setShowTooltipOutput(false);
        }}
      >
        {t('feedback.edit.submitSuggestion')} <Pencil />
      </Button>
      <Button
        className="mt-2 w-100"
        onClick={() => {
          setShowSubmitAdjustedButton(false);
          setShowFeedbackButtons(true);
          setShowTooltipOutput(false);
        }}
      >
        {t('back', { ns: 'common' })}
      </Button>
    </Form.Group>
  );
};

export default AdjustButtonComp;
