import CircularProgress from '@mui/material/CircularProgress';
import { Ref, useRef, useState } from 'react';
import { ArrowUp, FileEarmarkText } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import styles from './DragAndDropField.module.css'; // Import styles as an object
import { Alert } from 'react-bootstrap';
import theme from '../../../theme/themeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { refreshSession } from 'src/redux/supabase/actions';
import { shallowEqual, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { UserSelector } from 'src/redux/user/selector';
import Sentry from 'src/config/sentryConfig';
import { editorValueClear } from '../helper';

type Props = {
  switchInput: (value: boolean) => void;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  editorValue: (text: string, refEditor: Ref<any>, id: string) => void;
  inputRefEditor: Ref<any>;
};
const DragAndDropField = ({
  setInputText,
  switchInput,
  editorValue,
  inputRefEditor,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [extractActive, setExtractActive] = useState(false);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const supabaseSession = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  // input ref for keyboard choice
  const inputRef: Ref<any> = useRef(null);

  const extractTextFromFile = async (file: File) => {
    if (hasGroup) {
      dispatch(refreshSession());
      setExtractActive(true);
      setFileName(file.name);

      api.extractTextFromFile(
        supabaseSession?.access_token,
        { file: file },
        (result: { text: string }) => {
          editorValueClear(inputRefEditor);
          editorValue(result.text, inputRefEditor, 'translation-input');
          setExtractActive(false);
          // pass text to translation field
          setInputText(result.text);
          // enable translation field
          switchInput(false);
        },
        (error: any) => {
          Sentry.captureMessage(
            `Error extracting text from file: ${error}`,
            'error'
          );
          setErrorMessage(t('input.alertMessage'));
          setExtractActive(false);
        }
      );
    }
  };

  // handle drag events
  const handleDrag = (e: any) => {
    if (hasGroup) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    if (hasGroup) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        extractTextFromFile(e.dataTransfer.files[0]);
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    if (hasGroup) {
      e.preventDefault();
      if (e?.target.files && e?.target.files[0]) {
        extractTextFromFile(e?.target.files[0]);
      }
    }
  };

  const renderElement = () => {
    if (errorMessage !== '') {
      return (
        <div className={styles['drag-pane']}>
          <Alert
            variant="error"
            dismissible
            onClose={() => setErrorMessage('')}
          >
            <Alert.Heading>{t('input.alertHeader')}</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        </div>
      );
    } else if (dragActive === true) {
      return (
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className={styles['drag-pane']}
        >
          <label>
            <div>
              <ArrowUp className={styles['drag-icon']} />
              <div className={styles['upload-text']}>
                {t('input.dragAndDropText')}
              </div>
            </div>
          </label>
        </div>
      );
    } else if (extractActive === true) {
      return (
        <div className={styles['main-container']}>
          <label className={styles['label-file-upload']}>
            <div>
              <FileEarmarkText className={styles['upload-icon']} />
              <div className={styles['extract-text-file-name']}>{fileName}</div>
              <div className={styles['extract-text']}>
                {t('input.extractText')}
              </div>
              <div className={styles['extract-text-wait']}>
                {t('input.extractTextWait')}
              </div>
              <CircularProgress aria-label={t('uploading_file')} />
            </div>
          </label>
        </div>
      );
    } else {
      return (
        <form
          className={styles['main-container']}
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
          style={{
            padding: matchesSM ? '3px 0' : '0px 20px',
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className={styles['input-file-upload']}
            multiple={true}
            onChange={handleChange}
            disabled={!hasGroup}
          />
          <label
            className={styles['label-file-upload']}
            htmlFor="input-file-upload"
          >
            <div>
              <FileEarmarkText className={styles['upload-icon']} />
              <div className={styles['upload-text']}>
                {t('input.dragAndDropText')}
              </div>
              <div className={styles['separator']}>
                &nbsp;&nbsp;{t('input.dragAndDropSplitterText')}&nbsp;&nbsp;
              </div>
              <Button
                onClick={onButtonClick}
                disabled={!hasGroup}
              >
                {t('input.dragAndDropButtonText')}
              </Button>
            </div>
          </label>
        </form>
      );
    }
  };

  return renderElement();
};

export default DragAndDropField;
