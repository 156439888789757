import {
  LOADING,
  STOP_LOADING,
  GET_UNTRANSLATED_WORD,
  DELETE_UNTRANSLATED_WORD,
  CREATE_UNTRANSLATED_WORD,
  SEARCH_UNTRANSLATED_WORD,
  SEARCH_STOP_LOADING,
  SERACH_LOADING,
  RESET_STATE_VALUE,
} from './types';

const initialState: any = {
  ownWordList: null,
  companyWordList: null,
  loading: false,
  loadingSearch: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_UNTRANSLATED_WORD:
      return {
        ...state,
        words: payload,
      };
    case DELETE_UNTRANSLATED_WORD: {
      let check = payload.is_owner;
      let wordList = check
        ? { ...(state?.ownWordList ?? {}) }
        : { ...(state?.companyWordList ?? {}) };
      delete wordList[payload.id];
      return {
        ...state,
        ...(check ? { ownWordList: wordList } : { companyWordList: wordList }),
      };
    }
    case CREATE_UNTRANSLATED_WORD: {
      let check = payload.results.is_owner;
      let wordList = check
        ? {
            ...(state?.ownWordList ?? {}),
            [payload.results.id]: payload.results,
          }
        : {
            ...(state?.companyWordList ?? {}),
            [payload.results.id]: payload.results,
          };

      return {
        ...state,
        ...(check ? { ownWordList: wordList } : { companyWordList: wordList }),
      };
    }
    case SEARCH_UNTRANSLATED_WORD:
      let ownWordList = {};
      let companyWordList = {};
      payload.results.own_words?.forEach((item: { id: number }) => {
        ownWordList = {
          ...ownWordList,
          [item.id]: item,
        };
      });

      payload.results.company_words?.forEach((item: { id: number }) => {
        companyWordList = {
          ...companyWordList,
          [item.id]: item,
        };
      });
      return {
        ...state,
        ownWordList,
        companyWordList,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SERACH_LOADING:
      return {
        ...state,
        loadingSearch: true,
      };
    case SEARCH_STOP_LOADING:
      return {
        ...state,
        loadingSearch: false,
      };
    case RESET_STATE_VALUE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
