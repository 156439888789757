import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { ReactI18NextChild, useTranslation } from 'react-i18next';

type Props = {
  quotaSpent: ReactI18NextChild | Iterable<ReactI18NextChild>;
  quotaLimit: ReactI18NextChild | Iterable<ReactI18NextChild>;
  onHide: any;
  show: boolean;
};

const GiveFeedbackModal = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Modal
      {...props}
      show={props.show}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('feedback.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {t('feedback.modal.quotaUsage1')}
          <strong>
            {props.quotaSpent} {t('feedback.modal.quotaUsage2')}{' '}
            {props.quotaLimit} {t('feedback.modal.quotaUsage3')}
          </strong>
          .
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t('feedback.modal.text', {
                interpolation: { escapeValue: false },
              }),
            }}
          ></span>
          {/* Sie haben{' '}
          <strong>
            {props.quotaSpent} Zeichen von Ihren verfügbaren {props.quotaLimit}{' '}
            Zeichen verbraucht
          </strong>
          .
          <br />
          Sie bekommen weitere Zeichen gutgeschrieben, wenn Sie uns Feedback zu
          den Übersetzungen geben.
          <br />
          Wir benötigen Ihr Feedback, um unsere automatische Übersetzung für Sie
          zu verbessern.
          <br />
          <br />
          Bitte gehen Sie auf Ihre Übersetzungs-Historie und geben Sie Feedback
          zu den ausstehenden Übersetzungen:{' '} */}
          <Link to="/translation-history">
            {t('feedback.toTranslationHistory')}
          </Link>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t('close', { ns: 'common' })}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GiveFeedbackModal;
