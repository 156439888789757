export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const GET_ALL_UPLOADED_IMAGE = 'GET_ALL_UPLOADED_IMAGE';
export const SEARCH_UPLOADED_IMAGE = 'SEARCH_UPLOADED_IMAGE';
export const UPLOAD_LIST_IMAGE = 'UPLOAD_LIST_IMAGE';
export const LOADING = 'LOADING_IMAGE_UPLOAD';
export const STOP_LOADING = 'STOP_LOADING_IMAGE_UPLOAD';
export const RESET_IMAGE_UPLOAD_STATE = 'RESET_IMAGE_UPLOAD_STATE';
export const SET_UPLOAD_IMAGE_LIST = 'SET_UPLOAD_IMAGE_LIST';
export const FEEDBACK_IMAGE = 'FEEDBACK_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_SET = 'DELETE_IMAGE_SET';
