import { SynonymsReducer } from './selector';
import {
  EMPTY_STATE_VALUE,
  GET_SYNONYMS,
  LOADING,
  STOP_LOADING,
} from './types';

const initialState: SynonymsReducer = {
  synonymsList: null,
  loading: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_SYNONYMS:
      return {
        ...state,
        synonymsList: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case EMPTY_STATE_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}
