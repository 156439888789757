import MaterialSelectComp from '../../coreUI/materialSelect/MaterialSelectComp';
import { SelectChangeEvent } from '@mui/material';
import { dataType, outputLanguageType } from './ouputTextSetting.types';
import React, { useContext } from 'react';
import { SettingCompContext } from './SettingComp';

type Props = {
  showFirstTimeUpdateSnackbar: () => void;
  separatorType: dataType;
  separatorLabel: string;
  toolTipsTitle: string;
  outputLanguage: outputLanguageType;
  handleSetSeparatorStyle: (e: SelectChangeEvent<any>) => void;
  separateVal: string;
};

const SeparatorComp = ({
  separateVal,
  separatorLabel,
  toolTipsTitle,
  separatorType,
  outputLanguage,
  handleSetSeparatorStyle,
}: Props) => {
  const { selectStyle } = useContext(SettingCompContext);
  const handleSeparatorChange = (e: SelectChangeEvent<any>) => {
    handleSetSeparatorStyle(e);
  };

  return (
    <MaterialSelectComp
      menuItem={separatorType}
      handleChange={handleSeparatorChange}
      value={separateVal}
      // disabled={outputLanguage === 'plain'}
      id="separator-type-select"
      toolTipsTitle={undefined}
      label={separatorLabel}
      formStyle={{ width: '100%', ...selectStyle }}
    />
  );
};

export default SeparatorComp;
