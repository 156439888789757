import { Grid } from '@mui/material';
import React from 'react';

type Props = {
  input?: number;
  titleText?: string;
  textCharactersColor?: string;
};

const CharactersCount = ({
  input = 0,
  titleText = 'Character count',
  textCharactersColor = '#fff',
}: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
    >
      <span
        style={{
          position: 'absolute',
          right: '0',
          color: textCharactersColor,
          top: '100%',
          // bottom: 0,
        }}
        id="char-count"
      >
        {`${titleText}: ${input}`}
      </span>
    </Grid>
  );
};

export default CharactersCount;
