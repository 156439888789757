import http from '../config/httpComman';

export default class featureService {
  static async getFeatures() {
    return await http.get('features/notifications/');
  }

  static async updateFeature(value: number) {
    return await http.put('features/notifications/', {
      feature_id: value,
      seen: true,
    });
  }
}
