import { t } from 'i18next';
import moment from 'moment';
import DateTimePickerComp from 'src/coreUI/dateTimePickerComp/DateTimePickerComp';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import { FilterValue } from 'src/types/transHistory.types';
import SearchIcon from '@mui/icons-material/Search';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';

export const inputComponentConfig = (
  handleConfirmFIlter: (data: FilterValue) => void,
  filter: any,
  type: string
) => {
  return {
    label: t('operators.contain', { ns: 'common' }),
    value: 'contains',
    InputComponent: InputComponent,
    InputComponentProps: {
      name: type,
      id: type,
      value: filter?.value ?? '',
      label: t('search', { ns: 'common' }),
      endIcon: (
        <SearchIcon
          fontSize={'medium'}
          sx={{ color: '#919191' }}
        />
      ),
      style: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderBottom: '1px solid #919191',
          height: '53px',
        },
        '& .MuiInputBase-root': {
          borderRadius: 0,
        },
        '& .MuiInputLabel-root': {
          lineHeight: '2.4375em',
        },
        '& .MuiOutlinedInput-input': {
          lineHeight: '2.4375em',
          padding: '15.5px 14px 11px',
        },
      },
      handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        handleConfirmFIlter({
          field: type,
          value: e?.target.value,
          operation: 'contains',
        });
      },
    },
    getApplyFilterFn: () => {
      // we don't use the function it just the Material UI
      // table require it we dpend on backend for filter
      return null;
    },
  };
};

export const dataComponentConfig = (
  handleConfirmFIlter: (data: FilterValue) => void,
  filter: any,
  operation: 'after' | 'before' | 'is'
) => {
  return {
    InputComponent: DateTimePickerComp,
    InputComponentProps: {
      views: ['year', 'month', 'day'],
      value: filter?.actualValue ?? '',
      handleConfirm: (value: any) => {
        handleConfirmFIlter({
          field: 'timestamp_created',
          value: moment(new Date(value)).format('DD.MM.YYYY'),
          operation: operation,
          actualValue: value,
        });
      },
    },
    getApplyFilterFn: () => {
      // we don't use the function it just the Material UI
      // table require it we dpend on backend for filter
      return null;
    },
  };
};

export const selectComponentConfig = (
  filter: any,
  menu: { id: string; value: any }[],
  handleConfirmFIlter: (data: FilterValue) => void,
  type: string
) => {
  return {
    label: t('operators.equal', { ns: 'common' }),
    value: 'contains',
    InputComponent: MaterialSelectComp,
    InputComponentProps: {
      value: filter?.value ?? '',
      menuItem: menu,
      type: 'button',
      variant: 'outlined',
      label: t('Select'),
      handleChange: (e: any) =>
        handleConfirmFIlter({
          field: type,
          value: e?.target.value,
          operation: 'equal',
        }),
      formStyle: {
        mt: 0,
        mb: 0,
        mr: 0,
        ml: 0,
        width: '100%',
        '& .MuiFormLabel-root': {
          top: 8,
        },
      },
      style: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderBottom: '1px solid #919191',
          height: '53px',
        },
        borderRadius: 0,
        '& .MuiInputLabel-root': {
          lineHeight: '2.4375em',
        },
        '& .MuiOutlinedInput-input': {
          lineHeight: '2.4375em',
          // padding: '11.5px 14px 0px',
        },
        '& .MuiSvgIcon-root': {
          top: 'calc(50% - 0.4em)',
        },
      },
    },
    getApplyFilterFn: () => {
      // we don't use the function it just the Material UI
      // table require it we dpend on backend for filter
      return null;
    },
  };
};
