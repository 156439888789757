import { Dispatch, Ref, SetStateAction, useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FeedbackText, negativeFeedbackOpt } from '../../fixedData';
import ShowNefFeedbackInputComp from './ShowNefFeedbackInputComp';
import styles from './UserFeedback.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  showFeedbackModal: boolean;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
  negFeedback: any;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
};

const ShowFeedbackModal = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const [showNegFeedbackInput, setShowNegFeedbackInput] =
    useState<boolean>(false); // state that controls visibility of text area : used to show text area only if user selectes Andere option
  const [disableFeedbackSubmit, setDisableFeedbackSubmit] =
    useState<boolean>(false);
  const negFeedbackText: Ref<any> = useRef(); // ref to negative feedback text area
  const onChangeNegFeedback = () => {
    setDisableFeedbackSubmit(props.negFeedback?.current.value === 'other');
    setShowNegFeedbackInput(props.negFeedback?.current.value === 'other');
  };
  return (
    <Modal
      show={props.showFeedbackModal}
      onHide={() => props.setShowFeedbackModal(false)}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form.Group controlId="negativeFeedbackOptions">
          <Form.Label>{t('feedback.negative.reasonQuestion')}</Form.Label>
          <Form.Control
            ref={props.negFeedback}
            className={styles['neg-dropdown']}
            onChange={onChangeNegFeedback}
            defaultValue="wrongContent"
            as="select"
          >
            {negativeFeedbackOpt.map(({ id, text, value }) => (
              <option
                key={id}
                className={styles['neg-dropdown']}
                value={value}
              >
                {t(text)}
              </option>
            ))}
          </Form.Control>
          {showNegFeedbackInput === true && (
            <ShowNefFeedbackInputComp
              setDisableFeedbackSubmit={setDisableFeedbackSubmit}
              negFeedbackText={negFeedbackText}
            />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => props.setShowFeedbackModal(false)}
        >
          {t('close', { ns: 'common' })}
        </Button>
        <Button
          variant="primary"
          disabled={disableFeedbackSubmit}
          onClick={() => {
            let feedback;
            if (props.negFeedback?.current.value === 'other') {
              feedback = negFeedbackText?.current.value;
            } else {
              feedback = props.negFeedback?.current.value;
            }
            props.updateSimpleTextUserEdited('negative', feedback);
            props.setShowFeedbackModal(false);
          }}
        >
          {t('submit', { ns: 'common' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowFeedbackModal;
