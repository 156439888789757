import {
  GET_USER_PREFILL,
  LOADING,
  STOP_LOADING,
  USER_PURCHASE,
} from './types';

const initialState: any = {
  userPrefil: null,
  purchase: false,
  loading: false,
};

export default function userPrefillReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_PREFILL:
      return {
        ...state,
        userPrefil: payload,
      };
    case USER_PURCHASE:
      return {
        ...state,
        purchase: true,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
