const privacy_string = `
<h1>Datenschutzerklärung</h1>
<p>
  Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat
  einen besonders hohen Stellenwert für die Geschäftsleitung der SUMM AI GmbH.
  Eine Nutzung der Internetseiten der SUMM AI GmbH ist grundsätzlich
  ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene
  Person besondere Services unseres Unternehmens über unsere Internetseite in
  Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener
  Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten
  erforderlich und besteht für eine solche Verarbeitung keine gesetzliche
  Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
</p>
<p>
  Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der
  Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt
  stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung
  mit den für die SUMM AI GmbH geltenden landesspezifischen
  Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser
  Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns
  erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.
  Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die
  ihnen zustehenden Rechte aufgeklärt.
</p>
<p>
  Die SUMM AI GmbH hat als für die Verarbeitung Verantwortlicher zahlreiche
  technische und organisatorische Maßnahmen umgesetzt, um einen möglichst
  lückenlosen Schutz der über diese Internetseite verarbeiteten
  personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte
  Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein
  absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es
  jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen
  Wegen, beispielsweise telefonisch, an uns zu übermitteln.
</p>
<p><strong>1. Begriffsbestimmungen</strong></p>
<p>
  Die Datenschutzerklärung der SUMM AI GmbH beruht auf den Begrifflichkeiten,
  die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
  Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
  Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere
  Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
  gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
</p>
<p>
  Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden
  Begriffe:
</p>
<p>a)    personenbezogene Daten</p>
<p>
  Personenbezogene Daten sind alle Informationen, die sich auf eine
  identifizierte oder identifizierbare natürliche Person (im Folgenden
  „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person
  angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
  Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
  Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck
  der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
  kulturellen oder sozialen Identität dieser natürlichen Person sind,
  identifiziert werden kann.<br />
</p>
<p>b)    betroffene Person</p>
<p>
  Betroffene Person ist jede identifizierte oder identifizierbare natürliche
  Person, deren personenbezogene Daten von dem für die Verarbeitung
  Verantwortlichen verarbeitet werden.<br />
</p>
<p>c)    Verarbeitung</p>
<p>
  Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
  ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
  personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
  Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
  Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
  eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
  Einschränkung, das Löschen oder die Vernichtung.<br />
</p>
<p>d)    Einschränkung der Verarbeitung</p>
<p>
  Einschränkung der Verarbeitung ist die Markierung gespeicherter
  personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
  einzuschränken.<br />
</p>
<p>e)    Profiling</p>
<p>
  Profiling ist jede Art der automatisierten Verarbeitung personenbezogener
  Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden,
  um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
  beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung,
  wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen,
  Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
  Person zu analysieren oder vorherzusagen.<br />
</p>
<p>f)     Pseudonymisierung</p>
<p>
  Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise,
  auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
  Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
  werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt
  werden und technischen und organisatorischen Maßnahmen unterliegen, die
  gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
  oder identifizierbaren natürlichen Person zugewiesen werden.<br />
</p>
<p>g)    Verantwortlicher oder für die Verarbeitung Verantwortlicher</p>
<p>
  Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche
  oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein
  oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
  personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
  Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten
  vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten
  Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
  Mitgliedstaaten vorgesehen werden.<br />
</p>
<p>h)    Auftragsverarbeiter</p>
<p>
  Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde,
  Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
  Verantwortlichen verarbeitet.<br />
</p>
<p>i)      Empfänger</p>
<p>
  Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung
  oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig
  davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die
  im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
  Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten,
  gelten jedoch nicht als Empfänger.<br />
</p>
<p>j)      Dritter</p>
<p>
  Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
  andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem
  Auftragsverarbeiter und den Personen, die unter der unmittelbaren
  Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind,
  die personenbezogenen Daten zu verarbeiten.<br />
</p>
<p>k)    Einwilligung</p>
<p>
  Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten
  Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung
  in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden
  Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
  Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden
  ist.<br />
</p>
<p>
  <strong>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</strong>
</p>
<p>
  Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
  Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und
  anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:<br />
</p>
<p>SUMM AI GmbH</p>
<p>Faberstr. 8c</p>
<p>81373 München</p>
<p>Deutschland</p>
<p>Tel.: 01783674624</p>
<p>E-Mail: team@summ-ai.com</p>
<p>Website: <a href="https://summ-ai.com">https://summ-ai.com</a></p>
<p><strong>3. Cookies</strong></p>
<p>
  Die Internetseiten der SUMM AI GmbH verwenden Cookies. Cookies sind
  Textdateien, welche über einen Internetbrowser auf einem Computersystem
  abgelegt und gespeichert werden.
</p>
<p>
  Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
  enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige
  Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche
  Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden
  können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten
  Internetseiten und Servern, den individuellen Browser der betroffenen Person
  von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden.
  Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID
  wiedererkannt und identifiziert werden.
</p>
<p>
  Durch den Einsatz von Cookies kann die SUMM AI GmbH den Nutzern dieser
  Internetseite nutzerfreundlichere Services bereitstellen, die ohne die
  Cookie-Setzung nicht möglich wären.
</p>
<p>
  Mittels eines Cookies können die Informationen und Angebote auf unserer
  Internetseite im Sinne des Benutzers optimiert werden. Cookies ermöglichen
  uns, wie bereits erwähnt, die Benutzer unserer Internetseite wiederzuerkennen.
  Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer
  Internetseite zu erleichtern. Der Benutzer einer Internetseite, die Cookies
  verwendet, muss beispielsweise nicht bei jedem Besuch der Internetseite erneut
  seine Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
  Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein weiteres
  Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop
  merkt sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat,
  über ein Cookie.
</p>
<p>
  Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite
  jederzeit mittels einer entsprechenden Einstellung des genutzten
  Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft
  widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen
  Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in
  allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die
  Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen
  nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar.
</p>
<p><strong>4. Erfassung von allgemeinen Daten und Informationen</strong></p>
<p>
  Die Internetseite der SUMM AI GmbH erfasst mit jedem Aufruf der
  Internetseite durch eine betroffene Person oder ein automatisiertes System
  eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten
  und Informationen werden in den Logfiles des Servers gespeichert. Erfasst
  werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom
  zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von
  welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte
  Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System auf
  unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines
  Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse
  (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems und
  (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
  von Angriffen auf unsere informationstechnologischen Systeme dienen.
</p>
<p>
  Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die SUMM AI GmbH
  keine Rückschlüsse auf die betroffene Person. Diese Informationen werden
  vielmehr benötigt, um (1) die Inhalte unserer Internetseite korrekt
  auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für
  diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
  informationstechnologischen Systeme und der Technik unserer Internetseite zu
  gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines
  Cyberangriffes die zur Strafverfolgung notwendigen Informationen
  bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch
  die SUMM AI GmbH daher einerseits statistisch und ferner mit dem Ziel
  ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu
  erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten
  personenbezogenen Daten sicherzustellen. Die anonymen Daten der
  Server-Logfiles werden getrennt von allen durch eine betroffene Person
  angegebenen personenbezogenen Daten gespeichert.
</p>
<p><strong>5. Registrierung auf unserer Internetseite</strong></p>
<p>
  Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für
  die Verarbeitung Verantwortlichen unter Angabe von personenbezogenen Daten zu
  registrieren. Welche personenbezogenen Daten dabei an den für die Verarbeitung
  Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen
  Eingabemaske, die für die Registrierung verwendet wird. Die von der
  betroffenen Person eingegebenen personenbezogenen Daten werden ausschließlich
  für die interne Verwendung bei dem für die Verarbeitung Verantwortlichen und
  für eigene Zwecke erhoben und gespeichert. Der für die Verarbeitung
  Verantwortliche kann die Weitergabe an einen oder mehrere Auftragsverarbeiter,
  beispielsweise einen Paketdienstleister, veranlassen, der die
  personenbezogenen Daten ebenfalls ausschließlich für eine interne Verwendung,
  die dem für die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.
</p>
<p>
  Durch eine Registrierung auf der Internetseite des für die Verarbeitung
  Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP) der
  betroffenen Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der
  Registrierung gespeichert. Die Speicherung dieser Daten erfolgt vor dem
  Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert werden
  kann, und diese Daten im Bedarfsfall ermöglichen, begangene Straftaten
  aufzuklären. Insofern ist die Speicherung dieser Daten zur Absicherung des für
  die Verarbeitung Verantwortlichen erforderlich. Eine Weitergabe dieser Daten
  an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche Pflicht zur
  Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.
</p>
<p>
  Die Registrierung der betroffenen Person unter freiwilliger Angabe
  personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen dazu,
  der betroffenen Person Inhalte oder Leistungen anzubieten, die aufgrund der
  Natur der Sache nur registrierten Benutzern angeboten werden können.
  Registrierten Personen steht die Möglichkeit frei, die bei der Registrierung
  angegebenen personenbezogenen Daten jederzeit abzuändern oder vollständig aus
  dem Datenbestand des für die Verarbeitung Verantwortlichen löschen zu lassen.
  Dies kann formlos per E-Mail an
  <a href="mailto:team@summ-ai.com">team@summ-ai.com</a> geschehen.
</p>
<p>
  Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person
  jederzeit auf Anfrage Auskunft darüber, welche personenbezogenen Daten über
  die betroffene Person gespeichert sind. Ferner berichtigt oder löscht der für
  die Verarbeitung Verantwortliche personenbezogene Daten auf Wunsch oder
  Hinweis der betroffenen Person, soweit dem keine gesetzlichen
  Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der Mitarbeiter des für
  die Verarbeitung Verantwortlichen stehen der betroffenen Person in diesem
  Zusammenhang als Ansprechpartner zur Verfügung.
</p>
<p><strong>6. Abonnement unseres Newsletters</strong></p>
<p>
  Auf der Internetseite der SUMM AI GmbH wird den Benutzern die Möglichkeit
  eingeräumt, den Newsletter unseres Unternehmens zu abonnieren. Welche
  personenbezogenen Daten bei der Bestellung des Newsletters an den für die
  Verarbeitung Verantwortlichen übermittelt werden, ergibt sich aus der hierzu
  verwendeten Eingabemaske.
</p>
<p>
  Die SUMM AI GmbH informiert ihre Kunden und Geschäftspartner in
  regelmäßigen Abständen im Wege eines Newsletters über Angebote des
  Unternehmens. Der Newsletter unseres Unternehmens kann von der betroffenen
  Person grundsätzlich nur dann empfangen werden, wenn (1) die betroffene Person
  über eine gültige E-Mail-Adresse verfügt und (2) die betroffene Person sich
  für den Newsletterversand registriert. An die von einer betroffenen Person
  erstmalig für den Newsletterversand eingetragene E-Mail-Adresse wird aus
  rechtlichen Gründen eine Bestätigungsmail im Double-Opt-In-Verfahren
  versendet. Diese Bestätigungsmail dient der Überprüfung, ob der Inhaber der
  E-Mail-Adresse als betroffene Person den Empfang des Newsletters autorisiert
  hat.
</p>
<p>
  Bei der Anmeldung zum Newsletter speichern wir ferner die vom
  Internet-Service-Provider (ISP) vergebene IP-Adresse des von der betroffenen
  Person zum Zeitpunkt der Anmeldung verwendeten Computersystems sowie das Datum
  und die Uhrzeit der Anmeldung. Die Erhebung dieser Daten ist erforderlich, um
  den(möglichen) Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem
  späteren Zeitpunkt nachvollziehen zu können und dient deshalb der rechtlichen
  Absicherung des für die Verarbeitung Verantwortlichen.
</p>
<p>
  Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten
  werden ausschließlich zum Versand unseres Newsletters verwendet. Ferner
  könnten Abonnenten des Newsletters per E-Mail informiert werden, sofern dies
  für den Betrieb des Newsletter-Dienstes oder eine diesbezügliche Registrierung
  erforderlich ist, wie dies im Falle von Änderungen am Newsletterangebot oder
  bei der Veränderung der technischen Gegebenheiten der Fall sein könnte.
  Wir versenden unsere Newsletter mithilfe von MailChimp, einer Newsletter-Versandplattform des US-Anbieters Rocket
  Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA. Sie können die
  Datenschutzbestimmungen des Versanddienstleisters hier einsehen: <a
    href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>. Der Versanddienstleister wird
  auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO und eines
  Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.
  Das Abonnement unseres Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die Einwilligung in
  die Speicherung personenbezogener Daten, die die betroffene Person uns für den
  Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke
  des Widerrufs der Einwilligung findet sich in jedem Newsletter ein
  entsprechender Link.
</p>
<p><strong>7. Newsletter-Tracking</strong></p>
<p>
  Die Newsletter der SUMM AI GmbH enthalten sogenannte Zählpixel. Ein
  Zählpixel ist eine Miniaturgrafik, die in solche E-Mails eingebettet wird,
  welche im HTML-Format versendet werden, um eine Logdatei-Aufzeichnung und eine
  Logdatei-Analyse zu ermöglichen. Dadurch kann eine statistische Auswertung des
  Erfolges oder Misserfolges von Online-Marketing-Kampagnen durchgeführt werden.
  Anhand des eingebetteten Zählpixels kann die SUMM AI GmbH erkennen, ob und
  wann eine E-Mail von einer betroffenen Person geöffnet wurde und welche in der
  E-Mail befindlichen Links von der betroffenen Person aufgerufen wurden.
</p>
<p>
  Solche über die in den Newslettern enthaltenen Zählpixel erhobenen
  personenbezogenen Daten, werden von dem für die Verarbeitung Verantwortlichen
  gespeichert und ausgewertet, um den Newsletterversand zu optimieren und den
  Inhalt zukünftiger Newsletter noch besser den Interessen der betroffenen
  Person anzupassen. Betroffene Personen sind jederzeit berechtigt, die
  diesbezügliche gesonderte, über das Double-Opt-In-Verfahren abgegebene
  Einwilligungserklärung zu widerrufen. Dies kann formlos per E-Mail an
  <a href="mailto:team@summ-ai.com">team@summ-ai.com</a> geschehen. Nach einem
  Widerruf werden diese personenbezogenen Daten von dem für die Verarbeitung
  Verantwortlichen gelöscht. Eine Abmeldung vom Erhalt des Newsletters deutet
  die SUMM AI GmbH automatisch als Widerruf.
</p>
<p><strong>8. Kontaktmöglichkeit über die Internetseite</strong></p>
<p>
  Die Internetseite der SUMM AI GmbH enthält aufgrund von gesetzlichen
  Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu
  unserem Unternehmen sowie eine unmittelbare Kommunikation mit uns ermöglichen,
  was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post
  (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail oder über
  ein Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen
  aufnimmt, werden die von der betroffenen Person übermittelten
  personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis
  von einer betroffenen Person an den für die Verarbeitung Verantwortlichen
  übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder
  der Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine
  Weitergabe dieser personenbezogenen Daten an Dritte.
</p>
<p><strong>9. Kommentarfunktion im Blog auf der Internetseite</strong></p>
<p>
  Die SUMM AI GmbH bietet den Nutzern auf einem Blog, der sich auf der
  Internetseite des für die Verarbeitung Verantwortlichen befindet, die
  Möglichkeit, individuelle Kommentare zu einzelnen Blog-Beiträgen zu
  hinterlassen. Ein Blog ist ein auf einer Internetseite geführtes, in der Regel
  öffentlich einsehbares Portal, in welchem eine oder mehrere Personen, die
  Blogger oder Web-Blogger genannt werden, Artikel posten oder Gedanken in
  sogenannten Blogposts niederschreiben können. Die Blogposts können in der
  Regel von Dritten kommentiert werden.
</p>
<p>
  Hinterlässt eine betroffene Person einen Kommentar in dem auf dieser
  Internetseite veröffentlichten Blog, werden neben den von der betroffenen
  Person hinterlassenen Kommentaren auch Angaben zum Zeitpunkt der
  Kommentareingabe sowie zu dem von der betroffenen Person gewählten Nutzernamen
  (Pseudonym) gespeichert und veröffentlicht. Ferner wird die vom
  Internet-Service-Provider (ISP) der betroffenen Person vergebene IP-Adresse
  mitprotokolliert. Diese Speicherung der IP-Adresse erfolgt aus
  Sicherheitsgründen und für den Fall, dass die betroffene Person durch einen
  abgegebenen Kommentar die Rechte Dritter verletzt oder rechtswidrige Inhalte
  postet. Die Speicherung dieser personenbezogenen Daten erfolgt daher im
  eigenen Interesse des für die Verarbeitung Verantwortlichen, damit sich dieser
  im Falle einer Rechtsverletzung gegebenenfalls exkulpieren könnte. Es erfolgt
  keine Weitergabe dieser erhobenen personenbezogenen Daten an Dritte, sofern
  eine solche Weitergabe nicht gesetzlich vorgeschrieben ist oder der
  Rechtsverteidigung des für die Verarbeitung Verantwortlichen dient.
</p>
<p><strong>10. Rechtsgrundlage der Verarbeitung</strong></p>
<p>
  Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für
  Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
  Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
  Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
  erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist,
  die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung
  oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I
  lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur
  Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von
  Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser Unternehmen
  einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
  personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung
  steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c
  DS-GVO. In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten
  erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder
  einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der
  Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin
  sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige
  Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
  weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
  DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f
  DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die
  von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
  Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
  oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und
  Grundfreiheiten des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge
  sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen
  Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass
  ein berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person
  ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
</p>
<p>
  <strong>11. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
    personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
    Verpflichtung der betroffenen Person, die personenbezogenen Daten
    bereitzustellen; mögliche Folgen der Nichtbereitstellung</strong>
</p>
<p>
  Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten
  zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich
  auch aus vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben
  kann. Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine
  betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der
  Folge durch uns verarbeitet werden müssen. Die betroffene Person ist
  beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, wenn
  unser Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung
  der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem
  Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung
  personenbezogener Daten durch den Betroffenen muss sich der Betroffene an
  einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
  einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen
  Daten gesetzlich oder vertraglich vorgeschrieben oder für den
  Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
  personenbezogenen Daten bereitzustellen, und welche Folgen die
  Nichtbereitstellung der personenbezogenen Daten hätte.
</p>
<p>
  <strong>12. Verarbeitungs- und Speicherungszwecke; Dauer, für die die
    personenbezogenen Daten gespeichert werden; Routinemäßige Löschung und
    Sperrung von personenbezogenen Daten:</strong>
</p>
<p>
  Der für die Verarbeitung Verantwortliche verarbeitet und speichert
  personenbezogene Daten der betroffenen Person nur für den Zeitraum, der zur
  Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den
  Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber
  in Gesetzen oder Vorschriften, welchen der für die Verarbeitung
  Verantwortliche unterliegt, vorgesehen wurde.
</p>
<p>
  Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien-
  und Verordnungsgeber oder einem anderen zuständigen Gesetzgeber
  vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten
  routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
  gelöscht.
</p>
<ul>
  <li>
    Marketing, Vertrieb und Kundenkommunikation:<br />Zum Zweck von Marketing
    und zur Kundenkommunikation werden Name, E-mailadresse und (soweit zur
    Verfügung gestellt) Telefonnummer der betroffenen Person verarbeitet und
    gespeichert. Diese werden bis zur ausdrücklichen Beendigung der
    Kundenbeziehung (entweder von der betroffenen Person oder des für die
    Verarbeitung Verantwortlichen) gespeichert und dann innerhalb von 3 Monaten
    gelöscht. Hierbei ist unser berechtigtes Interesse die Durchführung unserer
    Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter und
    unserer Anteilseigner.
  </li>
  <li>
    Vertragserfüllung:<br />Um unsere Services zu nutzen ist es erforderlich,
    sich bei SUMM AI GmbH zu registrieren und einen Account anzulegen. Zum Zweck der
    Vertragserfüllung (Bereitstellung des Dienstes) wird die E-mailadresse,
    Passwort, ggf. Name und Firma, IP-Adresse und (soweit zur Verfügung
    gestellt) Rechnungsdaten der betroffenen Person verarbeitet und gespeichert.
    Diese werden bis zum Vertragsende gespeichert und dann innerhalb von 3
    Monaten gelöscht. Hierbei ist unser berechtigtes Interesse die Erfüllung
    eines Vertrags, dessen Vertragspartei die betroffene Person ist. Die
    Verarbeitung Ihrer IP-Adresse erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1
    lit. f) DSGVO, da es unseren berechtigten Interessen entspricht, Ihre
    IP-Adresse zur Betrugsprävention mit IP-Adressen abzugleichen, die unseren
    Service in der Vergangenheit missbräuchlich genutzt haben.
  </li>
</ul>
<p><strong>13. Übersetzungen und weitere Services von SUMM AI GmbH</strong></p>
<p>
  Wenn Sie unseren Übersetzungsservice nutzen, geben Sie nur Texte ein, die Sie
  auf unsere Server übertragen wollen. Die Übermittlung dieser Texte ist
  notwendig, damit wir die Übersetzung durchführen und Ihnen unseren Service
  anbieten können. Wir verarbeiten Ihre Texte sowie deren Übersetzungen für
  einen unbegrenzten Zeitraum, um unsere neuronalen Netze und
  Übersetzungsalgorithmen damit zu trainieren und zu verbessern. Dies gilt auch
  für Korrekturen, die Sie an unseren Übersetzungsvorschlägen vornehmen. Die
  Korrekturen werden an unsere Server weitergeleitet, um diese auf Richtigkeit
  zu überprüfen und gegebenenfalls den übersetzten Text entsprechend Ihrer
  Änderungen zu aktualisieren.<br />Wenn Sie die Glossar-Funktion nutzen und
  dort Erklärungen für bestimmte Worte hinterlegen, werden diese Daten ebenfalls
  auf unsere Server übertragen und verarbeitet. Dies ist notwendig, damit wir
  die Glossarfunktionen Ihnen auch auf einem anderen Browser anzeigen können.
  Weiterhin können Sie hierdurch ein anderes Glossar empfangen und Ihr eigenes
  für andere freigeben. Diese Daten werden auch genutzt, um unseren Service zu
  verbessern.<br />Wenn Sie die Synonyme-Funktion nutzen, werden Ihre Daten über
  die Anfragen und Interaktionen ebenfalls auf unsere Server übertragen und
  verarbeitet. Dies ist notwendig, damit wir die Synonyme-Funktion verbessern
  können.<br />Bitte beachten Sie, dass Sie unsere Services gemäß unserer
  Nutzungsbedingungen nicht für die Übersetzung von Texten mit personenbezogenen
  Daten jeglicher Art nutzen dürfen. Die Übersetzung personenbezogener Daten ist
  momentan nicht möglich.
</p>
<p>
  <strong>14. Rechte der betroffenen Person<br /></strong>
</p>
<p>a)    Recht auf Bestätigung</p>
<p>
  Jede betroffene Person hat das vom Europäischen Richtlinien- und
  Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
  Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende
  personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person
  dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an
  einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.<br />
</p>
<p>b)    Recht auf Auskunft</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit
  von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die
  zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser
  Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und
  Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen
  zugestanden:
</p>
<ul>
  <li>
    <ul>
      <li>die Verarbeitungszwecke</li>
      <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
      <li>
        die Empfänger oder Kategorien von Empfängern, gegenüber denen die
        personenbezogenen Daten offengelegt worden sind oder noch offengelegt
        werden, insbesondere bei Empfängern in Drittländern oder bei
        internationalen Organisationen
      </li>
      <li>
        falls möglich die geplante Dauer, für die die personenbezogenen Daten
        gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien
        für die Festlegung dieser Dauer
      </li>
      <li>
        das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
        betreffenden personenbezogenen Daten oder auf Einschränkung der
        Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
        gegen diese Verarbeitung
      </li>
      <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
      <li>
        wenn die personenbezogenen Daten nicht bei der betroffenen Person
        erhoben werden: Alle verfügbaren Informationen über die Herkunft der
        Daten
      </li>
      <li>
        das Bestehen einer automatisierten Entscheidungsfindung einschließlich
        Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen
        Fällen — aussagekräftige Informationen über die involvierte Logik sowie
        die Tragweite und die angestrebten Auswirkungen einer derartigen
        Verarbeitung für die betroffene Person<br />
      </li>
    </ul>
  </li>
</ul>
<p>
  Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
  personenbezogene Daten an ein Drittland oder an eine internationale
  Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der
  betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten
  Garantien im Zusammenhang mit der Übermittlung zu erhalten.
</p>
<p>
  Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann
  sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
  Verantwortlichen wenden.<br />
</p>
<p>c)    Recht auf Berichtigung</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die
  unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener
  Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter
  Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung
  unvollständiger personenbezogener Daten — auch mittels einer ergänzenden
  Erklärung — zu verlangen.<br />
</p>
<p>
  Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen,
  kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
  Verantwortlichen wenden.<br />
</p>
<ul>
  <li>d)    Recht auf Löschung (Recht auf Vergessen werden)</li>
</ul>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
  Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen
  Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft
  und soweit die Verarbeitung nicht erforderlich ist:
</p>
<ul>
  <li>
    <ul>
      <li>
        Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf
        sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.
      </li>
      <li>
        Die betroffene Person widerruft ihre Einwilligung, auf die sich die
        Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2
        Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen
        Rechtsgrundlage für die Verarbeitung.
      </li>
      <li>
        Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen
        die Verarbeitung ein, und es liegen keine vorrangigen berechtigten
        Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß
        Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
      </li>
      <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
      <li>
        Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
        rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
        Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
      </li>
      <li>
        Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
        Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.<br />
      </li>
    </ul>
  </li>
</ul>
<p>
  Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die
  Löschung von personenbezogenen Daten, die bei der SUMM AI GmbH gespeichert
  sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter
  des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der SUMM AI GmbH
  wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen
  wird.<br />
</p>
<p>
  Wurden die personenbezogenen Daten von der SUMM AI GmbH öffentlich gemacht
  und ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur
  Löschung der personenbezogenen Daten verpflichtet, so trifft die SUMM AI GmbH
  unter Berücksichtigung der verfügbaren Technologie und der
  Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere
  für die Datenverarbeitung Verantwortliche, welche die veröffentlichten
  personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die
  betroffene Person von diesen anderen für die Datenverarbeitung
  Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen
  Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten
  verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter
  der SUMM AI GmbH wird im Einzelfall das Notwendige veranlassen.<br />
</p>
<p>e)    Recht auf Einschränkung der Verarbeitung</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
  Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine
  der folgenden Voraussetzungen gegeben ist:
</p>
<ul>
  <li>
    <ul>
      <li>
        Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
        Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen
        ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.
      </li>
      <li>
        Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
        Löschung der personenbezogenen Daten ab und verlangt stattdessen die
        Einschränkung der Nutzung der personenbezogenen Daten.
      </li>
      <li>
        Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke
        der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </li>
      <li>
        Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art.
        21 Abs. 1 DS-GVO eingelegt und es steht noch nicht fest, ob die
        berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen
        Person überwiegen.<br />
      </li>
    </ul>
  </li>
</ul>
<p>
  Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene
  Person die Einschränkung von personenbezogenen Daten, die bei der SUMM AI GmbH
  gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an
  einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
  Mitarbeiter der SUMM AI GmbH wird die Einschränkung der Verarbeitung
  veranlassen.<br />
</p>
<p>f)     Recht auf Datenübertragbarkeit</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie
  betreffenden personenbezogenen Daten, welche durch die betroffene Person einem
  Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und
  maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten
  einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
  dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
  die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO
  oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs.
  1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter
  Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer
  Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
  öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
</p>
<p>
  Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
  Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass
  die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
  Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und
  sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt
  werden.
</p>
<p>
  Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
  betroffene Person jederzeit an einen Mitarbeiter der SUMM AI GmbH
  wenden.<br />
</p>
<p><strong>g)    Recht auf Widerspruch</strong></p>
<p>
  <strong>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
    vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus
    Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen
    die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von
    Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen.
    Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</strong>
</p>
<p>
  <strong>Die SUMM AI GmbH verarbeitet die personenbezogenen Daten im Falle des
    Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige
    Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und
    Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient
    der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</strong>
</p>
<p>
  Verarbeitet die SUMM AI GmbH personenbezogene Daten, um Direktwerbung zu
  betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen
  die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
  einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
  Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
  gegenüber der SUMM AI GmbH der Verarbeitung für Zwecke der Direktwerbung,
  so wird die SUMM AI GmbH die personenbezogenen Daten nicht mehr für diese
  Zwecke verarbeiten.
</p>
<p>
  Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer
  besonderen Situation ergeben, gegen die sie betreffende Verarbeitung
  personenbezogener Daten, die bei der SUMM AI GmbH zu wissenschaftlichen
  oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art.
  89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
  Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden
  Aufgabe erforderlich.
</p>
<p>
  Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt
  an jeden Mitarbeiter der SUMM AI GmbH oder einen anderen Mitarbeiter
  wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang mit der
  Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
  2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben,
  bei denen technische Spezifikationen verwendet werden.
</p>
<p>
  h)    Automatisierte Entscheidungen im Einzelfall einschließlich Profiling
</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
  ausschließlich auf einer automatisierten Verarbeitung — einschließlich
  Profiling — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber
  rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich
  beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die
  Erfüllung eines Vertrags zwischen der betroffenen Person und dem
  Verantwortlichen erforderlich ist, oder (2) aufgrund von Rechtsvorschriften
  der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
  zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
  der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen
  Person enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen
  Person erfolgt.
</p>
<p>
  Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags
  zwischen der betroffenen Person und dem Verantwortlichen erforderlich oder (2)
  erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, trifft die
  SUMM AI GmbH angemessene Maßnahmen, um die Rechte und Freiheiten sowie die
  berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das
  Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
  auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
  gehört.
</p>
<p>
  Möchte die betroffene Person Rechte mit Bezug auf automatisierte
  Entscheidungen geltend machen, kann sie sich hierzu jederzeit an einen
  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
</p>
<p>i)      Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</p>
<p>
  Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
  vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine
  Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
</p>
<p>
  Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend
  machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
  Verarbeitung Verantwortlichen wenden.
</p>
<p>
  <strong>Zur Ausübung seiner Nutzerrechte kann sich der Nutzer jederzeit per E-Mail
    an <a href="mailto:team@summ-ai.com">team@summ-ai.com</a> wenden.</strong>
</p>
<p>
  <strong>15. Datenschutzbestimmungen zu Einsatz und Verwendung von
    Google-AdWords</strong>
</p>
<p>
  Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Google
  AdWords integriert. Google AdWords ist ein Dienst zur Internetwerbung, der es
  Werbetreibenden gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von
  Google als auch im Google-Werbenetzwerk zu schalten. Google AdWords ermöglicht
  es einem Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen, mittels
  derer eine Anzeige in den Suchmaschinenergebnissen von Google ausschließlich
  dann angezeigt wird, wenn der Nutzer mit der Suchmaschine ein
  schlüsselwortrelevantes Suchergebnis abruft. Im Google-Werbenetzwerk werden
  die Anzeigen mittels eines automatischen Algorithmus und unter Beachtung der
  zuvor festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
  verteilt.
</p>
<p>
  Betreibergesellschaft der Dienste von Google AdWords ist die Google Ireland
  Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.
</p>
<p>
  Der Zweck von Google AdWords ist die Bewerbung unserer Internetseite durch die
  Einblendung von interessenrelevanter Werbung auf den Internetseiten von
  Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine Google
  und eine Einblendung von Fremdwerbung auf unserer Internetseite.
</p>
<p>
  Gelangt eine betroffene Person über eine Google-Anzeige auf unsere
  Internetseite, wird auf dem informationstechnologischen System der betroffenen
  Person durch Google ein sogenannter Conversion-Cookie abgelegt. Was Cookies
  sind, wurde oben bereits erläutert. Ein Conversion-Cookie verliert nach
  dreißig Tagen seine Gültigkeit und dient nicht zur Identifikation der
  betroffenen Person. Über den Conversion-Cookie wird, sofern das Cookie noch
  nicht abgelaufen ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise
  der Warenkorb von einem Online-Shop-System, auf unserer Internetseite
  aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir als auch
  Google nachvollziehen, ob eine betroffene Person, die über eine
  AdWords-Anzeige auf unsere Internetseite gelangt ist, einen Umsatz generierte,
  also einen Warenkauf vollzogen oder abgebrochen hat.
</p>
<p>
  Die durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen
  werden von Google verwendet, um Besuchsstatistiken für unsere Internetseite zu
  erstellen. Diese Besuchsstatistiken werden durch uns wiederum genutzt, um die
  Gesamtanzahl der Nutzer zu ermitteln, welche über AdWords-Anzeigen an uns
  vermittelt wurden, also um den Erfolg oder Misserfolg der jeweiligen
  AdWords-Anzeige zu ermitteln und um unsere AdWords-Anzeigen für die Zukunft zu
  optimieren. Weder unser Unternehmen noch andere Werbekunden von Google-AdWords
  erhalten Informationen von Google, mittels derer die betroffene Person
  identifiziert werden könnte.
</p>
<p>
  Mittels des Conversion-Cookies werden personenbezogene Informationen,
  beispielsweise die durch die betroffene Person besuchten Internetseiten,
  gespeichert. Bei jedem Besuch unserer Internetseiten werden demnach
  personenbezogene Daten, einschließlich der IP-Adresse des von der betroffenen
  Person genutzten Internetanschlusses, an Google in den Vereinigten Staaten von
  Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den
  Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das
  technische Verfahren erhobenen personenbezogenen Daten unter Umständen an
  Dritte weiter.
</p>
<p>
  Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite,
  wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
  Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
  von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
  Internetbrowsers würde auch verhindern, dass Google einen Conversion-Cookie
  auf dem informationstechnologischen System der betroffenen Person setzt. Zudem
  kann ein von Google AdWords bereits gesetzter Cookie jederzeit über den
  Internetbrowser oder andere Softwareprogramme gelöscht werden.
</p>
<p>
  Ferner besteht für die betroffene Person die Möglichkeit, der
  interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss die
  betroffene Person von jedem der von ihr genutzten Internetbrowser aus den Link
  www.google.de/settings/ads aufrufen und dort die gewünschten Einstellungen
  vornehmen.
</p>
<p>
  Weitere Informationen und die geltenden Datenschutzbestimmungen von Google
  können unter https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
</p>
<p>
  <strong>16. Datenschutzbestimmungen zu Einsatz und Verwendung von Matomo</strong>
</p>
<p>
  Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die
  Komponente Matomo integriert. Matomo ist ein Open-Source-Softwaretool zur
  Web-Analyse, welches auf unseren AWS Servern in Frankfurt gehostet wird.
  Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das
  Verhalten von Besuchern von Internetseiten. Ein Web-Analyse-Tool erfasst unter
  anderem Daten darüber, von welcher Internetseite eine betroffene Person auf
  eine Internetseite gekommen ist (sogenannter Referrer), auf welche Unterseiten
  der Internetseite zugegriffen oder wie oft und für welche Verweildauer eine
  Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung
  einer Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
  eingesetzt.
</p>
<p>
  Die Software wird auf dem Server des für die Verarbeitung Verantwortlichen
  betrieben, die datenschutzrechtlich sensiblen Logdateien werden ausschließlich
  auf diesem Server gespeichert. Dieser Server wird von AWS bezogen und befindet
  sich in Frankfurt.
</p>
<p>
  Der Zweck der Matomo-Komponente ist die Analyse der Besucherströme auf unserer
  Internetseite. Der für die Verarbeitung Verantwortliche nutzt die gewonnenen
  Daten und Informationen unter anderem dazu, die Nutzung dieser Internetseite
  auszuwerten, um Online-Reports, welche die Aktivitäten auf unseren
  Internetseiten aufzeigen, zusammenzustellen.
</p>
<p>
  Matomo setzt ein Cookie auf dem informationstechnologischen System der
  betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Mit der
  Setzung des Cookies wird uns eine Analyse der Benutzung unserer Internetseite
  ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite
  wird der Internetbrowser auf dem informationstechnologischen System der
  betroffenen Person automatisch durch die Matomo-Komponente veranlasst, Daten
  zum Zwecke der Online-Analyse an unseren Server zu übermitteln. Im Rahmen
  dieses technischen Verfahrens erhalten wir Kenntnis über personenbezogene
  Daten, wie der IP-Adresse der betroffenen Person, die uns unter anderem dazu
  dient, die Herkunft der Besucher und Klicks nachzuvollziehen.
</p>
<p>
  Mittels des Cookies werden personenbezogene Informationen, beispielsweise die
  Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die Häufigkeit der
  Besuche auf unserer Internetseite gespeichert. Bei jedem Besuch unserer
  Internetseiten werden diese personenbezogenen Daten, einschließlich der
  IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an
  unseren Server übertragen. Diese personenbezogenen Daten werden durch uns
  gespeichert. Wir geben diese personenbezogenen Daten nicht an Dritte weiter.
</p>
<p>
  Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite,
  wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
  Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
  von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
  Internetbrowsers würde auch verhindern, dass Matomo ein Cookie auf dem
  informationstechnologischen System der betroffenen Person setzt. Zudem kann
  ein von Matomo bereits gesetzter Cookie jederzeit über einen Internetbrowser
  oder andere Softwareprogramme gelöscht werden.
</p>
<p>
  Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der
  durch den Matomo erzeugten, auf eine Nutzung dieser Internetseite bezogenen
  Daten zu widersprechen und eine solche zu verhindern. Hierzu muss die
  betroffene Person in Ihrem Browser &quot;Do Not Track&quot; einstellen.
</p>
<p>
  Mit der Setzung des Opt-Out-Cookies besteht jedoch die Möglichkeit, dass die
  Internetseiten des für die Verarbeitung Verantwortlichen für die betroffene
  Person nicht mehr vollumfänglich nutzbar sind.
</p>
<p>
  Weitere Informationen und die geltenden Datenschutzbestimmungen von Matomo
  können unter https://matomo.org/privacy/ abgerufen werden.
</p>
<p>
  <strong>17. Datenschutzbestimmungen zu Einsatz und Verwendung von STRATO</strong>
</p>
<p>Wir hosten unsere Website bei STRATO AG (Otto-Ostrowski-Straße 7,</p>
<p>
  10249 Berlin). STRATO arbeitet DSGVO-konform. Außerdem waren sie als deutscher
  Anbieter bislang an das Bundesdatenschutzgesetz gebunden, eines der strengsten
  im weltweiten Vergleich. Hinzu kommt bei STRATO: Die TÜV-Zertifizierung der
  Rechenzentren nach der ISO 27001 schafft besondere Datensicherheit.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit STRATO geschlossen. Hierbei
  handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
  gewährleistet, dass STRATO die personenbezogenen Daten unserer Websitebesucher
  nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
</p>
<p>
  <strong>18. Datenschutzbestimmungen zu Einsatz und Verwendung von CookieYes</strong>
</p>
<p>
  Wir verwenden für Nutzereinwilligungen für einwilligungspflichtige Cookies und
  cookie-basierte Anwendungen auf unserer Webseite das Cookie-Consent-Tool
  „CookieYes“ der Mozilor Limited, 3 Warren Yard, Wolverton Mill, Milton Keynes,
  England, MK12 5NW, Großbritannien („CookieYes“).
</p>
<p>
  Über die CookieYes-Anwendung werden bestimmte Nutzerinformationen
  (einschließlich der IP-Adresse) erhoben, an Server von CookieYes übermittelt
  und dort gespeichert. Dies dient dazu, eine eindeutige individuelle
  Nutzerzuordnung von Seitenaufrufen zu ermöglichen und Eure als Nutzer
  getroffenen Einwilligungseinstellungen individuell zu erfassen, zu
  protokollieren und für eine Sitzungsdauer zu speichern.
</p>
<p>
  Diese Datenverarbeitungen erfolgen gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
  unseres berechtigten Interesses an einem rechtskonformen, nutzerspezifischen
  und nutzerfreundlichen Einwilligungsmanagement für Cookies und mithin an einer
  rechtskonformen Ausgestaltung unseres Internetauftritts. Weitere
  Rechtsgrundlage für die beschriebenen Datenverarbeitungen ist ferner Art. 6
  Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der rechtlichen
  Verpflichtung, den Einsatz technisch nicht notwendiger Cookies von der
  jeweiligen Nutzereinwilligung abhängig zu machen.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit CookieYes geschlossen.
  Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
  Vertrag, der gewährleistet, dass CookieYes die personenbezogenen Daten unserer
  Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO
  verarbeitet.
</p>
<p>
  <strong>19. Datenschutzbestimmungen zu Einsatz und Verwendung von Hubspot</strong>
</p>
<p>
  Wir nutzen das Customer-Relationship-Management System bei Hubspot Inc. (25
  First Street, Cambridge, MA 02141 USA). Seit Juli 2021 verfügt HubSpot über
  eine neue AWS-Umgebung in der Europäischen Union. HubSpot-Accounts werden an
  einem dieser Hosting-Standorte gehostet und Kundendaten werden an diesem
  Standort verarbeitet und gespeichert.
</p>
<p>
  HubSpot hat ein umfassendes Datenschutzprogramm, das auf die
  Hosting-Anforderungen verschiedener Regionen ausgerichtet ist. Die
  Vereinbarung zur Datenverarbeitung von HubSpot beinhaltet die
  Standardvertragsklauseln der Europäischen Union nach der Richtlinie 95/46/EG
  als Mechanismus für den Transfer von Daten aus der EU in die USA. HubSpot
  erfüllt darüber hinaus die Grundsätze des Datenschutzschild-(Privacy
  Shield)-Abkommens. So kann HubSpot gegenüber verschiedenen Interessengruppen
  gewährleisten, dass unsere Prozesse zur Datenverarbeitung strenge
  datenschutzrechtliche Auflagen einhalten.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit Hubspot geschlossen. Hierbei
  handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
  gewährleistet, dass Hubspot die personenbezogenen Daten unserer
  Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO
  verarbeitet.
</p>
<p>
  <strong>20. Datenschutzbestimmungen zu Einsatz und Verwendung von Sentry</strong>
</p>
<p>
  Wir verwenden für die Überwachung und Fehlerbehebung unserer Dienste Sentry. Dienstanbieter ist Functional Software
  Inc., Sentry, 1501 Mariposa St #408, San Francisco, CA 94107, USA. Die Verarbeitung erfolgt auf Grundlage von Art. 6
  Abs. 1 lit. f DSGVO. Sentry unterstützt uns dabei, potenzielle Probleme und Fehler in unserer Software zu
  identifizieren und zu beheben, um die Qualität und Sicherheit unserer Dienste zu gewährleisten.
</p>
<p>
  Bei der Verwendung von Sentry werden bestimmte technische Informationen übertragen und gespeichert, um Fehler und
  Abstürze zu analysieren. Diese Informationen können auch personenbezogene Daten enthalten, wie beispielsweise
  IP-Adressen oder Geräteinformationen. Allerdings verwenden wir Sentry nicht zur gezielten Erfassung personenbezogener
  Daten unserer Nutzer, sondern ausschließlich zu Analyse- und Fehlerbehebungszwecken. Weitere Informationen hierzu
  finden Sie in der Datenschutzerklärung von Sentry: <a href="https://sentry.io/privacy/"
    target="_blank"
    rel="nofollow">https://sentry.io/privacy/</a>.
</p>
<p>
  Sentry hat angemessene technische und organisatorische Maßnahmen getroffen, um die Sicherheit und Vertraulichkeit
  Ihrer Daten zu gewährleisten. Weitere Informationen hierzu finden Sie unter diesem Link: <a
    href="https://sentry.io/security/"
    target="_blank">https://sentry.io/security/</a>.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />
  Wir haben einen Vertrag über Auftragsverarbeitung mit Sentry geschlossen. Hierbei handelt es sich um einen
  datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass Sentry die personenbezogenen Daten unserer
  Nutzer nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet
</p>
<p>
  <strong>21. Datenschutzbestimmungen zu Einsatz und Verwendung von Grafana</strong>
</p>
<p>
  Wir nutzen die Dienste von Grafana Labs („Grafana“). Wenn Sie unsere Dienste
  nutzen, werden Ihre personenbezogenen Daten auf den Servern von Grafana
  verarbeitet. Wir nutzen ausschließlich Server in der EU.
</p>
<p>
  Der Einsatz des Dashboards erfolgt zum Zwecke der Vertragserfüllung gegenüber
  unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
  im Interesse einer sicheren, schnellen und effizienten Möglichkeit des
  Monitorings unseres Angebots durch einen professionellen Anbieter (Art. 6 Abs.
  1 lit. f DSGVO).
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit Grafana geschlossen. Hierbei
  handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
  gewährleistet, dass Grafana die personenbezogenen Daten unserer Nutzer nur
  nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
</p>
<p>
  <strong>22. Datenschutzbestimmungen zu Einsatz und Verwendung von Supabase</strong>
</p>
<p>
  Wir verwenden für die Authentifizierung unserer Dienste Supabase. Dienstanbieter ist Supabase Inc., in 970 Toa Payoh
  North #07-04, Singapore 318992. Wenn Sie unsere Dienste nutzen, werden Ihre personenbezogenen
  Daten auf Servern in der EU verarbeitet. Weitere Informationen entnehmen Sie der Datenschutzerklärung von Supabase:
  https://supabase.com/privacy.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit Supabase geschlossen.
  Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
  Vertrag, der gewährleistet, dass Supabase die personenbezogenen Daten unserer
  Nutzer nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
</p>
<p>
  <strong>23. Datenschutzbestimmungen zu Hosting mit Amazon Web Services
    (AWS)</strong>
</p>
<p>
  Wir hosten unsere Services bei AWS. Anbieter ist die Amazon Web Services EMEA
  SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend: AWS). Wenn Sie
  unsere Dienste nutzen, werden Ihre personenbezogenen Daten auf den Servern von
  AWS verarbeitet. Wir nutzen ausschließlich Server in der EU. Es kann nicht
  ausgeschlossen werden, dass personenbezogene Daten an das Mutterunternehmen
  von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird
  auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier:
  https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/ .
  Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:
  https://aws.amazon.com/de/privacy/?nc1=f_pr . Die Verwendung von AWS erfolgt
  auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
  Interesse an einer möglichst zuverlässigen Darstellung unserer Services.
</p>
<p>
  <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br />Wir
  haben einen Vertrag über Auftragsverarbeitung mit AWS geschlossen. Hierbei
  handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
  gewährleistet, dass AWS die personenbezogenen Daten unserer Websitebesucher
  nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
</p>
<p>
  <strong>24. Datenschutzbestimmungen zu Einsatz und Verwendung von
    SharePoint</strong>
</p>
<p>
  Der für die Verarbeitung Verantwortliche nutzt SharePoint zur Verwaltung von
  Angebotserstellungen und Kundenfeedback. Anbieter ist die Microsoft
  Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA (im folgenden
  „SharePoint“).
</p>
<p>
  Die Verwendung von OneDrive erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
  DSGVO. Der für die Verarbeitung Verantwortliche hat ein berechtigtes Interesse
  an einem zuverlässigen Medium zur Aufbewahrung von Projektangeboten und
  Kundenfeedback. Dies dient sowohl der effizienten Verwaltung von
  Projektinformationen als auch zur Verbesserung des Angebots.
</p>
<p><strong>Abschluss eines Vertrags über Auftragsverarbeitung</strong></p>
<p>
  Wir haben einen Vertrag über Auftragsverarbeitung mit SharePoint geschlossen.
  Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
  Vertrag, der gewährleistet, dass OneDrive die personenbezogenen Daten unserer
  Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO
  verarbeitet.
</p>
<p><strong>25. Hinweis zur Datenweitergabe in die USA</strong></p>
<p>
  Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den
  USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen
  Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir
  weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des
  EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet,
  personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als
  Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
  ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf
  US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten
  und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen
  Einfluss.
</p>
<p><strong>26. Bestehen einer automatisierten Entscheidungsfindung</strong></p>
<p>
  Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
  Entscheidungsfindung oder ein Profiling.
</p>
`;

export default privacy_string;
