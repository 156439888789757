import { Button, Grid, Typography, useTheme } from '@mui/material';
import BasicTableComp from 'src/coreUI/basicTableComp/BasicTableComp';
import { tableHeader } from './fixedData';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ConfirmModelComp from 'src/coreUI/confirmModelComp/ConfirmModelComp';
import { useCallback, useEffect, useState } from 'react';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import CopyButtomComp from 'src/coreUI/copyButtonComp/CopyButtomComp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createUserApiKey,
  deleteUserApiKey,
  getUserApiKey,
  resetCreatedApiKey,
  updateUserApiKey,
} from 'src/redux/user/actions';
import { UserSelector } from 'src/redux/user/selector';
import { isEmpty } from 'lodash';
import { Permission } from 'src/enums/permission';
import PermissionsCheck from 'src/config/PermissionsCheck';

type Props = {};

const ApiKeyContainer = (props: Props) => {
  const { t } = useTranslation('account');
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreateNew, setOpenCreateNew] = useState<boolean>(false);
  const [openCopyCreatedKey, setOpenCopyCreatedKey] = useState<boolean>(false);
  const [keyName, setKeyName] = useState<string>('');
  const [copyValue, setCopyValue] = useState<string>('');
  const [targetKey, setTargetKey] = useState<any>(null);

  const apiKeys = useSelector(
    (state: any) => UserSelector(state).apiKeys,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const createdKey: string | null = useSelector(
    (state: any) => UserSelector(state).createdKey,
    shallowEqual
  );

  const handleOpenCloseModel = useCallback(
    (type: string) => {
      switch (type) {
        case 'delete':
          setOpenDelete(!openDelete);
          setKeyName('');
          break;
        case 'edit':
          setOpenEdit(!openEdit);
          setKeyName('');
          break;
        case 'create':
          setOpenCreateNew(!openCreateNew);
          setKeyName('');
          break;
        case 'copy':
          setOpenCopyCreatedKey(!openCopyCreatedKey);
      }
    },
    [openCopyCreatedKey, openCreateNew, openDelete, openEdit]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKeyName(e?.target.value);
  };

  const handleDeleteConfirm = useCallback(() => {
    dispatch(deleteUserApiKey(targetKey?.prefix ?? ''));
    handleOpenCloseModel('delete');
  }, [dispatch, handleOpenCloseModel, targetKey?.prefix]);

  const handleEditConfirm = useCallback(() => {
    dispatch(updateUserApiKey(targetKey?.prefix ?? '', keyName));
    handleOpenCloseModel('edit');
  }, [dispatch, handleOpenCloseModel, keyName, targetKey?.prefix]);

  const handleCreateConfirm = useCallback(async () => {
    await dispatch(createUserApiKey(keyName));
    handleOpenCloseModel('create');
    // dispatch(getUserApiKey());
  }, [dispatch, handleOpenCloseModel, keyName]);

  const handleCopyConfirm = () => {
    handleOpenCloseModel('copy');
  };

  useEffect(() => {
    if (userDetails?.permissions.includes(Permission.can_view_apikeys)) {
      dispatch(getUserApiKey());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createdKey) {
      setCopyValue(createdKey);
      setOpenCopyCreatedKey(!isEmpty(createdKey));
    }
  }, [createdKey]);

  useEffect(() => {
    if (!openCopyCreatedKey) {
      dispatch(resetCreatedApiKey());
    }
  }, [dispatch, openCopyCreatedKey]);

  return (
    <>
      <Grid
        item
        xs={12}
        textAlign={'left'}
        fontSize={'1.5rem'}
      >
        <h4>{t('secretKey.apiKey')}</h4>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'right'}
        spacing={4}
      >
        <Grid
          item
          xs={10}
        >
          <BasicTableComp
            tableHeader={tableHeader(t)}
            tableContent={apiKeys ? Object.values(apiKeys) : []}
            showActions={userDetails?.permissions.includes(
              Permission.can_delete_apikeys && Permission.can_update_apikeys
            )}
            handleDelete={(row: any) => {
              handleOpenCloseModel('delete');
              setTargetKey(row);
              setKeyName(row.name);
            }}
            handleEdit={(row: any) => {
              handleOpenCloseModel('edit');
              setTargetKey(row);
              setKeyName(row.name);
            }}
          />
        </Grid>
        <PermissionsCheck allowedPermission={[Permission.can_create_apikeys]}>
          <Grid
            item
            xs={10}
            textAlign={'left'}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                backgroundColor: theme.palette.general.lightGray,
                '&:hover': {
                  backgroundColor: theme.palette.general.lightBlue,
                  border: 'none',
                },
                border: 'none',
              }}
              onClick={() => handleOpenCloseModel('create')}
            >
              {t('secretKey.createKeyMsg')}
            </Button>
          </Grid>
        </PermissionsCheck>

        <PermissionsCheck
          allowedPermission={[
            Permission.can_delete_apikeys,
            Permission.can_update_apikeys,
          ]}
        >
          {/* Delete Key Model */}
          <ConfirmModelComp
            open={openDelete}
            setOpen={setOpenDelete}
            handleConfirm={handleDeleteConfirm}
            title={t('secretKey.deleteApiKey')}
            component={
              <Typography
                variant="body1"
                gutterBottom
              >
                {t('secretKey.deleteConfirmMsg')}
              </Typography>
            }
          />

          {/* Edit Key Model */}
          <ConfirmModelComp
            open={openEdit}
            setOpen={setOpenEdit}
            handleConfirm={handleEditConfirm}
            component={
              <InputComponent
                value={keyName}
                handleChange={handleChange}
                id="edit-api-key"
                label={t('account.name')}
                style={{ width: '100%' }}
              />
            }
            title={t('secretKey.editApiKey')}
            contentStyle={{
              padding: '1em !important',
            }}
            disableConfirm={isEmpty(keyName)}
          />
        </PermissionsCheck>

        <PermissionsCheck allowedPermission={[Permission.can_create_apikeys]}>
          {/* Create Key Model */}
          <ConfirmModelComp
            open={openCreateNew}
            setOpen={setOpenCreateNew}
            handleConfirm={handleCreateConfirm}
            component={
              <InputComponent
                value={keyName}
                handleChange={handleChange}
                id="create-api-key"
                label={t('account.name')}
                style={{ width: '100%' }}
              />
            }
            title={t('secretKey.createKeyMsg')}
            contentStyle={{
              padding: '1em !important',
            }}
            disableConfirm={isEmpty(keyName)}
          />

          {/* copy Create Key Model */}
          <ConfirmModelComp
            open={openCopyCreatedKey}
            setOpen={setOpenCopyCreatedKey}
            handleConfirm={handleCopyConfirm}
            component={
              <InputComponent
                value={createdKey || ''}
                //   handleChange={handleChange}
                id="copy-create-api-key"
                label="Secret Key"
                style={{ width: '100%' }}
                endIcon={<CopyButtomComp value={copyValue} />}
                disable={true}
              />
            }
            title={t('secretKey.secretApiKey')}
            contentStyle={{
              padding: '1em !important',
            }}
            msg={t('secretKey.secretApiKeyDesc')}
            hideConfirmButton={true}
            closeButtonText={t('secretKey.confirm')}
          />
        </PermissionsCheck>
      </Grid>
    </>
  );
};

export default ApiKeyContainer;
