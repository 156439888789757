import AccountContainer from './AccountContainer';
import LicenseContainer from './LisenceContainer';
import SideMenu from './SideMenu';
import { useParams } from 'react-router-dom';
import FeedbackContainer from './FeedbackContainer';
import ApiKeyContainer from './apiKeyComp/ApiKeyContainer';
import { Grid, useTheme } from '@mui/material';
import PermissionsCheck from '../../config/PermissionsCheck';
import { Permission } from '../../enums/permission';

const AccountOverview = () => {
  const { page } = useParams();
  const theme = useTheme();
  const styleConatiner = {
    border: `1px solid ${theme.palette.general.lightBlue}`,
    borderRadius: '0.3rem',
    padding: '1.5rem',
    marginTop: '1.5rem',
  };
  const renderContent = () => {
    if (page === 'main' || page === 'undefined') {
      return (
        <>
          <Grid
            item
            xs={12}
            container
            sx={styleConatiner}
          >
            <AccountContainer />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={styleConatiner}
          >
            <LicenseContainer />
          </Grid>
          <PermissionsCheck allowedPermission={[Permission.can_view_apikeys]}>
            <Grid
              item
              xs={12}
              container
              sx={styleConatiner}
            >
              <ApiKeyContainer />
            </Grid>
          </PermissionsCheck>
        </>
      );
    } else if (page === 'support') {
      return (
        <Grid
          item
          xs={12}
        >
          <FeedbackContainer />
        </Grid>
      );
    }
  };
  return (
    <Grid
      item
      container
      xs={12}
      mt="3em"
      mb="2em"
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <Grid
        item
        xs={2}
      >
        <SideMenu />
      </Grid>
      <Grid
        item
        xs={9}
      >
        {renderContent()}
      </Grid>
    </Grid>
  );
};

export default AccountOverview;
