import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FeedbackFormInput } from '../../types/feedback.types';
import { useDispatch } from 'react-redux';
import { sendFeedback } from '../../redux/feedback/actions';

type Props = {};

const FeedbackForm = (props: Props) => {
  const { t } = useTranslation('account');
  const [validated, setValidated] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const [feedbackForm, setFeedbackForm] = useState<FeedbackFormInput>({
    subject: '',
    feedback: '',
  });

  const { subject, feedback } = feedbackForm;
  const handleOnChange = (e: any) => {
    setFeedbackForm({
      ...feedbackForm,
      [e?.target.name]: e?.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();

      return setValidated(true);
    }

    await dispatch(sendFeedback(feedbackForm));
    setFeedbackForm({
      subject: '',
      feedback: '',
    });
  };

  return (
    <Row>
      <Col>
        <Form
          className="mt-2"
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
        >
          <Row>
            <Col xs={12}>
              <Form.Group
                controlId="subject"
                style={{ textAlign: 'left' }}
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  {t('feedback.subject')}
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  //   id="subject"
                  name="subject"
                  value={subject}
                  onChange={handleOnChange}
                  placeholder={t('feedback.subject')}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group
                controlId="feedback"
                style={{ textAlign: 'left' }}
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  {t('feedback.message')}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={t('feedback.message')}
                  required
                  style={{ height: '100px' }}
                  value={feedback}
                  name="feedback"
                  //   id="feedback"
                  onChange={handleOnChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Button
                variant="primary"
                type="submit"
              >
                {t('feedback.submit')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default FeedbackForm;
