import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  emptyStateValueImageUpload,
  searchUploadedImages,
  addImageUpload,
  setImageUploadList,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage,
} from 'src/redux/imageUpload/actions';
import { UploadImageSelector } from 'src/redux/imageUpload/selector';
import { TranslateSelector } from 'src/redux/translation/selector';
import { appMessageHandling } from 'src/utils/errorHandler';
import ImageUploadCompWrapper, {
  UploadImageReducer,
} from '@summ-ai-github/summ-frontend-package/components/imageUploadComp';
import { insertImageToTable } from 'src/redux/translation/actions';

type Props = {};

const ImageUploadFieldWrapper = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const tempImageList = useSelector(
    (state: any) => UploadImageSelector(state).tempImageList,
    shallowEqual
  );

  const imageList = useSelector(
    (state: any) => UploadImageSelector(state).imageList,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => UploadImageSelector(state).loading,
    shallowEqual
  );

  const uploadedImageList = useSelector(
    (state: any) => UploadImageSelector(state).uploadedImageList
  );

  const deletedImgId = useSelector(
    (state: any) => UploadImageSelector(state).deletedImgId
  );
  const selectedOutputNode = useSelector(
    (state: any) => TranslateSelector(state).selectedOutputNode,
    shallowEqual
  );

  return (
    <ImageUploadCompWrapper
      emptyStateValueImageUpload={(data: UploadImageReducer) =>
        dispatch(emptyStateValueImageUpload(data)) as void
      }
      searchUploadedImages={(value: any) =>
        dispatch(searchUploadedImages(value))
      }
      imageList={imageList}
      tempImageList={tempImageList}
      loading={loading}
      langWords={{
        ListUploadTitle: t('ListUploadTitle'),
        searchWord: t('search'),
        searchImage: t('searchImage'),
        myGallary: t('myGallary'),
        bilder: t('bilder'),
        licenseImage: t('licenseImage'),
        uploading: t('uploading'),
        dragUploadImage: t('dragUploadImage'),
        dragAndDropSplitterText: t('input.dragAndDropSplitterText'),
        buttonUpload: t('buttonUpload'),
        noImage: t('noImage'),
      }}
      addImageUpload={(data: any) => dispatch(addImageUpload(data))}
      setImageUploadList={(data: { [key: string]: string }) =>
        dispatch(setImageUploadList(data))
      }
      appMessageHandling={(msg: string) =>
        appMessageHandling(dispatch, msg, 'error')
      }
      downloadUploadedImage={(id: string) =>
        dispatch(downloadUploadedImage(id))
      }
      handleInsertImage={(url: string) => dispatch(insertImageToTable(url))}
      feedbackImageUpload={(
        id: string,
        feedback: 0 | 1 | 2,
        imageId: number,
        isOwner: boolean
      ) => dispatch(feedbackImageUpload(id, feedback, imageId, isOwner))}
      deleteUploadedImage={(id: string, imageId: number, isOwner: boolean) =>
        dispatch(deleteUploadedImage(id, imageId, isOwner))
      }
      selectedOutputNode={selectedOutputNode}
      uploadedImageList={uploadedImageList}
      deletedImgId={deletedImgId}
    />
  );
};

export default ImageUploadFieldWrapper;
