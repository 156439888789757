import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, useTheme } from '@mui/material';
import { checkSwitch } from '../../utils/checkSwitch';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  msg?: string;
  children?: React.ReactNode | undefined;
  component?: React.ReactNode;
  handleConfirm: () => void;
  titleStyle?: object;
  contentStyle?: object;
  hideConfirmButton?: boolean;
  disableConfirm?: boolean;
  closeButtonText?: string;
  modelCloseText?: string;
  modelConfirmOnText?: string;
  modelConfirmOffText?: string;
  confirmText?: string;
  confirmButtonStyle?: object;
  confirmButtonText?: string;
};

const ConfirmModelComp = ({
  open,
  setOpen,
  title,
  msg,
  children,
  handleConfirm,
  component,
  titleStyle = {},
  contentStyle = {},
  hideConfirmButton = false,
  disableConfirm = false,
  closeButtonText,
  modelCloseText = 'Cancel',
  modelConfirmOnText = 'Confirm',
  modelConfirmOffText = 'Confirm',
  confirmText = 'on',
  confirmButtonStyle = {},
  confirmButtonText,
}: Props) => {
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            paddingBottom: 0,
            paddingLeft: '13px',
            paddingTop: '19px',
            ...titleStyle,
          }}
        >
          <Box
            component={'div'}
            sx={{ fontWeight: '600', fontSize: '1.3rem' }}
          >
            {title}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: '13px', ...contentStyle }}>
          {msg && <DialogContentText>{msg}</DialogContentText>}
          <br />
          {component}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '13px',
            // paddingLeft: '13px',
          }}
        >
          <Box>{children}</Box>
          <Box sx={{ paddingRight: '4px' }}>
            {!hideConfirmButton && (
              <Button
                onClick={handleConfirm}
                sx={{
                  textTransform: 'capitalize',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  width: '7em',
                  marginRight: '10px',
                  ...confirmButtonStyle,
                }}
                variant="outlined"
                disabled={disableConfirm}
              >
                {confirmButtonText ||
                  (checkSwitch() === confirmText
                    ? modelConfirmOnText
                    : modelConfirmOffText)}
              </Button>
            )}
            <Button
              onClick={handleClose}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.secondary.main,
                background: theme.palette.primary.main,
                width: '7em',
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
            >
              {closeButtonText ? closeButtonText : modelCloseText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmModelComp;
