import { GET_FEATURES, UPDATE_FEATURE, LOADING, STOP_LOADING } from './types';

const initialState: any = {
  featuresList: null,
  loading: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_FEATURES:
      let featureListUpdate = {};
      payload?.forEach((feature: { feature_id: number }) => {
        featureListUpdate = {
          ...featureListUpdate,
          [feature.feature_id]: feature,
        };
      });

      return {
        ...state,
        featuresList: featureListUpdate,
      };
    case UPDATE_FEATURE:
      return {
        ...state,
        featuresList: {
          ...state.featuresList,
          [payload]: {
            ...state.featuresList[payload],
            seen: true,
          },
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
