const terms_conditions_string: string = `
<h1>Allgemeine Nutzungsbedingungen für SUMM AI</h1>
<p>
  SUMM AI GmbH, Tal 44, 80331 München (nachfolgend
  <strong>SUMM AI</strong>) bietet gewerblich tätigen Kunden (nachfolgend
  <strong>Kunde</strong>) die Bereitstellung einer KI-gestützten
  Online-Übersetzung-Technologie an.
</p>
<ol>
  <li>Leistungsbeschreibung</li>
  <p>
    SUMM AI ist eine KI-gestützte Technologie, welche Texte, die in Alltagssprache
    verfasst wurden, automatisch in leicht verständliche Sprache übersetzt. Bei
    leicht verständlicher Sprache handelt es sich um einen besonderen
    Sprachstil. Die sprachliche Ausdrucksweise zielt dabei auf die besonders
    leichte Verständlichkeit, insbesondere durch die Verwendung einfacher
    Satzstrukturen und zusätzlichen Erklärungen. In Deutschland sind mehr als 10
    Millionen Menschen darauf angewiesen, dass ihnen Informationen in leicht
    verständlicher Sprache zur Verfügung stehen. Nur mit Informationen in leicht
    verständlicher Sprache können Menschen mit Lernschwierigkeiten, ältere oder
    kranke Menschen, oder Menschen die Deutsch lernen, ihren Alltag meistern.
    SUMM AI ist folglich eine KI-gestützte Technologie, welche sprachliche
    Inklusion und digitale Teilhabe fördern kann.
  </p>
  <p>Die Technologie wird in den folgenden Formen zur Verfügung gestellt:</p>
  <ol>
    <li>Web-App bzw. Word Add-In mit folgenden Funktionalitäten:</li>
    <ul>
      <li>
        Assistenten-Funktion:​<br />Automatische Übersetzungen von
        Alltagssprache in Leichte Sprache
      </li>
      <li>
        Glossar-Funktion:​<br />Vorschläge für Konzept-Erklärungen sowohl
        aus bestehenden “Leichte-Sprache-Glossaren” ​als auch durch Eingabe
        eigener Einträge.
      </li>
      <li>Synonym-Funktion:​<br />Synonymvorschläge auf Wortebene.</li>
    </ul>
    <li>Programmatische Schnittstelle (API) mit folgender Funktionalität:</li>
    <ul>
      <li>
        Assistenten-Funktion:​<br />Automatische Übersetzungen von
        Alltagssprache in Leichte Sprache
      </li>
    </ul>
  </ol>

  <p>
    Die Nutzung von SUMM AI erfolgt gemäß den nachfolgenden Nutzungsbedingungen.<br />
  </p>

  <li>Gegenstand der Nutzungsbedingungen</li>
  <p>Allgemein</p>
  <ol>
    <li>
      SUMM AI gewährt dem Kunden Zugang zu den zur Verfügung stehenden
      Online-Übersetzungsdiensten (nachfolgend <strong>Produkte</strong>)
      während der Laufzeit und im Rahmen des Geltungsbereichs dieser
      Vereinbarung.
    </li>
    <li>
      Der vertragliche Umfang der Leistung richtet sich nach dem konkreten
      Angebot oder nach den Angaben im Online-Kaufprozess. Sofern die Produkte
      gegen Entgelt angeboten werden, wird dies im Angebot oder im
      Online-Kaufprozess als kostenpflichtige Nutzung ausdrücklich
      gekennzeichnet.
    </li>
    <li>
      Die Übersetzungen in leicht verständliche Sprache durch SUMM AI erfolgen
      ausschließlich automatisiert durch die KI (Künstliche Intelligenz). Eine
      Überprüfung der Übersetzungen durch eine natürliche Person auf etwaige
      Fehler erfolgt nicht. SUMM AI kann daher nicht gewährleisten, dass eine
      Übersetzung fehlerlos erfolgt. Aufgrund des Lernmechanismus der KI, kann
      es sein, dass die Eingabe des gleichen Textes zu unterschiedlichen
      Übersetzungen führt.
    </li>
    <li>
      SUMM AI richtet sich ausschließlich an Unternehmer im Sinne von § 14 BGB.
      Nach § 14 BGB ist ein Unternehmer eine natürliche oder juristische Person
      oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines
      Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
      beruflichen Tätigkeit handelt.
    </li>
    <li>
      SUMM AI wird die Übersetzungen des jeweiligen Kunden für die Dauer der
      Vereinbarung so speichern und verarbeiten, dass keine unbefugten Personen
      Zugriff darauf haben.
    </li>
    <li>
      SUMM AI behält sich das Recht vor, jederzeit zusätzliche Funktionen zu den
      Produkten hinzuzufügen. Darüber hinaus kann SUMM AI bestehende Funktionen der
      Produkte aus Gründen der Datensicherheit, aufgrund von technischen
      Notwendigkeiten oder Änderungen des geltenden Rechts anpassen,
      einschränken oder entfernen, sofern die Anpassung, Einschränkung oder
      Entfernung unter Berücksichtigung der Interessen beider Parteien für den
      Kunden zumutbar ist. SUMM AI wird die Kunden über solche Anpassungen
      rechtzeitig schriftlich (E-Mail genügt) informieren. Sofern eine
      wesentliche Funktion der Produkte entfernt wird, steht dem Kunden ein
      Sonderkündigungsrecht gem. Ziffer 9 Abs. 3 zu.
    </li>
  </ol>
  <p>a) Web-App bzw. Word Add-In</p>
  <ol>
    <li>
      Die Lizenz zur Nutzung ist eine Einzelnutzerlizenz, d.h. pro Nutzer der
      Produkte muss der Kunde eine Einzelnutzerlizenz erwerben.
    </li>
  </ol>
  <p>b) API</p>
  <ol>
    <li>Die Lizenz zur Nutzung ist eine API-Lizenz.</li>
  </ol>

  <li>Verfügbarkeit</li>
  <p>Allgemein</p>
  <ol>
    <li>
      SUMM AI bietet im Rahmen der kostenpflichtigen Version eine Verfügbarkeit von
      durchschnittlich 98% der Betriebszeit. Für die kostenfreie Version gibt es
      keine garantierte Verfügbarkeit.
    </li>
    <li>
      Ausfallzeiten die auf geplante Wartungsarbeiten oder Unterbrechung, die
      außerhalb des Einflussbereichs von SUMM AI liegen (z.B. höhere Gewalt,
      automatisierte Anfragen von Kunden, die eine Erhöhung der Symstemkapazität
      erfordern) werden nicht in die Verfügbarkeitsberechnung einbezogen.
    </li>
    <li>
      SUMM AI bemüht sich unvermeidbare Ausfallzeiten aufgrund geplanter
      Wartungsarbeiten dem Kunden rechtzeitig im Voraus elektronisch (z.B. per
      E-Mail) anzukündigen.
    </li>
  </ol>

  <li>Zustandekommen des Vertrags</li>
  <p>a) Web-App bzw. Word Add-In</p>
  <ol>
    <li>
      Der Kunde muss sich zunächst auf der Webseite registrieren und zu diesem
      Zwecke verschiedene Daten angeben (E-Mailadresse, Passwort). Der Kunde ist
      verpflichtet, alle zu diesem Zweck erforderlichen Informationen
      vollständig und wahrheitsgemäß anzugeben.
    </li>
    <li>
      Im Falle einer kostenpflichtigen Lizenz übersendet SUMM AI dem Kunden dann
      ein entsprechendes Angebot per E-Mail. Der Vertrag wird geschlossen,
      sobald der Kunde dieses Angebot durch eine Bestätigungse-mail oder
      Eintragung in ein von SUMM AI bereitgestelltes Formular annimmt. Der Kunde
      ist verpflichtet in diesem Fall weitere Informationen (Name,
      Rechnungsadresse) bereitzustellen.
    </li>
  </ol>

  <p>b) API</p>
  <ol>
    <li>
      SUMM AI übersendet dem Kunden ein entsprechendes Angebot per E-Mail. Der
      Vertrag wird geschlossen, sobald der Kunde dieses Angebot durch eine
      formlose E-Mail oder eine Eintragung in ein von SUMM AI bereitgestelltes
      Formular annimmt. Der Kunde ist verpflichtet in diesem Fall weitere
      Informationen (Name, Rechnungsadresse) bereitzustellen.
    </li>
  </ol>

  <li>Verpflichtung der Kunden</li>
  <p>Allgemein</p>
  <ol>
    <li>
      Der Kunde darf die Produkte ausschließlich für den zwischen den Parteien
      vereinbarten Zweck verwenden.
    </li>
    <li>
      Insbesondere darf der Kunde die Produkte, die mit den Produkten erstellten
      Übersetzungen, die Dokumentation oder andere von SUMM AI zur Verfügung
      gestellte Daten, Informationen oder Dienstleistungen nicht für die
      folgenden Zwecke zu nutzen, es sei denn, SUMM AI hat dies ausdrücklich
      schriftlich genehmigt:
    </li>
    <ul>
      <li>
        für Inhalte, die pornographische oder jugendgefährdende Elemente
        enthalten oder anderweitig gegen die guten Sitten, behördliche
        Anordnungen oder gesetzliche Bestimmungen verstoßen;
      </li>
      <li>
        im Zusammenhang mit oder zum Betrieb kritischer Infrastrukturen wie
        Elektrizitätswerken, Militär- oder Verteidigungsanlagen, medizinischen
        Geräten oder anderen Geräten, deren Ausfall oder Beeinträchtigung
        unvorhersehbare wirtschaftliche oder physische Schäden zur Folge hätte,
        (insbesondere aber nicht beschränkt auf kritische Infrastrukturen im
        Sinne der Europäischen Richtlinie 2008/114/EG);
      </li>
      <li>
        für das Versenden von Phishing/Spam-E-Mails und/oder jeder anderen
        unerwünschten Werbung;
      </li>
      <li>
        um Tests jeglicher Art an der technischen Infrastruktur von SUMM AI
        durchzuführen;
      </li>
      <li>
        einen maschinellen Übersetzungsalgorithmus oder ähnliches Produkt, einen
        ähnlichen Service oder eine ähnliche API zu entwickeln, deren Hauptzweck
        darin besteht, maschinelle Übersetzungsdienste wie insbesondere Leichte
        Sprache Wörterbücher anzubieten;
      </li>
    </ul>
    <li>
      Der Kunde verpflichtet sich SUMM AI nicht für Inhalte zu nutzen, die Rechte
      Dritter (insbesondere aber nicht beschränkt auf Urheberrechte,
      Markenrechte, Persönlichkeitsrechte) verletzen.
    </li>
    <li>
      Der Kunde muss sich für den Zugang bei SUMM AI registrieren. Nach der Registrierung oder dem Login werden
      Authentisierungsdaten lokal auf dem Gerät des Kunden gespeichert. Der Kunde ist sich dieses Risikos bewusst und
      selbst dafür verantwortlich, geeignete Sicherheitsmaßnahmen zu ergreifen, um unerlaubten Zugriff durch Dritte zu
      verhindern.
    </li>
    <li>
      Der Kunde stellt sicher, dass die von SUMM AI zur Verfügung gestellten
      Zugangsdaten sicher und vertraulich behandelt werden. Der Kunde ist nicht
      berechtigt Zugangsdaten an Dritte weiterzugeben oder weiterzuverkaufen, es
      sei denn, dies ist für die vertraglich vorgesehene und erlaubte Nutzung
      der Produkte erforderlich und SUMM AI hat voher zugestimmt.
    </li>
    <li>
      Der Kunde ist verpflichtet, SUMM AI nicht für Übersetzungen von
      personenbezogenen Daten Dritter zu nutzen und diese auch nicht an SUMM AI
      weiterzugeben. Die Übersetzung personenbezogener Daten ist momentan nicht
      möglich.
    </li>
    <li>
      Für den Fall, dass der Kunde Anwendungen von Drittanbietern für den
      Zugriff auf die Produkte verwendet, muss der Kunde die entsprechenden
      Richtlinien für die Nutzung einhalten, die gegebenenfalls vom Anbieter
      festgelegt wurden.
    </li>
    <li>
      Der Kunde verpflichtet sich, keine automatisierte Verfahren einzusetzen,
      durch die eine erhöhte Anzahl von Anfragen (über das übliche
      Nutzerverhalten hinaus) an SUMM AI verursachen können.
    </li>
    <li>
      Im Übrigen verpflichtet sich der Kunde alle gesetzlichen Bestimmungen
      (insb. Datenschutzgesetze) und Exportbeschränkungen bei der Nutzung der
      Produkte zu berücksichtigen und einzuhalten.
    </li>
  </ol>

  <p>b) API</p>
  <ol>
    <li>
      Der Kunde ist verantwortlich für die Integration der API in den
      Kunden-eigenen Systemen sowie für die Installation, den Betrieb und die
      Wartung seiner Anwendung, einschließlich der Einhaltung aller geltenden
      Gesetze, Vorschriften und anderer Anforderungen Dritter.
    </li>
    <li>
      Der Kunde muss alle technischen Anforderungen erfüllen, die in der
      Leistungsbeschreibung und der Dokumentation festgelegt sind. Insbesondere
      muss der Kunde sicherstellen, dass alle Inhalte in einem gültigen
      Datenformat und einer gültigen Datenkodierung an die API übertragen
      werden.
    </li>
    <li>
      SUMM AI behält sich vor, die final veröffentlichten Übersetzungen beim Kunden
      zu Qualitätssicherungs- und Qualitätsverbesserungszwecken abzufragen.
    </li>
    <li>
      Der Kunde ist verpflichtet, SUMM AI unverzüglich zu informieren, sollte ein
      signifikanter Anstieg der Anzahl der API-Anfragen zu erwarten sein.
    </li>
  </ol>
  <li>Lizenz und Nutzungsrechte</li>
  <p>Allgemein</p>
  <ol>
    <li>
      SUMM AI räumt dem Kunden ein einfaches, nicht übertragbares und nicht
      unterlizenzierbares, örtlich unbeschränktes Nutzungsrecht an den Produkten
      für die Laufzeit dieser Vereinbarung und der Vertragszwecke ein.
    </li>
    <li>
      Der Kunde überträgt an SUMM AI ein zeitlich und örtlich unbegrenztes,
      einfaches und übertragbares Nutzungsrecht an den durch den Kunden
      hochgeladenen Texten und Inhalten. SUMM AI nutzt diese Daten ausschließlich
      zum Zwecke der Vertragserfüllung und des Trainings und der Verbesserung
      der KI. Soweit zur Leistungserbringung erforderlich, gewährt der Kunde
      SUMM AI das Recht hochgeladene Inhalte zu speichern, zu modifizieren, zu
      verarbeiten, zu übersetzen und zu übertragen sowie die vorstehenden Rechte
      an Dritte in Unterlizenz zu vergeben. Zu diesem Zweck überträgt SUMM AI Texte
      und Daten auf Server von Dritten. Diese Übermittlung ist notwendig, um die
      Services anbieten zu können. SUMM AI verarbeitet die Texte sowie deren
      Übersetzungen für einen unbegrenzten Zeitraum, um neuronale Netze und
      Übersetzungsalgorithmen zu trainieren und zu verbessern. Dies gilt auch
      für Korrekturen, die an den Übersetzungsvorschlägen vorgenommen werden.
      Die Korrekturen werden an die Server von SUMM AI weitergeleitet, um diese auf
      Richtigkeit zu überprüfen und gegebenenfalls den übersetzten Text
      entsprechend der Änderungen zu aktualisieren.
    </li>
    <li>
      SUMM AI beansprucht keine Urheberrechte an den Übersetzungen, die vom Kunden
      unter Verwendung der Produkte erstellt wurden. Für den Fall, dass die vom
      Kunden unter Verwendung der Produkte angefertigten Übersetzungen als
      urheberrechtlich geschützt angesehen werden, gewährt SUMM AI dem Kunden mit
      der Erstellung solcher Übersetzungen alle ausschließlichen, übertragbaren,
      unterlizenzierbaren, weltweit unbefristeten Rechte zur uneingeschränkten
      Nutzung der Übersetzungen für alle bestehenden oder zukünftigen
      Nutzungsarten, einschließlich des Rechts zur Änderung der Übersetzungen
      und zur Erstellung abgeleiteter Werke.
    </li>
    <li>
      Soweit SUMM AI Open-Source-Software einbindet, stellt SUMM AI sicher, dass der
      Kunde an den verarbeiteten Texten die gleichen Rechte wie in 5.2.
      beschrieben erhält. Ist das nicht möglich, wird SUMM AI vor einer Einbindung
      die Zustimmung des Kunden einholen. Die Urheber der
      Open-Source-Komponenten schließen ihre Haftung für die von ihnen kostenlos
      zur Verfügung gestellte Software meistens in einem sehr weiten Umfang aus.
      SUMM AI ist verpflichtet, an dieser Stelle deutlich auf diesen
      Haftungsausschluss hinzuweisen. Die Einbeziehung von Open-Source-Software
      hat keine Auswirkung auf die Gewährleistung nach dieser Vereinbarung.
    </li>
  </ol>
  <p>b) API</p>
  <ol>
    <li>
      Der Kunde kann Nutzern innerhalb des eigenen Unternehmens und nach eigenem
      Ermessen Zugang zu der Übersetzungsfunktionalität gewähren. Der Kunde
      haftet jedoch in vollem Umfang für die Nutzung der Funktionalität durch
      die internen Nutzer und stellt sicher, dass die internen Nutzer alle in
      dieser Vereinbarung festgelegten Beschränkungen für die Nutzung der
      Funktionalität kennen und einhalten.
    </li>
    <li>
      Dem Lizenznehmer ist es nicht gestattet:
      <ul>
        <li>
          die API zu kopieren, zu übertragen, zu verkaufen, eine Unterlizenz zu
          erteilen oder auf andere Weise die API einem Dritten zur Verfügung zu
          stellen. Dies gilt sowohl für kommerzielle als auch für
          nicht-kommerzielle Zwecke;
        </li>
        <li>
          die API so zu verwenden, dass hierdurch die Nutzung von SUMM AI einem
          Dritten ermöglicht wird, der nicht unter die Lizenz- oder
          Nutzungsbedingungen fällt.
        </li>
        <li>
          die API anders als im gesetzlich zulässigen Umfang zu verändern,
          anzupassen, zu verbinden, zu modifizieren, zu übersetzen, zu
          dekompilieren, zu zerlegen oder abgeleitete Werke des gesamten oder
          eines Teils der API, ohne die vorherige schriftliche Zustimmung des
          Lizenzgebers, herzustellen.
        </li>
        <li>
          Der Lizenznehmer erkennt an, dass das Eigentum an den Rechten, an
          geistigem Eigentum in und an der API, einschließlich aller Kopien, bei
          SUMM AI liegt.
        </li>
      </ul>
    </li>
  </ol>
  <li>Freistellung von Ansprüchen Dritter</li>
  <p>Allgemein</p>
  <p>
    Der Kunde stellt SUMM AI von allen Ansprüchen Dritter einschließlich der
    notwendigen Kosten für die Rechtsverteidigung frei, die gegen SUMM AI aufgrund
    einer schuldhaften Verletzung von Rechten Dritter durch den Kunden geltend
    gemacht werden. Sollten Dritte derartige Ansprüche gegen SUMM AI geltend
    machen, wird SUMM AI den Kunden unverzüglich über die geltend gemachten
    Ansprüche informieren und die Verteidigung nach Wahl des Kunden dem Kunden
    überlassen oder in Zusammenarbeit mit dem Kunden vornehmen. Der Kunde
    verpflichtet sich für den Fall einer Inanspruchnahme durch Dritte
    unverzüglich, wahrheitsgemäß und vollständig sämtliche Informationen zur
    Verfügung zu stellen, die für die Prüfung der Ansprüche und eine
    Rechtsverteidigung erforderlich sind.
  </p>
  <p>
    SUMM AI wird Ansprüche Dritter nicht ohne Zustimmung des Kunden erfüllen oder
    anerkennen, wobei die Zustimmung des Kunden nicht unangemessen verweigert
    oder verzögert werden darf. Soll SUMM AI die Rechtsverteidigung für den Kunden
    übernehmen, ist SUMM AI berechtigt, einen angemessenen Vorschuss für die
    voraussichtlich anfallenden Kosten der Rechtsverteidigung zu verlangen. Die
    Freistellung gilt entsprechend, wenn durch ein Verhalten des Kunden
    Bußgelder oder andere behördliche oder gerichtliche Anordnungen und
    Ansprüche gegen SUMM AI gerichtet werden.
  </p>

  <li>Preise und Vergütungsregelung</li>
  <p>Allgemein</p>
  <ol>
    <li>
      Die konkret vom Kunden geschuldete Vergütung für die Nutzung der Produkte
      ergibt sich aus dem konkreten Angebot oder aus dem im Online-Kaufprozess
      angegebenen Preis des ausgewählten Pakets.
    </li>
    <li>
      Die Rechnungsstellung erfolgt im 3-Monats-Rhythmus (bei monatlicher
      Laufzeit) oder im 12-Monats-Rhythmus (bei jährlicher Laufzeit). Wenn der
      Kunde kündigt, erfolgt die Rechnungsstellung zum Ende der Laufzeit. Die
      Rechnung wird von SUMM AI per E-Mail an den Kunden gesendet.
    </li>
    <li>
      Der Rechnungsbetrag ist innerhalb von 30 Tagen ab Rechnungsstellung zu
      entrichten. SUMM AI behält sich das Recht vor nach schriftlicher Mitteilung
      den Zugang des Kunden zu sperren, wenn der Kunde die fällige Rechnung
      nicht fristgerecht bezahlt. Nach vollständigem Zahlungseingang des offenen
      Betrags wird SUMM AI den Zugang innerhalb von drei Werktagen
      wiederherstellen.
    </li>
    <li>
      Alle Beträge werden in der auf der Rechnung in EUR angegeben und sind in
      voller Höhe zu zahlen. Ggf. anfallenden Bank- und Überweisungsgebühren und
      etwaige Kosten für eine Währungsumrechnung sind vom Kunden zu entrichten.
    </li>
    <li>
      Rechnungen stellt SUMM AI ausschließlich in digitaler Form zur Verfügung (z.
      B. per E-Mail), außer das Gesetz schreibt etwas anderes vor.
    </li>
    <li>
      Alle Preise von SUMM AI verstehen sich zzgl. Mehrwertsteuer, es sei denn,
      diese sind ausdrücklich als Bruttopreise angegeben.
    </li>
  </ol>

  <li>Laufzeit und Beendigung dieser Vereinbarung</li>
  <p>Allgemein</p>
  <ol>
    <li>
      Die Vereinbarung wird auf unbestimmte Zeit geschlossen. Bei monatlicher Laufzeit kann jede Partei die Vereinbarung
      bis spätestens 14 Tage vor Ablauf der Laufzeit mit Wirkung zum Ende der Laufzeit per E-Mail kündigen. Bei
      jährlicher Laufzeit kann jede Partei die Vereinbarung bis spätestens 3 Monate vor Ablauf der Laufzeit mit Wirkung
      zum Ende der Laufzeit per E-Mail kündigen. Im Falle des Kunden geschieht dies per E-Mail an <a
        href="mailto:kuendigung@summ-ai.com">kuendigung@summ-ai.com</a>
      unter Angabe der jeweiligen Kundennummer (diese finden Sie in der Bestätigungsmail für Ihre Lizenz oder bei Bedarf
      auf Ihrer letzten Rechnung) im Betreff und der für die Lizenz verwendete E-Mail-Adresse.<br />
    </li>
    <li>
      Falls nicht anders vereinbart endet die kostenlose Probeversion von SUMM AI
      14 Tage nach Vertragsschluss.
    </li>
    <li>
      Wenn die Parteien eine feste Laufzeit vereinbaren, verlängert sich die
      Laufzeit automatisch um die vereinbarte feste Laufzeit, sofern die
      Vereinbarung nicht gekündigt wird.<br />
    </li>
    <li>
      Bei individuellem Angebot ergibt sich die Vereinbarung über die Laufzeit
      der Nutzung von SUMM AI aus dem konkreten Angebot.
    </li>
    <li>
      Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt
      unberührt. Ein wichtiger Grund zur außerordentlichen Kündigung liegt für
      eine Vertragspartei insbesondere vor, wenn
      <ol type="a">
        <li>
          die andere Vertragspartei eine Pflicht in erheblichem Umfang
          schuldhaft verletzt und diese Verletzung nicht innerhalb einer von der
          kündigenden Vertragspartei gesetzten angemessenen Frist abstellt; oder
        </li>
        <li>der Kunde die Produkte automatisiert nutzt; oder</li>
        <li>der Kunde zahlungsunfähig ist.</li>
      </ol>
    </li>
    <li>
      Eine Kündigung und eine einer Kündigung vorausgehende Fristsetzung
      bedürfen der Schriftform.
    </li>
  </ol>

  <li>Haftung</li>
  <p>Allgemein</p>
  <ol>
    <li>
      SUMM AI haftet nur für Schäden, die durch Vorsatz oder grobe Fahrlässigkeit
      von SUMM AI, einem Vertreter oder Erfüllungsgehilfen verursacht wurden oder
      wenn die Schäden aus der Verletzung des Lebens, des Körpers und der
      Gesundheit bestehen sowie in Fällen der zwingend gesetzlichen Haftung,
      insbesondere nach dem Produkthaftungsgesetz (ProdHaftG);
    </li>
    <li>
      Für Schäden, die durch einfache Fahrlässigkeit verursacht wurden, haftet
      SUMM AI nur, soweit diese Fahrlässigkeit die Verletzung wesentlicher
      Vertragspflichten betrifft, deren Einhaltung für die Erreichung des
      Vertragszwecks von besonderer Bedeutung ist (Kardinalpflichten). Zu den
      „Kardinalpflichten“ zählen solche Pflichten, deren Verletzung den
      Vertragszweck gefährden würde und auf deren Erfüllung der Vertragspartner
      daher berechtigterweise vertrauen darf. Dabei wird jedoch nur gehaftet,
      soweit die Schäden in typischer Weise mit dem Vertrag verbunden und
      vorhersehbar sind;
    </li>
    <li>
      SUMM AI haftet nicht für Datenverlust, es sei denn, dieser wurde durch grobe
      Fahrlässigkeit oder vorsätzlich verursacht und der Schaden war auch durch
      geeignete Datensicherung nicht zu vermeiden.
    </li>
    <li>
      Im Übrigen ist die Haftung von SUMM AI ausgeschlossen. Ein Ausschluss oder
      die Beschränkung der Haftung, gilt auch für die persönliche Haftung der
      Angestellten, Vertreter und Erfüllungsgehilfen von SUMM AI und für
      Schadensersatzansprüche aus unerlaubter Handlung.
    </li>
  </ol>

  <li>Gewährleistung</li>
  <p>Allgemein</p>
  <ol>
    <li>
      SUMM AI gewährleistet nicht, dass die Produkte den Anforderungen des Kunden
      entspricht. Festgelegte Spezifikationen stellen keine Garantien dar, es
      sei denn, sie sind ausdrücklich als solche bezeichnet.
    </li>
    <li>
      Bei Vorliegen eines Gewährleistungsfalls wird SUMM AI nach eigener Wahl
      Mängel durch Fehlerbehebung, Ersatzbeschaffung beheben. SUMM AI stehen zwei
      Versuche zur Nachbesserung zu. Gelingt es SUMM AI nicht, die Mängel innerhalb
      eines angemessenen Zeitraums zu beheben, ist der Kunde zur Reduzierung der
      Vergütung („Minderung“) berechtigt. Sollte es sich um einen wesentlichen
      Mangel handeln, ist der Kunde statt zur Minderung auch zum Rücktritt
      berechtigt.
    </li>
    <li>
      Der Kunde ist verpflichtet einen Gewährleistungsfall unverzüglich
      schriftlich (E-Mail ist ausreichend) an SUMM AI zu melden.
    </li>
    <li>
      Dem Kunden stehen keine Gewährleistungsansprüche zu,
      <ul>
        <li>
          wenn er die Produkte nicht bestimmungsgemäß oder missbräuchlich nutzt,
          oder
        </li>
        <li>wenn er die Produkte modifiziert oder verändert, oder</li>
        <li>
          wenn Probleme oder Fehler darauf beruhen, dass das Produkt mit unter
          Anwendungsbedingungen genutzt werden, die nicht der Hard- und
          Softwareumgebung entsprechen, die in der dem Produkt beiliegenden
          Dokumentation oder durch andere Mitteilungen von SUMM AI spezifiziert
          sind,
        </li>
      </ul>
      <p>
        es sei denn, der Kunde kann nachweisen, dass der Mangel auf einen Fehler
        im Produkt zurückzuführen ist.
      </p>
    </li>
    <li>
      Sofern dem Kunden aufgrund Gewährleistung ein Anspruch auf Schadenersatz
      oder Ersatz vergeblicher Aufwendungen zusteht, unterliegt dieser der
      Haftungsbeschränkung aus Ziffer 10.
    </li>
    <li>
      Gewährleistungsanspüche für die Produkte verjähren in 12 Monaten ab
      Ermöglichung der Nutzung der Produkte. Hiervon ausgenommen sind
      Schadensersatzansprüche sowie Mängelansprüche bei arglistigem Verschweigen
      eines Mangels
    </li>
  </ol>

  <li>Datenschutz</li>
  <p>Allgemein</p>
  <ol>
    <li>
      Eine Übermittlung von personenbezogenen Daten durch Kunden an SUMM AI ist
      nicht vorgesehen und nach diesen Nutzungsbedingungen ausdrücklich
      untersagt. SUMM AI wird demnach weder als Mitverantwortlicher noch als
      Auftragsverarbeiter der Kunden tätig.
    </li>
    <li>
      Im Übrigen wird auf die Datenschutzerklärung von SUMM AI verwiesen, welche
      <a href="https://app.summ-ai.com/dataprivacy">hier</a> abrufbar ist.
    </li>
  </ol>

  <li>Änderung der Nutzungsbedingungen</li>
  <p>Allgemein</p>
  <p>
    SUMM AI behält sich vor, diese Nutzungsbedingungen mit Wirkung für die Zukunft
    zu ändern oder anzupassen, wenn
  </p>
  <ul>
    <li>dies aufgrund einer Änderung der Marktlage erforderlich ist,</li>
    <li>dies aufgrund einer Änderung des Geschäftsmodells erforderlich ist,</li>
    <li>
      die Änderungen oder Anpassungen erforderlich sind, um eine Übereinstimmung
      mit geltendem Recht herzustellen, vor allem auch, wenn sich die geltende
      Rechtslage ändert, oder um höchstrichterlicher Rechtsprechung oder einer
      Behördenentscheidung nachzukommen,
    </li>
    <li>
      dies zur Korrektur einer für unwirksam erklärten Klausel erforderlich ist,
    </li>
    <li>
      die Änderungen oder Anpassungen ohne wesentlichen Einfluss auf Funktionen
      der Dienste oder rein technischer oder organisatorischer Art sind oder
    </li>
    <li>
      eine Änderung bzw. Anpassung bestehender oder neuer Dienste oder
      Leistungen von SUMM AI erfolgt ist, die einer Aktualisierung der Beschreibung
      in den Nutzungsbedingungen bedarf, es sei denn, dass dies für das laufende
      Nutzungsverhältnis nachteilig wäre und der Kunde dadurch nicht zumutbar
      belastet wird.
    </li>
  </ul>
  <p>
    Der Kunde wird über alle Änderungen im Voraus in Textform informiert (z.B.
    per E-Mail). Im Falle wesentlicher Änderungen steht dem Kunden ein
    fristloses außerordentliches Kündigungsrecht zu.
  </p>


  <li>Schlussbestimmungen</li>
  <p>Allgemein</p>
  <ol>
    <li>
      Es gilt das Recht der Bundesrepublik Deutschland mit Ausnahme des
      UN-Kaufrechtes (CISG).
    </li>
    <li>Allgemeiner Erfüllungsort ist München.</li>
    <li>
      Ausschließlicher Gerichtsstand für alle Streitigkeiten, die sich aus
      diesem Vertrag ergeben, ist München, wenn der Vertragspartner Kaufmann,
      juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
      Sondervermögen ist oder er seinen Sitz bzw. Wohnsitz nicht innerhalb
      Deutschlands hat.
    </li>
    <li>
      Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein
      oder die Wirksamkeit durch einen später eintretenden Umstand verlieren,
      berührt dies die Wirksamkeit des Vertrages im Übrigen nicht. Anstelle der
      unwirksamen Bestimmungen tritt eine Regelung, die dem am nächsten kommt,
      was die Vertragsparteien gewollt hätten, wenn sie den betreffenden Punkt
      bedacht hätten.
    </li>
  </ol>
</ol>
`;

export default terms_conditions_string;
