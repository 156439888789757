import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SideMenuItem = (props) => {
  return (
    <Nav.Item className={'mb-1 rounded bg-light '}>
      <Nav.Link
        as={props.as ? props.as : Link}
        to={props.to}
      >
        {props.label}
      </Nav.Link>
    </Nav.Item>
  );
};

export default SideMenuItem;
