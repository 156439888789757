import React from 'react';
import SynonymsListEntry from './SynonymsListEntry';
import List from '@mui/material/List';
import { SynonymsListProps } from './synonyms.types';

const SynonymsList = ({ synonyms }: SynonymsListProps) => {
  return (
    <>
      {synonyms.map((family: { synonym: {} }[], i:number) => (
        <List dense={true} key={i + 1}>
          {family.map((synonym: any, j: number) => (
            <SynonymsListEntry synonym={synonym} key={j + 1} />
          ))}
        </List>
      ))}
    </>
  );
};

export default SynonymsList;
