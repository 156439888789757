export const menuItem: (t: any) => {
  id: string;
  name: string;
  value: string;
  tooltip: string;
}[] = (t) => [
  {
    id: '1',
    name: t('my_word_list'),
    value: 'True',
    tooltip: t('private_word'),
  },
  {
    id: '2',
    name: t('company_word_list'),
    value: 'False',
    tooltip: t('company_word'),
  },
];
