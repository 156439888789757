import { appMessageHandling } from '../../utils/errorHandler';
import {
  DELETE_IMAGE_GALLERY,
  FETCH_MY_GALLERY,
  LOADING,
  STOP_LOADING,
  UPDATE_GALLERY_STATE,
} from './types';
import imageUploadService from 'src/services/imageUpload.service';

export const fetchMyGallery =
  (
    fields: { page_size: string; page: number } = { page_size: '10', page: 1 }
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await imageUploadService.fetchGalleryApi({
        fetch_mode: 'user',
        ...fields,
      });
      dispatch({
        type: FETCH_MY_GALLERY,
        payload: { ...res.data, ...fields },
      });
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.fetching_my_gallery', 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteGalleryImage =
  (id: string) => async (dispatch: any, getState: any) => {
    let stateImageVal = getState().myGallery.galleryList.images;
    let currentPage = getState().myGallery.currentPage;
    let count = getState().myGallery.count;

    try {
      await imageUploadService.deleteUploadedImageApi(id);
      dispatch({
        type: DELETE_IMAGE_GALLERY,
        payload: id,
      });

      if (
        Object.values(stateImageVal).length - 1 <= 0 ||
        currentPage < count / 10
      ) {
        dispatch(
          fetchMyGallery({
            page_size: '10',
            page: currentPage,
          })
        );
      }
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.fetching_my_gallery', 'error');
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const updateGalleryState = (value: string) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_GALLERY_STATE,
    payload: value,
  });
};
