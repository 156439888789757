import Alert from '@mui/material/Alert';
import React from 'react';
type Props = {
  AIUnauthWord: string | TrustedHTML;
  showAiSearchUnauthAlert: (value: boolean) => void;
};

const GlossaryAlerts = ({ AIUnauthWord, showAiSearchUnauthAlert }: Props) => {
  return (
    <Alert
      severity="error"
      onClose={() => showAiSearchUnauthAlert(false)}
      icon={false}
    >
      {' '}
      <span
        dangerouslySetInnerHTML={{
          __html: AIUnauthWord,
        }}
      />
    </Alert>
  );
};

export default GlossaryAlerts;
