import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Tooltip } from '@mui/material';

type Props = {
  menuItem: {
    id: string | number;
    value: number | string;
    text: string;
    label?: string;
  }[];
  label?: string;
  id?: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  value: string | undefined;
  disabled?: boolean;
  toolTipsTitle?: string | undefined;
  toolTipPlacement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  style?: object;
  formStyle?: object;
  name?: string;
};

const MaterialSelectComp = ({
  menuItem,
  label,
  id = 'demo-select-small-label',
  handleChange,
  value,
  disabled = false,
  toolTipsTitle = undefined,
  toolTipPlacement = 'bottom',
  style,
  formStyle,
  name,
}: Props) => {
  return (
    <Tooltip
      title={toolTipsTitle}
      placement={toolTipPlacement}
    >
      <FormControl
        sx={{ m: 1, minWidth: 120, width: '37%', mt: '1em', ...formStyle }}
        size="small"
      >
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          sx={style}
          name={name}
        >
          {menuItem.map(({ id, value, text, label }) => (
            <MenuItem
              value={value}
              key={id}
              id={value as string}
            >
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default MaterialSelectComp;
