import { createSelector } from 'reselect';
import { VariantType } from 'notistack';

export interface Config {
  trigger: boolean;
  AlertMessage: { msg: string; variant: VariantType; options?: object };
  sidebarTab: number;
  textareaDimension: { width: number; height: number };
}
interface RootState {
  appSetting: Config;
}

// This used to memorize the selector value
export const configSelector = createSelector(
  (state: RootState) => state.appSetting,
  (config: Config) => {
    return {
      trigger: config.trigger,
      AlertMessage: config.AlertMessage,
      sidebarTab: config.sidebarTab,
      textareaDimension: config.textareaDimension,
    };
  }
);
