import { OrderData } from 'src/types/orderForm.types';
import http from '../config/httpComman';

export default class OrderFormService {
  static async getUserPrefillAPI() {
    return await http.get('user/prefill');
  }

  static async userPurchaseAPI(data: OrderData) {
    return await http.post('user/purchase/', data);
  }
}
