import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { filterList } from './fixedData';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTransHistory } from 'src/redux/translationHistory/actions';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import { languageType } from 'src/enums/languageType';

type Props = {
  showLang: string;
  setShowLang: any;
};

const TranslationTypeFilter = ({ showLang, setShowLang }: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const dispatch: any = useDispatch();
  const page = useSelector(
    (state: any) => HistorySelector(state).currentPage,
    shallowEqual
  );
  const entries = useSelector(
    (state: any) => HistorySelector(state).entries,
    shallowEqual
  );

  const filterValue = useSelector(
    (state: any) => HistorySelector(state).filters,
    shallowEqual
  );

  const handleFilter = (event: any) => {
    dispatch(
      getTransHistory({
        page: page,
        page_size: entries,
        ...(event?.target.value === languageType.none_lang
          ? {}
          : {
              task: event?.target.value,
            }),
        ...(filterValue ? filterValue : {}),
      })
    );
  };
  return localStorage.getItem('i18nextLng') !== 'fr' ? (
    <Col md={{ span: '2' }}>
      <FormControl sx={{ width: '100%', textAlign: 'left' }}>
        <InputLabel
          id="TranslationHistory-entries-filter-label"
          className="bg-white"
        >
          {t('history.jobTask.header')}
        </InputLabel>
        <Select
          labelId="TranslationHistory-entries-filter-label"
          value={showLang}
          onChange={(event) => {
            setShowLang(event?.target.value);
            handleFilter(event);
          }}
        >
          {filterList.map(({ name, value }) => (
            <MenuItem
              value={value}
              key={value}
            >
              {t(name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Col>
  ) : (
    <></>
  );
};

export default TranslationTypeFilter;
