import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { GlossaryEntriesSelector } from 'src/redux/glossaryEntries/selector';
import styles from './GlossaryEntries.module.css';
import { FixMe } from 'src/types/types';
import { useTranslation } from 'react-i18next';

type Props = {
  params: any;
  removeTag: (index: number, row: FixMe) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, row: FixMe) => void;
  role?: string;
};

const GlossaryTags = ({ params, removeTag, handleKeyDown, role }: Props) => {
  const { t } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  const tags = useSelector(
    (state: any) => GlossaryEntriesSelector(state).tags,
    shallowEqual
  );
  return (
    <div
      className={styles['tags-input-container']}
      role={role}
    >
      {tags[params.row.id]?.map((tag, index) => (
        <div
          className={styles['tag-item'] + ' tag-item-glossary-table'}
          key={index}
        >
          <span className="text">{tag}</span>
          <span
            className={styles['close'] + ' delete-tag-glossary-table'}
            onClick={() => removeTag(index, params.row)}
            aria-label={t('glossaryEntries.deleteTag') + tag}
          >
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={(e) => handleKeyDown(e, params.row)}
        type="text"
        className={styles['tags-input']}
        placeholder={t('glossaryEntries.enterTags')}
        aria-label={t('glossaryEntries.tagInputForWord') + params.row.word}
      />
    </div>
  );
};

export default GlossaryTags;
