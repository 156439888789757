import { Nav, Container } from 'react-bootstrap';
// import { withRouter } from 'react-router-dom';
import SideMenuItem from './SideMenuItem';
import SideMenuLogoutItem from './SideMenuLogoutItem';
import { useTranslation } from 'react-i18next';

const Side = (props) => {
  const { t } = useTranslation('account');
  return (
    <Container className="border rounded-lg h-100">
      <Nav
        className="d-md-block pt-3 pb-5 text-center"
        defaultActiveKey="/account/main"
      >
        <div className="sidebar-sticky"></div>
        <SideMenuItem
          to="../account/main"
          label={t('sidebar.account')}
        />
        <SideMenuItem
          to="../account/support"
          label={t('sidebar.support')}
        />
        <SideMenuLogoutItem />
      </Nav>
    </Container>
  );
};
// const SideMenu = withRouter(Side);
export default Side;
