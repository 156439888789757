import React from 'react';
import { useMemo, useRef } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import DragDropComp from './DragDropComp';
import FileUploading from './FileUploading';
import { Typography } from '@mui/material';
import { LangWords } from './image.types';

type Props = {
  tempImageList: { [key: string]: string };
  addImageUpload: (data: any) => void;
  setImageUploadList: (data: { [key: string]: string }) => void;
  appMessageHandling: (msg: string) => void;
  langWords: LangWords;
  uploadedImageList: {
    [key: string]: { msg: string; status: 'error' | 'success'; id: string };
  };
};

const ImageUpload = ({
  tempImageList,
  setImageUploadList,
  addImageUpload,
  appMessageHandling,
  langWords,
  uploadedImageList,
}: Props) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      },
      noClick: true,
      noKeyboard: true,
      // maxFiles: 10,
      maxSize: 10 * 1024 * 1024, // file isze must not exceed 10mb,
      onDropAccepted: (acceptedFiles) => {
        addImageUpload(acceptedFiles);
        let temFileAccepted = {};
        acceptedFiles.forEach((file: File) => {
          temFileAccepted = {
            ...temFileAccepted,
            [file.name]: { name: file.name },
          };
        });
        setImageUploadList({ ...tempImageList, ...temFileAccepted });
      },
      onDropRejected: (files, e) => {
        files.map((file: FileRejection) =>
          appMessageHandling(
            file.errors[0].code === 'file-too-large'
              ? 'File is too large'
              : file.errors[0].message
          )
        );
      },
    });
  const { ref } = getRootProps();
  const dragRef: any = useRef(null);

  const files = useMemo(
    () =>
      Object.values(tempImageList).map((file: any, index: number) => {
        return (
          <FileUploading
            key={file.path + index}
            file={file}
            uploadedImageList={uploadedImageList}
            langWords={langWords}
          />
        );
      }),
    [tempImageList, uploadedImageList, langWords]
  );
  return (
    <Grid
      item
      container
      xs={12}
      ref={ref}
      spacing={1}
    >
      <Grid
        item
        container
        xs={12}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <DragDropComp
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          open={open}
          dragRef={dragRef}
          langWords={langWords}
        />
      </Grid>

      {Object.values(tempImageList).length > 0 && (
        <Grid
          item
          container
          xs={12}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                textWrap: 'pretty',
                textAlign: 'left',
              }}
            >
              {langWords.ListUploadTitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {files}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ImageUpload;
