import React from 'react';
import { Form } from 'react-bootstrap';

const FormSelect = React.forwardRef(
  (
    {
      // register
      name,
      onChange,
      onBlur,
      // custom
      label,
      defaultValue,
      options,
      error,
      feedback,
      id,
    },
    ref
  ) => {
    const listOptions = options.map((option, index) => (
      <option
        key={index}
        value={option.value}
        label={option.lable}
      >
        {' '}
        {option.label}
      </option>
    ));

    return (
      <>
        <Form.Label htmlFor={id}>{label}</Form.Label>
        <Form.Control
          className={feedback ? '' : 'mb-3'}
          as="select"
          defaultValue={defaultValue}
          isInvalid={error}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          id={id}
        >
          {listOptions}
        </Form.Control>
        {feedback ? (
          <Form.Control.Feedback
            className="mb-3"
            type="invalid"
          >
            {feedback}
          </Form.Control.Feedback>
        ) : (
          <></>
        )}
      </>
    );
  }
);

export default FormSelect;
