import { appMessageHandling } from '../../utils/errorHandler';
import {
  LOADING,
  STOP_LOADING,
  GET_HISTORY,
  UPDATE_HISTORY,
  DELETE_HISTORY,
  TAGS_UPDATE,
  SET_FILTER_VALUE,
  UPDATE_PAINATION,
  UPDATE_JOB_VALUE,
} from './types';
import Sentry from '../../config/sentryConfig';
import transHistoryService from 'src/services/transHistory.service';
import {
  FilterValue,
  GetHistoryInput,
  UpdateHistoryInput,
} from 'src/types/transHistory.types';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

export const getTransHistory =
  (data: GetHistoryInput, loadingFetch: boolean = true) =>
  async (dispatch: any) => {
    try {
      if (loadingFetch) {
        dispatch(loading());
      }
      const { actualValue, operation, field, value, ...sendData } = data;
      const dataUpdate = { operation, field, value, ...sendData };
      // If the any of filter value empty remove all
      if (isEmpty(operation) || isEmpty(field) || isEmpty(value)) {
        delete dataUpdate['operation'];
        delete dataUpdate['field'];
        delete dataUpdate['value'];
      }
      const res: AxiosResponse<object> =
        await transHistoryService.getTransHistoryApi({
          ...dataUpdate,
        });
      dispatch({
        type: GET_HISTORY,
        payload: {
          ...res.data,
          currentPage: data.page,
          entries: data.page_size,
        },
      });
    } catch (e: any) {
      Sentry.captureMessage(
        `Get Translation History Items Error: ${e}`,
        'error'
      );
    } finally {
      if (loadingFetch) {
        dispatch(stopLoading());
      }
    }
  };

export const updateTransHistory =
  (data: UpdateHistoryInput) => async (dispatch: any) => {
    try {
      const res = await transHistoryService.updateTransHistoryApi(data);
      dispatch({
        type: UPDATE_HISTORY,
        payload: res.data,
      });
      return appMessageHandling(
        dispatch,
        'success.updateTranslationJobSuccess',
        'success'
      );
    } catch (e: any) {
      appMessageHandling(dispatch, 'error.updateTranslationJobError', 'error');
      Sentry.captureMessage(
        `Update Translation History Item Error: ${e}`,
        'error'
      );
    }
  };

export const deleteTransHistory =
  (id: string | number) => async (dispatch: any) => {
    try {
      await transHistoryService.deleteTransHistoryApi(id);
      dispatch({
        type: DELETE_HISTORY,
        payload: id,
      });
      appMessageHandling(
        dispatch,
        'success.deleteTranslationJobSuccess',
        'success'
      );
    } catch (e: any) {
      appMessageHandling(dispatch, 'error.deleteTranslationJobError', 'error');
      Sentry.captureMessage(
        `Delete Translation History Item Error: ${e}`,
        'error'
      );
    }
  };

export const fIlterValueSet = (data: FilterValue) => (dispatch: any) => {
  dispatch({
    type: SET_FILTER_VALUE,
    payload: data,
  });
};

export const tagsUpdate = (data: any) => (dispatch: any) => {
  return dispatch({
    type: TAGS_UPDATE,
    payload: data,
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
export const updatePagination = (page: number) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_PAINATION,
    payload: page,
  });
};

export const updateJobValue = (data: any) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_JOB_VALUE,
    payload: data,
  });
};
