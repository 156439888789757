import React from 'react';
import { Grid } from '@mui/material';
import SearchInputComp from '../../coreUI/searchComp/SearchInputComp';

type Props = {
  getGlossaryFromInputField: (value: string) => void;
  loadingGlossary: boolean;
  setGlossarInput: React.Dispatch<React.SetStateAction<string>>;
  searchGlossaryWord: string;
  searchWord: string;
  wrapperStyle?: object;
};

const GlossaryInputField = ({
  getGlossaryFromInputField,
  loadingGlossary,
  setGlossarInput,
  searchGlossaryWord,
  searchWord,
  wrapperStyle = {},
}: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
    >
      <SearchInputComp
        handleSubmit={(value: string) => {
          setGlossarInput(value);
          getGlossaryFromInputField(value);
        }}
        loading={loadingGlossary}
        placeholder={searchGlossaryWord}
        buttonId="glossarySearchButton"
        inputId="glossaryInputField"
        searchWord={searchWord}
        wrapperStyle={wrapperStyle}
      />
    </Grid>
  );
};

export default GlossaryInputField;
