import TextareaComp from '../../coreUI/TextAreaComp/TextareaComp';
import { summaryOutput } from './summary.types';
import React from 'react';

type Props = {
  heightInput: number;
  summarySelector: summaryOutput | null;
  noSummaryText: string;
};

const SumaryText = ({ heightInput, summarySelector, noSummaryText }: Props) => {
  return (
    <TextareaComp
      value={summarySelector?.translated_summary || noSummaryText}
      disabled={true}
      multiline={true}
      // maxRows={7}
      // minRows={7}
      style={{
        height: heightInput - 37,
        minHeight: 337 - 37,
        width: '100%',
        '& .base-Input-multiline': {
          width: '100%',
          border: `none !important`,
          resize: 'none !important',
          boxShadow: 'none !important',
          '&:focus': {
            outline: 'none !important',
            boxShadow: 'none !important',
          },
        },
      }}
    />
  );
};

export default SumaryText;
