import { Typography, Grid } from '@mui/material';
import React from 'react';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import { ErrorCheck, FormData, FormDataKeys } from 'src/types/orderForm.types';

type Props = {
  formData: FormData;
  handleChange: (data: FormData | object) => void;
  error: ErrorCheck;
  handleEmptyFieldError: (field: FormDataKeys) => void;
};

const RechnungAdresse = ({
  formData,
  handleChange,
  error,
  handleEmptyFieldError,
}: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      textAlign={'left'}
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant="h5">Rechnungsadresse</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={formData.street}
          disable={false}
          label="Straße und Hausnummer"
          id="street"
          handleChange={(e) => handleChange({ street: e?.target.value })}
          require={true}
          style={{ width: '100%' }}
          error={error?.street ?? false}
          handlekeyUp={(e) => handleEmptyFieldError('street')}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.zipcode}
            label="Postleitzahl"
            id="companyPostalCode"
            handleChange={(e) => handleChange({ zipcode: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.zipcode ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('zipcode')}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.city}
            label="Ort"
            id="companyCity"
            handleChange={(e) => handleChange({ city: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.city ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('city')}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.vat}
            label="USt-IdNr. (Grund falls nicht vorhanden)"
            id="companyVat"
            handleChange={(e) => handleChange({ vat: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.vat ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('vat')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RechnungAdresse;
