import { Supabase } from './selector';
import {
  GET_SESSION,
  LOADING,
  LOGIN,
  LOGOUT,
  REFRESH_SESSION,
  SET_NEW_PASSWORD,
  SIGN_UP,
  STOP_LOADING,
  UPDATE_TOKEN,
  UPDATE_USER,
  VERIFY_OTP,
} from './types';

const initialState: Supabase = {
  user: null,
  session: null,
  error: null,
  loading: false,
};

export default function alertReducer(
  state = initialState,
  action: {
    type: any;
    payload: { data: { session: any; user: any }; error: any };
  }
) {
  const { type, payload } = action;
  const { data, error } = payload || {};

  switch (type) {
    case REFRESH_SESSION:
      return {
        user: data.user,
        session: data.session,
        error: error,
      };
    case SIGN_UP:
      return {
        ...state,
        // session: data.session,
        // user: data.user,
        error: error,
      };
    case LOGIN:
      return {
        ...state,
        user: data.user,
        session: data.session,
        error: error,
      };
    case LOGOUT:
      return {
        user: null,
        session: null,
        error: null,
      };
    case GET_SESSION:
      return {
        ...state,
        session: data.session,
      };
    case VERIFY_OTP:
      return {
        ...state,
        session: data.session,
        error: error,
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        user: data.user,
        error: error,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        session: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
