import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Add this import

type Props = {
  loading: boolean;
  children: any;
  size?: string;
};

const OverlayLoadingHOC = ({ loading, size, children }: Props) => {
  const { t } = useTranslation('A11y');

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          background: 'rgb(0 0 0 / 6%)',
        }}
        open={loading}
      >
        <CircularProgress aria-label={t('loading')} />
      </Backdrop>
      {children}
    </>
  );
};

export default OverlayLoadingHOC;
