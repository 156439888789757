import { Grid, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
type Props = {
  message: string;
};

const GlossaryItemAlert = ({ message }: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'space-between'}
      sx={{
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    >
      <Grid
        item
        xs={2}
        sx={{
          background: '#C7CCCF',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <InfoIcon
          fontSize="large"
          sx={{ height: '100%', color: '#374D5D' }}
        />
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          padding: '7px',
        }}
      >
        <Typography sx={{ whiteSpace: 'pre-line' }}>{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default GlossaryItemAlert;
