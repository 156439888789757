import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import terms_conditions_string from './TermsConditions.html';
import styles from './TermsConditions.module.css';

const TermsConditions = () => {
  return (
    <>
      <Container style={{ textAlign: 'left', paddingTop: '1em' }}>
        <Row>
          <Col>
            <div
              className={styles['terms-conditions']}
              dangerouslySetInnerHTML={{
                __html: terms_conditions_string,
              }}
            />

            <p>
              <Link to="/dashboard">Zurück</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TermsConditions;
