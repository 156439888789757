import http from '../config/httpComman';

export default class untranslatedWordService {
  static async getUntranslatedWord() {
    return await http.get('job/static-translation-words/');
  }

  static async searchUntranslatedWord(data: { search_string: string }) {
    return await http.post('job/static-translation-words/search/', data);
  }

  static async deleteUntranslatedWord(id: number) {
    return await http.delete(`job/static-translation-words/${id}/`);
  }

  static async addUntranslatedWord({
    word,
    is_owner,
  }: {
    word: string;
    is_owner: string;
  }) {
    return await http.post('job/static-translation-words/', {
      word,
      is_owner,
    });
  }
}
