import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import privacy_string from './Dataprivacy.html.js';

const Dataprivacy = () => {
  return (
    <>
      <Container style={{ textAlign: 'left', paddingTop: '1em' }}>
        <Row>
          <Col>
            {/* render html page */}
            <div
              dangerouslySetInnerHTML={{
                __html: privacy_string,
              }}
            />
            <p>
              <Link
                to="/dashboard"
                className="linkStyle"
              >
                Zurück
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dataprivacy;
