import { createSelector } from 'reselect';
import { summaryOutput } from 'src/types/summary.types';

export interface SummaryReducer {
  loading?: boolean;
  summary: summaryOutput | null;
}
interface RootState {
  summary: SummaryReducer;
}

// This used to memorize the selector value
export const SummarySelector = createSelector(
  (state: RootState) => state.summary,
  (config: SummaryReducer) => {
    return {
      loading: config.loading,
      summary: config.summary,
    };
  }
);
