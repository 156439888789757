import { Typography, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyOtp } from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import OTPInput from './OTPInput';

type Props = {
  style?: object;
};

const ConfirmOTP = ({ style = {} }: Props) => {
  const distpatch: any = useDispatch();
  const history = useNavigate();
  const [otpValue, setOtpValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');

  const { t } = useTranslation('account');

  const handleChange = (e: string) => {
    setOtpValue(e);
  };

  const loading = useSelector(
    (state: any) => supabaseSelector(state).loading,
    shallowEqual
  );

  const handleConfirm = async () => {
    let emailVal = sessionStorage.getItem('email') || '';
    const resData = await distpatch(
      verifyOtp({ email: emailVal.toLocaleLowerCase(), otp: otpValue })
    );
    if (resData) {
      let type = sessionStorage.getItem('type');
      if (type && type === 'new_password') {
        // Redirect to the page
        return history('/reset-password');
      }
      if (resData.data.user) {
        if (localStorage.getItem('IN_PURCHASE_FLOW') === 'true') {
          history('/checkout');
        } else {
          return history('/signup');
        }
      } else if (!emailVal) {
        // if user try to go the otp page without having otp
        return history('/');
      } else {
        // set the email value
        setEmailValue(emailVal ?? '');
      }
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        marginTop: '5em',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...style,
      }}
      spacing={1.5}
    >
      <Grid
        item
        container
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          <MarkEmailUnreadIcon sx={{ fontSize: '7em', color: '#384d5d' }} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: '#374D5D', fontWeight: 'bold', mt: '10px' }}
          >
            {t('otpPage.title')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: '#374D5D' }}
          >
            {t('otpPage.subtitle', {
              email: sessionStorage.getItem('email') || '',
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        xs={4}
      >
        <Grid
          item
          xs={12}
        >
          <OTPInput
            otp={otpValue}
            handleChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <LoadingButton
            loading={loading}
            variant="contained"
            disabled={isEmpty(otpValue) || otpValue.length !== 6}
            onClick={handleConfirm}
            sx={{ textTransform: 'capitalize', p: '8px 3em' }}
            id="otp-submit"
          >
            {t('otpPage.submit')}
          </LoadingButton>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{ color: '#374D5D', mb: 0 }}
          >
            {t('otpPage.didntRecieve')}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ color: '#374D5D', fontWeight: 'bold' }}
          >
            {t('otpPage.checkSpam')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmOTP;
