import React from 'react';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import ImageListItemView from '../../coreUI/ImageListItemView/ImageListItemView';
import { ImageUploadItem, LangWords } from './image.types';

type Props = {
  imageItem: ImageUploadItem;
  downloadUploadedImage: (id: string) => void;
  feedbackImageUpload(
    id: string,
    feedback: 0 | 1 | 2,
    imageId: string | number,
    isOwner: boolean
  ): void;
  handleInsertImage?: (url: string) => void;
  deleteUploadedImage: (id: string, imageId: number, isOwner: boolean) => void;
  selectedOutputNode: number;
  langWords: LangWords;
  deletedImgId: string | null;
};

const ImageListComp = ({
  imageItem,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage,
  handleInsertImage,
  selectedOutputNode,
  langWords,
  deletedImgId,
}: Props) => {
  const theme = useTheme();

  const handleDownload = (id: string) => {
    downloadUploadedImage(id);
  };
  const handleFeedback = (id: string, feedback: 0 | 1 | 2) => {
    feedbackImageUpload(id, feedback, imageItem.id, imageItem.is_owner);
  };

  const handleDelete = (id: string) => {
    deleteUploadedImage(id, imageItem.id, imageItem.is_owner);
  };

  return (
    <Grid
      item
      container
      xs={12}
      mb={3}
    >
      <Grid
        item
        xs={12}
        mb={2}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          textAlign={'left'}
          color={theme.palette.primary.main}
          mb={0}
        >
          {imageItem.is_owner
            ? langWords.myGallary
            : imageItem.name === 'Bilder'
              ? `${langWords.bilder} ${
                  imageItem.owner_email ? `- ${imageItem.owner_email}` : ''
                }`
              : `${imageItem.name}${' '}${
                  imageItem.owner_email ? `- ${imageItem.owner_email}` : ''
                }`}
        </Typography>
        {imageItem.license && (
          <Typography
            variant="body2"
            gutterBottom
            textAlign={'left'}
            color={theme.palette.primary.main}
          >
            {langWords.licenseImage}
            <br />
            <Link
              href={imageItem.license?.link}
              color="inherit"
              target="_blank"
              sx={{ textWrap: 'wrap' }}
            >
              {imageItem.license?.name}
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          border: `2px solid ${theme.palette.general.lightBlue}`,
          padding: '8px',
          borderRadius: '.3em',
        }}
      >
        <ImageListItemView
          imageItem={imageItem}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFeedback={handleFeedback}
          handleInsertImage={selectedOutputNode ? handleInsertImage : undefined}
          disableInsertImage={selectedOutputNode ? false : true}
          deletedImgId={deletedImgId}
        />
      </Grid>
    </Grid>
  );
};

export default ImageListComp;
