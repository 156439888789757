import { createSelector } from 'reselect';

export interface untransaltedReducer {
  loading: boolean;
  loadingSearch: boolean;
  ownWordList: any;
  companyWordList: any;
}
export type PartialUntranslatedReducer = Partial<untransaltedReducer>;
interface RootState {
  untranslatedWords: untransaltedReducer;
}

// This used to memorize the selector value
export const UntranslatedWordsSelector = createSelector(
  (state: RootState) => state.untranslatedWords,
  (config: untransaltedReducer) => {
    return {
      loading: config.loading,
      ownWordList: config.ownWordList,
      companyWordList: config.companyWordList,
      loadingSearch: config.loadingSearch,
    };
  }
);
