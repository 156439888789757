// import React from 'react';
import SelectionPage from './SelectionPage';
import Cookies from 'universal-cookie';

const ShopPage = () => {
  const cookies = new Cookies();

  return (
    <>
      <SelectionPage cookies={cookies} />
    </>
  );
};

export default ShopPage;
