import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
// import SearchInputComp from 'src/coreUI/searchComp/SearchInputComp';
import ItemView from './ItemView';
import DeleteIcon from '@mui/icons-material/Delete';
import { menuItem } from './fixedData';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createunTranslatedWord,
  deleteWord,
  // emptyStateValueuntranslatedWord,
  searchWordList,
} from 'src/redux/untranslatedWord/actions';
import { UntranslatedWordsSelector } from 'src/redux/untranslatedWord/selector';
import { isEmpty } from 'lodash';
// import TextareaComp from 'src/coreUI/TextAreaComp/TextareaComp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

type Props = {};

const UntranslatedDictionaryWrapper = (props: Props) => {
  const { t } = useTranslation(['common', 'easy_language', 'static_words'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const [inputVal, setInputVal] = useState<{ word: string; is_owner: string }>({
    word: '',
    is_owner: 'True',
  });
  const [expandAcc, setExpandAcc] = useState<boolean>(false);

  // const searchLoading = useSelector(
  //   (state: any) => UntranslatedWordsSelector(state).loadingSearch,
  //   shallowEqual
  // );

  const loading = useSelector(
    (state: any) => UntranslatedWordsSelector(state).loading,
    shallowEqual
  );

  const ownWordList = useSelector(
    (state: any) => UntranslatedWordsSelector(state).ownWordList,
    shallowEqual
  );

  const companyWordList = useSelector(
    (state: any) => UntranslatedWordsSelector(state).companyWordList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(searchWordList({ search_string: '' }));
  }, [dispatch]);

  const handleChange: () => void = () => {
    setExpandAcc(!expandAcc);
  };

  // const handleSearch = (value: string) => {
  //   dispatch(searchWordList({ search_string: value }));
  // };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    setInputVal({
      ...inputVal,
      [e?.target.name]: e?.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(createunTranslatedWord(inputVal));
  };

  const handleDelete = useCallback(
    (data: { id: number; is_owner: string; word: string }) => {
      dispatch(deleteWord({ id: data.id, is_owner: data.is_owner }));
    },
    [dispatch]
  );
  const renderList = useCallback(
    (wordList: { id: number; word: string; is_owner: string }[], title) => (
      <>
        <Grid
          item
          xs={12}
          mt={'1em'}
          sx={{
            maxHeight: 400,
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: 'left',
              fontSize: '1rem',
              color: theme.palette.primary.main,
            }}
          >
            {t(title)}
          </Typography>
        </Grid>

        {wordList && Object.values(wordList).length > 0 ? (
          <>
            {Object.values(wordList).map(
              (data: { id: number; word: string; is_owner: string }) => (
                <Grid
                  item
                  xs={12}
                  key={data.id}
                >
                  <ItemView
                    accordionSummary={data.word}
                    expandIcon={
                      <IconButton
                        onClick={() => handleDelete(data)}
                        aria-label={t('delete_word')}
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    }
                  />
                </Grid>
              )
            )}
          </>
        ) : (
          <Grid
            item
            xs={12}
            mt={'1em'}
            sx={{
              border: `1px solid ${theme.palette.general.pattensBlue}`,
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'left',
              paddingLeft: '19px',
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: theme.palette.primary.main }}
            >
              {t('no_item')}
            </Typography>
          </Grid>
        )}
      </>
    ),
    [
      handleDelete,
      t,
      theme.palette.general.pattensBlue,
      theme.palette.primary.main,
    ]
  );
  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      sx={{}}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          sx={{
            hyphens: 'auto',
            textAlign: 'left',
            whiteSpace: 'normal',
            marginBottom: '10px',
          }}
        >
          {t('word_remain')}
          <br />
          <strong>{t('note')}</strong>
        </Typography>
        {/* <SearchInputComp
          handleSubmit={handleSearch}
          loading={searchLoading}
          placeholder={t('search')}
        /> */}
      </Grid>
      <Grid
        item
        xs={12}
      >
        <div>
          <Accordion
            expanded={expandAcc}
            onChange={handleChange}
            // disabled={!props.userDetails?.premiumuser}
            disableGutters
            sx={{
              boxShadow: 'none',

              borderRadius: '4px',
              // height: '40px !important',
              '&::before': {
                // position: 'unset',
              },
              '& .MuiAccordionSummary-content': {
                margin: '0 !important',
              },
            }}
          >
            {/* <Accordion className="text-left mt-3 mb-4"> */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{
                backgroundColor: '#DCE2E6',
                // height: '40px !important',
                minHeight: '40px !important',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ paddingTop: 0, paddingRight: '10px' }}
              >
                <ControlPointIcon fontSize="small" />
              </Grid>
              <Grid
                item
                xs="auto"
              >
                <Typography variant="subtitle2">
                  {t('add_word_to_list')}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingTop: '16px',
                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
                borderLeft: `1px solid rgba(0, 0, 0, 0.12)`,
                background: '#fff',
                // zIndex: 2,
                // position: 'absolute',
              }}
            >
              <Grid
                item
                container
                xs={12}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <InputComponent
                    value={inputVal.word}
                    label={t('insert_word')}
                    style={{ width: '100%' }}
                    name="word"
                    handleChange={handleInputChange}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      id="select-Add-to"
                      size="small"
                    >
                      {t('add_to')}
                    </InputLabel>
                    <Select
                      labelId="select-Add-to"
                      id="Add-to"
                      value={inputVal.is_owner}
                      label={t('add_to')}
                      onChange={handleInputChange}
                      size="small"
                      name="is_owner"
                      sx={{ textAlign: 'left' }}
                    >
                      {menuItem(t).map((item) => (
                        <MenuItem
                          value={item.value}
                          key={item.id}
                          sx={{ paddingLeft: 0 }}
                        >
                          <Tooltip
                            title={item.tooltip}
                            placement="right"
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ width: '100%', paddingLeft: '15px' }}
                            >
                              {item.name}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  textAlign={'center'}
                >
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    disabled={
                      isEmpty(inputVal.word) || isEmpty(inputVal.is_owner)
                    }
                    onClick={handleSubmit}
                    sx={{ textTransform: 'capitalize', p: '8px 3em' }}
                    id="otp-submit"
                    fullWidth
                  >
                    {t('submit')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={12}
      >
        {renderList(ownWordList, 'my_word_list')}
        {renderList(companyWordList, 'company_word_list')}
        {/* {ownWordList ||
          (companyWordList &&
            Object.values({ ...ownWordList, ...companyWordList }).length ===
              0 && (
              <Grid
                item
                xs={12}
                mt={'2em'}
              >
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t('no_item')}
                </Typography>
              </Grid>
            ))} */}
      </Grid>
    </Grid>
  );
};

export default UntranslatedDictionaryWrapper;
