export const copyToClipboard = async (textForClipboard) => {
  const result = await navigator.permissions.query({
    name: 'clipboard-write',
  });
  if (result.state === 'granted') {
    return await navigator.clipboard.writeText(textForClipboard);
  }
};

export const clearLocalStorage = () => {
  function clearAllCaches() {
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => {
            return caches.delete(cacheName);
          })
        );
      });
    }
  }
  function clearApplicationData() {
    localStorage.clear();
  }
  clearAllCaches();
  clearApplicationData();
  window.location.reload();
};
