import { Container, Grid } from '@mui/material';
import React from 'react';
import Category from './categoryComp/Category';
import Tags from './Tags';
import UserFeedback from './UserFeedback';
// import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  paramsVal: any;
  onDeleteEntry: (row: any, alertTrigger: boolean) => void;
  selectedRow: number;
};

const ActionWrapper = ({ paramsVal, onDeleteEntry, selectedRow }: Props) => {
  return (
    <Container
      sx={{
        ...(selectedRow !== paramsVal.id
          ? {
              height: '46% !important',
              overflow: 'hidden',
            }
          : {}),
      }}
    >
      {/* <Grid
        item
        container
        xs={12}
      > */}
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          pt={'22px !important'}
        >
          <Category params={paramsVal} />
        </Grid>
        <Grid
          item
          xs={12}
          pt={'22px'}
        >
          <Tags paramsVal={paramsVal} />
        </Grid>
        <Grid
          item
          xs={12}
          pt={'22px'}
        >
          <UserFeedback params={paramsVal} />
        </Grid>
        {/* </Grid> */}
        {/* <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            height: 'fit-content',
            margin: 'auto',
          }}
        >
          <IconButton
            onClick={() => onDeleteEntry(paramsVal, true)}
            //   sx={{ height: 'fit-content', verticalAlign: 'middle' }}
          >
            <DeleteIcon
              fontSize="large"
              color="primary"
            />
          </IconButton>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default ActionWrapper;
