import { Navigate, Outlet } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState, supabaseSelector } from 'src/redux/supabase/selector';
// import { isEmpty } from 'lodash';
// import { getStorageRefreshToken } from 'src/redux/supabase/actions';

// TODO: I restored the old logic because the input textarea was not editable after reload
export default function PrivateRoute() {
  const supabaseUser = useSelector(
    (state: RootState) => supabaseSelector(state).user,
    shallowEqual
  );

  return supabaseUser ? <Outlet /> : <Navigate to="/" />;
  // return !isEmpty(getStorageRefreshToken()) ? <Outlet /> : <Navigate to="/" />;
}
