import { SelectChangeEvent } from '@mui/material';
import React from 'react';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { GlossaryEntriesSelector } from 'src/redux/glossaryEntries/selector';
import { shareGlossaryEntites } from 'src/redux/glossaryEntries/actions';
import { useTranslation } from 'react-i18next';
import { FeatureSelector } from 'src/redux/features/selector';
import { updateFeature } from 'src/redux/features/actions';

type Props = {};

const ShareGlossary = (props: Props) => {
  const { t } = useTranslation('glossary');
  const dispatch: any = useDispatch();
  const shareGlossary = useSelector(
    (state: any) => GlossaryEntriesSelector(state).shareGlossary,
    shallowEqual
  );
  const handleChange = (e: SelectChangeEvent<any>) => {
    dispatch(shareGlossaryEntites({ access: e?.target.value }));
  };
  const featuresList = useSelector(
    (state: any) => FeatureSelector(state).featuresList,
    shallowEqual
  );

  return (
    <MaterialSelectComp
      badge={featuresList ? featuresList[7]?.seen : true}
      handleOnClick={() =>
        featuresList && !featuresList[7]?.seen
          ? dispatch(updateFeature(7))
          : undefined
      }
      menuItem={[
        {
          id: 's-g-1',
          value: 'private',
          text: t('private_glossary'),
          desc: t('private_desc'),
        },
        {
          id: 's-g-1',
          value: 'company',
          text: t('company_glossary'),
          desc: t('company_des'),
        },
      ]}
      handleChange={handleChange}
      value={shareGlossary}
      label={t('share_glossary')}
      disabled={false}
      formStyle={{
        width: '6em',
        height: '2em',
        fontSize: '0.8rem',
        borderRadius: '2px',
        mt: 0,
      }}
      name={t('share_glossary')}
      require={false}
      error={false}
      style={{
        textAlign: 'left',
        minWidth: 120,
      }}
    />
  );
};

export default ShareGlossary;
