import styles from './RequestReviewPopup.module.css';
import RequestReviewForm from '../Forms/RequestReviewForm';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const RequestReviewPopup = (props) => {
  return (
    <Modal
      open={props.show}
      onClose={props.close}
      closeAfterTransition
    >
      <Fade in={props.show}>
        <Box
          className={styles['modal-style']}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000,
          }}
        >
          <IconButton
            aria-label="Schließen" //TODO: add translations
            onClick={props.close}
            className={styles['close-button']}
            style={{ position: 'absolute', right: '16px', top: '16px' }}
          >
            <CloseIcon />
          </IconButton>
          <div className={styles['modal-body-div']}>
            <RequestReviewForm />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RequestReviewPopup;
