import GetAppIcon from '@mui/icons-material/GetApp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Button, SvgIconTypeMap } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import React from 'react';

export const actionButton: (
  handleDownload?: (id: string) => void,
  item?: any,
  handleFeedback?: (id: string, feedback: 0 | 1 | 2) => void,
  handleInsertImage?: (id: string) => void
) => {
  action: any;
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}[] = (handleDownload, item, handleFeedback, handleInsertImage) => [
  ...(handleFeedback
    ? [
        ...(handleInsertImage
          ? [
              {
                action: () =>
                  handleInsertImage(
                    `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`
                  ),
                label: 'insert image',
                Icon: PlaylistAddIcon,
              },
            ]
          : []),
        {
          action: () => handleFeedback(item.uuid, 2),
          label: 'dislike',
          Icon: item.feedback !== 2 ? ThumbDownOffAltIcon : ThumbDownIcon,
        },
        {
          action: () => handleFeedback(item.uuid, 1),
          label: 'like',
          Icon: item.feedback !== 1 ? ThumbUpOffAltIcon : ThumbUpIcon,
        },
      ]
    : []),
  ...(handleDownload
    ? [
        {
          action: () => handleDownload(item.uuid),
          label: 'download',
          Icon: GetAppIcon,
        },
      ]
    : []),
];

export const menuListItem = (
  t: any,
  item: any,
  handleDelete?: (id: string) => void
) => {
  return {
    list: handleDelete
      ? [
          {
            id: 'image-upload-1',
            value: (
              <>
                <Button
                  sx={{
                    padding: '0',
                    textTransform: 'lowercase',
                    fontSize: '.9rem',
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                  onClick={() => handleDelete(item.uuid)}
                  startIcon={<DeleteIcon sx={{ fontSize: '16px' }} />}
                >
                  {t('delete')}
                </Button>
              </>
            ),
          },
        ]
      : [],
  };
};
