import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateTransHistory } from 'src/redux/translationHistory/actions';
import { feedbackList } from './fixedData';
import {
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  params: any;
};

const UserFeedback = ({ params }: Props) => {
  const [feedback, setFeedback] = useState<string>(
    params?.row.userfeedback ?? ''
  );
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const updateFeedback = async (feedback: string) => {
    dispatch(
      updateTransHistory({
        id: params.row.id,
        simpleTextUserEdited: params.row.simpletextuseredited,
        userFeedback: feedback,
        category: params.row.category,
        tags: params.row.tags ? params.row.tags : '',
      })
    );
  };
  useEffect(() => {
    setFeedback(params.row.userfeedback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: SelectChangeEvent<any>) => {
    updateFeedback(e?.target.value);
    setFeedback(e?.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id={'history.feedback.giveFeedback'}
        size={'small'}
      >
        {t('history.feedback.giveFeedback')}
      </InputLabel>
      <Select
        labelId={'history.feedback.giveFeedback'}
        id={'textType-' + params.row.id}
        value={feedback}
        label={t('history.feedback.giveFeedback')}
        onChange={handleChange}
        sx={{
          textAlign: 'left',
        }}
        size="small"
      >
        <MenuItem value="">{t('none')}</MenuItem>
        {feedbackList.map(({ value, name }) => (
          <MenuItem
            value={value}
            key={value}
          >
            {t(name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserFeedback;
