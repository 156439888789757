import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <div className="bg-light">
      <Navbar style={{ height: '5vh' }}>
        <Container fluid>
          <Nav>
            <Link
              to="/imprint"
              className="p-2 linkStyle"
            >
              {t('nav.imprint')}
            </Link>
            <Link
              to="/dataprivacy"
              className="p-2 linkStyle"
            >
              {t('nav.privacy')}
            </Link>
            <Link
              to="/licence"
              className="p-2 linkStyle"
            >
              {t('nav.termsofuse')}
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default Footer;
