import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import LoginWarning from './Homepage/LoginWarning';
import UpdateAppPopup from './Homepage/UpdateAppPopup';
import styles from './LoginForm.module.css';

const LoginForm = (props: any) => {
  const [passType, setPassType] = useState('password');
  const { t } = useTranslation('common');
  return (
    <>
      <UpdateAppPopup
        show={props.showUpdateAppPopup}
        onClick={props.clearLocalStorage}
      />
      <h4>{t('loginForm.loginHeadline')}</h4>
      {/* {renderLoginHint()} */}
      <Form
        className="mt-4"
        onSubmit={props.handleSubmit}
      >
        <Form.Group
          controlId="email"
          style={{ textAlign: 'left' }}
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            ref={props.emailRef}
            required
            id="email"
            data-testid="email"
          />
        </Form.Group>
        {
          <Form.Group
            className="mb-1"
            controlId="password"
            style={{ textAlign: 'left' }}
          >
            <Form.Label>{t('loginForm.password')}</Form.Label>
            <InputGroup>
              <Form.Control
                type={passType}
                ref={props.passwordRef}
                required
                id="password"
                data-testid="password"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  // type="button"
                  aria-label={
                    passType === 'password'
                      ? t('showPassword')
                      : t('hidePassword')
                  }
                  role="button"
                  onClick={() =>
                    passType === 'password'
                      ? setPassType('text')
                      : setPassType('password')
                  }
                  style={{ height: '100%' }}
                >
                  {passType === 'password' ? <EyeFill /> : <EyeSlashFill />}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <Link
              className={`${styles['forgot-password']} linkStyle`}
              to="/reset-password "
            >
              {t('loginForm.forgotPassword')}
            </Link>
          </Form.Group>
        }
        <Button
          variant="primary"
          type="submit"
        >
          {t('loginForm.loginButton')}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
