import glossaryService from 'src/services/glossary.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  DELETE_GLOSSARY_ENTRIES,
  GET_GLOSSARY_ENTRIES,
  GET_SHARE_GLOSSARY_VALUE,
  LOADING,
  SET_FILTER_VALUE,
  STOP_LOADING,
  TAGS_UPDATE,
  UPDATE_CURRENT_PAGE,
  UPDATE_GLOSSARY_ENTRIES,
  UPDATE_SHARE_GLOSSARY_VALUE,
} from './types';
import {
  GlossaryEntrie,
  getGlossaryEntries,
} from 'src/types/glossaryTypes.types';
import Sentry from 'src/config/sentryConfig';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { FilterValue } from 'src/types/transHistory.types';
import { UPDATE_GLOSSARY_ITEM } from '../glossary/types';

export const getGlossaryEtnries =
  (data: getGlossaryEntries, loadingFetch: boolean = true) =>
  async (dispatch: any) => {
    try {
      if (loadingFetch) {
        dispatch(loading());
      }
      const { actualValue, operation, field, value, ...sendData } = data;
      const dataUpdate = { operation, field, value, ...sendData };
      // If the any of filter value empty remove all
      if (isEmpty(operation) || isEmpty(field) || isEmpty(value)) {
        delete dataUpdate['operation'];
        delete dataUpdate['field'];
        delete dataUpdate['value'];
      }

      const res: AxiosResponse<object> =
        await glossaryService.getAllGlossaryEntries(dataUpdate);
      dispatch({
        type: GET_GLOSSARY_ENTRIES,
        payload: {
          ...res.data,
          currentPage: data.page,
          entries: data.page_size,
        },
      });

      dispatch(updateCurrentPage(data.page));
    } catch (e: any) {
      return appMessageHandling(
        dispatch,
        'error.get_glossary_entries',
        'error'
      );
    } finally {
      if (loadingFetch) {
        dispatch(stopLoading());
      }
    }
  };

export const updateGlossaryEntry =
  (data: GlossaryEntrie) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await glossaryService.updateGlossaryEntry(data);
      appMessageHandling(
        dispatch,
        'success.update_glossary_entry_success',
        'success'
      );

      dispatch({
        type: UPDATE_GLOSSARY_ITEM,
        payload: res.data,
      });

      return dispatch({
        type: UPDATE_GLOSSARY_ENTRIES,
        payload: res.data,
      });
    } catch (e: any) {
      appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? 'error.update_glossary_entry_error',
        'error'
      );
      Sentry.captureMessage(`Update glossary entry Error: ${e}`, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteGlossaryEntry =
  (id: number | string) => async (dispatch: any) => {
    try {
      await glossaryService.deleteGlossaryEntry(id);
      appMessageHandling(
        dispatch,
        'success.delete_glossary_entry_success',
        'success'
      );
      return dispatch({
        type: DELETE_GLOSSARY_ENTRIES,
        payload: id,
      });
    } catch (e: any) {
      appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? 'error.delete_glossary_entry_error',
        'error'
      );
      Sentry.captureMessage(`Delete glossary entry Error: ${e}`, 'error');
    }
  };

export const shareGlossaryEntites =
  (data: { access: 'private' | 'company' }) => async (dispatch: any) => {
    try {
      await glossaryService.shareGlossaryEntitesApi(data);
      appMessageHandling(
        dispatch,
        'success.share_glossary_entry_success',
        'success'
      );
      return dispatch({
        type: UPDATE_SHARE_GLOSSARY_VALUE,
        payload: data,
      });
    } catch (e: any) {
      appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? 'error.share_glossary_entry_error',
        'error'
      );
      Sentry.captureMessage(`Share glossary entry Error: ${e}`, 'error');
    }
  };

export const getShareGlossaryEntites = () => async (dispatch: any) => {
  try {
    let res = await glossaryService.getShareGlossaryValueApi();
    return dispatch({
      type: GET_SHARE_GLOSSARY_VALUE,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get Share glossary entry Error: ${e}`, 'error');
  }
};

export const glossaryTagsUpdate = (data: any) => (dispatch: any) => {
  return dispatch({
    type: TAGS_UPDATE,
    payload: data,
  });
};

export const fIlterValueSetGlossaryEntry =
  (data: FilterValue) => (dispatch: any) => {
    dispatch({
      type: SET_FILTER_VALUE,
      payload: data,
    });
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const updateCurrentPage = (page: number | string) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_CURRENT_PAGE,
    payload: page,
  });
};
