import { createSelector } from 'reselect';
import { FilterValue } from 'src/types/transHistory.types';

export interface HistoryReducer {
  loading: boolean;
  jobsList: object;
  tags: { [key: string]: string[] };
  count: number;
  next: null | number;
  previous: null | number;
  currentPage: number;
  entries: number;
  filters: null | FilterValue;
  historyJob: null | {
    originaltext: string;
    simpletext: string;
    simpletextuseredited: string;
    id: number;
    category: null | string;
    tags: null | string;
  };
}
interface RootState {
  transHistory: HistoryReducer;
}

// This used to memorize the selector value
export const HistorySelector = createSelector(
  (state: RootState) => state.transHistory,
  (histroy: HistoryReducer) => {
    return {
      loading: histroy.loading,
      jobsList: histroy.jobsList,
      tags: histroy.tags,
      count: histroy.count,
      next: histroy.next,
      previous: histroy.previous,
      currentPage: histroy.currentPage,
      entries: histroy.entries,
      filters: histroy.filters,
      historyJob: histroy.historyJob,
    };
  }
);
