import { SummaryInput } from 'src/types/summary.types';
import http from '../config/httpComman';

export default class summaryService {
  static async createSummaryApi(data: SummaryInput) {
    return await http.post('translate/summarize/', data);
  }
  static async updateSummaryApi(data: { id: number; feedback: number }) {
    return await http.put('translate/summarize/', data);
  }
}
