import { useTheme } from '@mui/material';
import OtpInput from 'react-otp-input';
type Props = {
  otp: string;
  handleChange: (e: string) => void;
};

const OTPInput = ({ otp, handleChange }: Props) => {
  const theme = useTheme();
  return (
    <OtpInput
      value={otp}
      onChange={handleChange}
      numInputs={6}
      // renderSeparator={<span> </span>}
      inputType="text"
      renderInput={(props) => (
        <input
          id="otp-input"
          {...props}
          disabled={false}
          style={{
            height: '3em',
            textAlign: 'center',
            marginRight: '10px',
            ...props.style,
            width: '3em',
            background: theme.palette.secondary.main,
            border: 'none',
            outline: 'none',
            borderRadius: '3px',
          }}
        />
      )}
      containerStyle={{ width: '100%', justifyContent: 'center' }}
      inputStyle={{ width: '100%', marginRight: '10px' }}
    />
  );
};

export default OTPInput;
