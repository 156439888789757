import { triggerAlert } from '../redux/appSetting/actions';
import localize from './localize';
import { VariantType } from 'notistack';

export const appMessageHandling = (
  dispatch: any,
  key: string,
  variant: VariantType,
  options?: object,
  alertOption?: object
) => {
  return dispatch(triggerAlert(localize(key, options), variant, alertOption));
};
