import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserSelector } from '../redux/user/selector';
import { Permission } from '../enums/permission';

type Props = {
  allowedPermission: Permission[];
  children: any;
};

const PermissionsCheck = ({ allowedPermission, children }: Props) => {
  const [allow, setAllow] = useState<boolean>(true);
  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const checkExsit = useCallback(() => {
    if (userDetails) {
      allowedPermission?.forEach((per: Permission) => {
        if (!userDetails?.permissions?.includes(per)) {
          return setAllow(false);
        }
      });
    }
  }, [allowedPermission, userDetails]);

  useEffect(() => {
    checkExsit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return allow ? children : null;
};

export default PermissionsCheck;
