import { Button, Col, Container, Row } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import customerStatementImg from '../../assets/customer_statement.png';
import glossaryFeatureImg from '../../assets/glossary-feature.png';
import glossaryNewEntryEllipse from '../../assets/glossary-new-entry-ellipse.png';
import productTranslationHeaderImg from '../../assets/product-translation-header.png';
import synonymsFeatureImg from '../../assets/synonyms-feature.png';
import translationFeatureImg from '../../assets/translation-feature.png';

import HelpButton from './HelpButton';
import styles from './ProductInformationPage.module.css';

const ProductInformationPage = () => {
  const history = useNavigate();

  return (
    <>
      <Container
        fluid
        className="mb-5"
      >
        <Row className="mt-4">
          <Col>
            <h1>
              Leichte Sprache. Leicht gemacht.
              <br />
              Mit SUMM in Microsoft Word.
            </h1>
          </Col>
        </Row>
        <Row className="mt-5 d-flex align-items-center mb-5">
          <Col md={6}>
            <img
              src={productTranslationHeaderImg}
              alt="product-translation-header"
              className={styles['product-header-img']}
            />
          </Col>
          <Col
            lg={5}
            md={6}
            className="text-left"
          >
            <div className="align-self-center">
              <p>
                Wie funktioniert das eigentlich genau?{' '}
                <span className="font-weight-bold">
                  Mit dem SUMM Leichte Sprache Tool für Übersetzer:innen - der
                  passgenauen Unterstützung im Übersetzungsprozess.
                </span>
              </p>
              <p>
                SUMM ist mit Übersetzer:innen gemeinsam entwickelt worden, damit
                das Schreiben in Leichter Sprache flüssig von der Hand geht und
                Texte in hoher Qualität entstehen können. Wir von SUMM möchten,
                dass es mehr Texte in Leichter Sprache gibt und echte (digitale)
                Teilhabe stattfinden kann. Wenn wir Ihnen beim Übersetzen
                helfen, klappt das (fast) von ganz allein.
              </p>
              <p>
                Gut zu Wissen: Wir orientieren uns aktuell an den
                Netzwerk-Regeln - aber vor allem auch an Ihnen und Ihrem
                Feedback! Damit SUMM besser werden kann, und richtig auf die
                Bedürfnisse der Zielgruppe abgestimmt ist. Das wissen Sie als
                Übersetzer:in am besten, und deshalb freuen wir uns über Ihr
                Feedback.
              </p>
              <p className="font-weight-bold">
                Der “Clou” beim Feedback Geben: Mit jedem Feedback schalten Sie
                verbrauchte Zeichen für KI-Übersetzungsvorschläge wieder frei.
              </p>
              <p>
                Das heißt also, dass Sie nicht nur die KI-Funktion mit Ihrem
                Feedback besser an Ihre Bedürfnisse anpassen - Sie profitieren
                auch mit einer Zeichen-Gutschrift. Damit können Sie noch mehr
                KI-Übersetzungsvorschläge generieren als in Ihrem Paket zu
                Anfang voreingestellt ist.
              </p>
              <p>
                Lernen Sie weiter unten unsere Funktionen kennen oder klicken
                Sie auf “SUMM bestellen!”, um mehr über die verschiedenen Pakete
                zu erfahren, die Ihnen zur Wahl stehen!
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-4 mb-5">
            <Button
              className={
                styles['purchase-button'] + ' d-flex align-items-center p-4'
              }
              onClick={() => history('/summ-products')}
            >
              <ChevronRight />
              SUMM bestellen!
            </Button>
          </Col>
        </Row>
        <hr />
        <Row
          className={
            styles['section-2'] + ' text-left d-flex align-items-center'
          }
        >
          <Col md={{ span: 4 }}>
            <div className="text-center">
              <img
                src={translationFeatureImg}
                alt="Assistentenfunktion"
                className={styles['product-header-img']}
              />
              <span className="text-secondary">Assistentenfunktion</span>
            </div>

            <div className="text-center">
              <img
                src={synonymsFeatureImg}
                alt="Synonyme Funktion"
                className={styles['product-header-img'] + ' mt-5'}
              />
              <span className="text-secondary">Synonym-Suche</span>
            </div>
          </Col>
          <Col md={3}>
            <h4 className="font-weight-bold">
              Aber was kann SUMM denn nun wirklich?
            </h4>
            <p>
              Unser Tool überzeugt mit{' '}
              <span className="font-weight-bold">drei Kernfunktionen</span>, die
              in der Übersetzungs-Praxis einen entscheidenden Unterschied
              machen:
            </p>
            <p>
              <span className="font-weight-bold">1. Assistentenfunktion</span>{' '}
              für KI-basierte, automatisch generierte Übersetzungsvorschläge in
              Leichter Sprache - für ein flüssiges Arbeiten auf Basis von
              Textvorschlägen, die unter Berücksichtigung der Netzwerkregeln
              generiert werden. Das macht den Übersetzungsprozess deutlich
              schneller und hilft elegant über mühsame oder besonders schwierige
              Textpassagen hinweg.
            </p>
            <p>
              <span className="font-weight-bold">2. Glossarfunktion</span> für
              die Suche in einer umfangreichen Sammlung aus bestehenden und
              eigenen Konzepterklärungen in Leichter Sprache. Das Besondere: Sie
              können eigene Konzepterklärungen bequem anlegen, durchsuchen und
              wiederverwenden. So kann Ihre Sammlung an bewährten Erklärungen
              stetig wachsen und das mühsame, händische Nachsehen in alten
              Dokumenten oder Excel-Tabellen entfällt.
            </p>
            <p>
              <span className="font-weight-bold">3. Synonym-Suche</span> auf
              Basis von OpenThesaurus, bequem direkt in Microsoft Word. Das
              Beste daran? Die vorgeschlagenen Synonyme sind nach Sprachniveau
              (A1, A2, B1) auf Basis des Gemeinsamen Europäischen
              Referenzrahmens (GER) kategorisiert.
            </p>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <div className={styles['glossary-feature'] + ' text-center'}>
                  <img
                    src={glossaryFeatureImg}
                    alt="Glossarfunktion"
                    className={styles['product-header-img']}
                  />
                  <img
                    src={glossaryNewEntryEllipse}
                    alt="Feld, um neuen Glossareintrag anzulegen"
                    className={styles['glossary-new-entry']}
                  />
                  <span className="text-secondary">Glossarfunktion</span>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="text-center">
                <img
                  className={styles['customer-statement-img']}
                  alt="Positives Statement eines Nutzers von SUMM"
                  src={customerStatementImg}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-right"></Col>
        </Row>
        <Row>
          <Col
            md={{ span: 11 }}
            className="d-flex flex-row-reverse align-items-center"
          >
            <Button
              className={
                styles['purchase-button'] + ' d-flex align-items-center'
              }
              onClick={() => history('/summ-products')}
            >
              <ChevronRight />
              SUMM bestellen!
            </Button>
          </Col>
          <Col className="text-left">
            <HelpButton />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductInformationPage;
