import { InsertToString } from 'src/utils/insertToString';
import http from '../config/httpComman';

export default class imageUploadService {
  static async fetchGalleryApi(
    params: { [key: string]: string | number } = { fetch_mode: 'all' }
  ) {
    let url = InsertToString('images/', params);
    return await http.get(url);
  }

  static async uploadImageApi(data: FormData) {
    http.defaults.headers['Content-Type'] = 'multipart/form-data';

    return await http.post('images/', data);
  }

  static async searchUploadedImageApi(
    value: string,
    extract_keywords: boolean = false
  ) {
    return await http.get(
      `images/search/?text=${value}&similarity=0.07&extract_keywords=${extract_keywords}`
    );
  }

  static async deleteUploadedImageApi(id: string) {
    return await http.delete(`images/${id}/`);
  }

  static async feedbackImageApi(id: string, feedback: 0 | 1 | 2) {
    // 0 => undefined
    // 1 => negative
    // 2 => positive
    return await http.post(`images/feedback/`, {
      uuid: id,
      feedback,
    });
  }

  static async downloadImageApi(id: string) {
    return await http.get(`images/download/${id}`);
  }
}
