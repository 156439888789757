export const GET_GLOSSARY_ENTRIES = 'GET_GLOSSARY_ENTRIES';
export const UPDATE_GLOSSARY_ENTRIES = 'UPDATE_GLOSSARY_ENTRIES';
export const DELETE_GLOSSARY_ENTRIES = 'DELETE_GLOSSARY_ENTRIES';
export const TAGS_UPDATE = 'TAGS_UPDATE';
export const LOADING = 'LOADING_GLOSS_ENTRIES';
export const STOP_LOADING = 'STOP_LOADING_GLOSS_ENTRIES';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE_GLOSS_ENTRIES';
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE_GLOSSARY_ENTRIES';
export const UPDATE_SHARE_GLOSSARY_VALUE = 'UPDATE_SHARE_GLOSSARY_VALUE';
export const GET_SHARE_GLOSSARY_VALUE = 'GET_SHARE_GLOSSARY_VALUE';
