import { ListItem, Avatar, ListItemText } from '@mui/material';
import React from 'react';
import CopyButtomComp from '../../coreUI/copyButtonComp/CopyButtomComp';
import { SynonymsListEntryProps } from './synonyms.types';
import { useContext } from 'react';
import { SynonymsFieldContext } from './SynonymsField';

const SynonymsListEntry = ({ synonym }: SynonymsListEntryProps) => {
  const { copyText, copiedText }: any = useContext(SynonymsFieldContext);
  const GetBadge = (level: string | null) => {
    const colorDict: { [key: string]: string } = {
      A1: '#87a3b8',
      A2: '#6c8ea8',
      B1: '#577993',
      B2: '#476378',
      C1: '#374d5d',
      C2: '#283743',
    };

    const textColorDict: { [key: string]: string } = {
      A1: 'white',
      A2: 'white',
      B1: 'white',
      B2: 'white',
      C1: 'white',
      C2: 'white',
    };
    return (
      <Avatar
        sx={{
          backgroundColor: level && colorDict[level],
          color: level && textColorDict[level],
          width: '24.5px',
          borderRadius: '4px',
          height: '21px',
          fontSize: '.8rem',
          marginRight: '4px',
          paddingLeft: '2px',
          visibility: `${level}` !== 'null' ? 'visible' : 'hidden',
        }}
      >
        {level}
      </Avatar>
    );
  };

  return (
    <ListItem
      secondaryAction={
        <CopyButtomComp
          value={synonym.term}
          buttonStyle={{ position: 'relative', paddingLeft: '11px' }}
          placement="left"
          copyText={copyText}
          copiedText={copiedText}
        />
      }
      sx={{
        borderLeft: `1px solid #ced4da`,
        borderRight: `1px solid #ced4da`,
        borderTop: `1px solid #ced4da`,
        width: '100%',
        paddingRight: 0,
        ':last-child': {
          borderBottom: `1px solid #ced4da`,
        },
        '& .MuiListItemSecondaryAction-root': {
          right: '2px',
        },
      }}
    >
      {GetBadge(synonym.language_level)}
      <ListItemText
        sx={{
          fontSize: '.8rem',
          paddingLeft: '6px',
          '& .MuiListItemText-primary': {
            maxWidth: '84%',
            wordBreak: 'break-all',
            textWrap: 'pretty',
          },
        }}
        primary={synonym.term}
      />
    </ListItem>
  );
};

export default SynonymsListEntry;
