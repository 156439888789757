import { createSelector } from 'reselect';
import { FilterValue } from 'src/types/transHistory.types';

export interface GlossaryEntriesReducer {
  loading: boolean;
  glossaryEntriesList: readonly any[];
  tags: { [key: string]: string[] };
  count: number;
  next: null | number;
  previous: null | number;
  currentPage: number;
  entries: number;
  filters: null | FilterValue;
  shareGlossary: 'private' | 'company';
}
interface RootState {
  glossaryEntries: GlossaryEntriesReducer;
}

// This used to memorize the selector value
export const GlossaryEntriesSelector = createSelector(
  (state: RootState) => state.glossaryEntries,
  (config: GlossaryEntriesReducer) => {
    return {
      loading: config.loading,
      glossaryEntriesList: config.glossaryEntriesList,
      tags: config.tags,
      count: config.count,
      next: config.next,
      previous: config.previous,
      currentPage: config.currentPage,
      entries: config.entries,
      filters: config.filters,
      shareGlossary: config.shareGlossary,
    };
  }
);
