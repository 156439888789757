import * as React from 'react';
import { InfoSquare } from 'react-bootstrap-icons';
import styles from './InfoComponent.module.css';

const InfoComponent = (props) => {
  const headerStyling = props.headerStyle ? props.headerStyle : {};
  const iconStyling = props.iconStyle ? props.iconStyle : {};

  return (
    <div className={styles['info-div']}>
      <InfoSquare
        className={styles['info-infoicon']}
        style={iconStyling}
      />

      <div className={styles['info-text']}>
        <span
          id={styles['infotext-header']}
          style={headerStyling}
        >
          {props.heading}
        </span>
        {props.body}
      </div>
    </div>
  );
};

export default InfoComponent;
