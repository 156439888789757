import { Grid } from '@mui/material';
import ContainerField from './ContainerField';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { UserSelector } from '../../redux/user/selector';

const LicenseContainer = () => {
  const { t } = useTranslation('account');
  const userDetails = useSelector(
    (state) => UserSelector(state).userDetails,
    shallowEqual
  );
  return (
    <>
      <Grid
        item
        xs={12}
        textAlign={'left'}
        fontSize={'1.5rem'}
      >
        <h4>{t('license.header')}</h4>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'right'}
      >
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('license.type') + ':'}
            value={userDetails?.currentlicense?.name}
          />
        </Grid>
        {/* <ContainerField
            label={t('license.billingDate') + ':'}
            value={userDetails?.billingdate}
          /> */}
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('license.startDate') + ':'}
            value={userDetails?.licencestartdate}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('license.endDate') + ':'}
            value={userDetails?.licenceenddate}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('license.quotaSpent') + ':'}
            value={userDetails?.quotaspent}
          />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <ContainerField
            label={t('license.quotaLimit') + ':'}
            value={userDetails?.quotalimit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LicenseContainer;
