import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import bannerLogo from '../../assets/logo_dark.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClick: () => void;
};

const UpdateAppPopup: FC<Props> = (props: Props) => {
  const { t } = useTranslation(['dashboard', 'common']);
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-label="Aktualisierungsmeldung SUMM AI"
      centered
      backdrop="static"
      keyboard={false}
      className="text-center"
    >
      <Modal.Body className="p-4">
        <img
          src={bannerLogo}
          alt="Logo SUMM"
        />
        <h4 className="mt-3">Sicherheitsupdate SUMM AI</h4>
        <p className="text-left">
          <span
            dangerouslySetInnerHTML={{
              __html:
                'Liebe Nutzende,<br /><br />wir haben unsere App aktualisiert, um die IT-Sicherheit zu erhöhen. Im Rahmen dieser Aktualisierung wurden die bisherigen Login-Daten ungültig gemacht und Sie müssen sich neu registrieren, um die App nutzen zu können. Ihre Daten, wie zum Beispiel bisherige Übersetzungen, werden bei Verwendung derselben E-Mail-Adresse automatisch mit umgezogen.<br />Vielen Dank für Verständnis.<br /><br />Bitte klicken Sie auf den Aktualisieren Button, um die App zu aktualisieren und sich anschließend neu zu registrieren.',
            }}
          />
        </p>
        <p className="text-left">Ihr SUMM AI Team 🐝</p>
        <Button
          id="closeWhatsnewPopup"
          onClick={props.onClick}
          className="mt-5 mb-5"
        >
          {t('update', { ns: 'common' })}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAppPopup;
