import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateTransHistory } from 'src/redux/translationHistory/actions';
import { HistorySelector } from 'src/redux/translationHistory/selector';

type Props = {};

const SaveTransHistoryUpdate = (props: Props) => {
  const { t } = useTranslation('common');
  const dispatch: any = useDispatch();
  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  const handleSaveButton = async () => {
    const userEditText =
      document.getElementById('translation-output')?.innerHTML ?? '';
    let isHtmlTextInput = false;
    if (userEditText.includes('<strong') || userEditText.includes('<a')) {
      isHtmlTextInput = true;
    }
    dispatch(
      updateTransHistory({
        id: historyJob?.id,
        simpleTextUserEdited: userEditText,
        userFeedback: 'adjusted',
        category: historyJob?.category ?? '',
        tags: historyJob?.tags ?? '',
        input_text_type: isHtmlTextInput ? 'html' : 'plain_text',
      })
    );
  };
  return (
    <Button
      className="mt-2 w-100"
      onClick={handleSaveButton}
    >
      {t('save')}
    </Button>
  );
};

export default SaveTransHistoryUpdate;
