import React from 'react';
import {
  Container,
  Typography,
  Box,
  Link as MuiLink,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

type Props = {};

const NoRoutesPage = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('404Page');
  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: '10%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <ErrorOutlineIcon
          sx={{ fontSize: '10rem', color: theme.palette.secondary.main, mb: 2 }}
        />
        <Typography
          component="h1"
          variant="h4"
          sx={{
            fontWeight: 'bold',
            fontSize: '5rem',
            color: theme.palette.primary.main,
          }}
        >
          404
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            mb: 4,
            fontSize: '1.5rem',
            color: theme.palette.primary.main,
          }}
        >
          {t('not_exist')}
        </Typography>
        <MuiLink
          component={Link}
          to="/dashboard"
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            textDecoration: 'none',
            color: theme.palette.primary.main,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {t('go_to_dashboard')}
        </MuiLink>
      </Box>
    </Container>
  );
};

export default NoRoutesPage;
