import OptinWaitingSupabase from './OptinWaitingSupabase';

const OptinWaitingPage = () => {
  return (
    <>
      <div>
        <OptinWaitingSupabase />
      </div>
    </>
  );
};

export default OptinWaitingPage;
