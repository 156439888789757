export const offlineProducts = [
  {
    id: 1,
    name: 'Kostenfreies Paket',
    pricemonthlynetto: '0.00',
    priceyearlynetto: '0.00',
    quotacharacters: 0,
    glossaryaccess: true,
    synonymsaccess: true,
    translationaccess: false,
    supportby: ['none'],
    platformaccess: ['webapp'],
    description:
      'Diese Lizenz kann ausschließlich von kleinen Unternehmen mit weniger als 10 Mitarbeitenden oder Übersetzungsbüros von Lebenshilfen & Caritas erworben werden',
    features: [
      {
        availability: false,
        description: 'Keine KI-basierten Übersetzungsvorschläge',
      },
      {
        availability: true,
        description: 'Glossar abrufen',
      },
      {
        availability: true,
        description: 'Synonyme suchen',
      },
      {
        availability: true,
        description: 'Zugang über Website',
      },
      {
        availability: true,
        description: 'E-Mail Support',
      },
    ],
    translatorlicence: true,
    premiumaccess: false,
  },
  {
    id: 2,
    name: 'Basis Paket',
    pricemonthlynetto: '49.00',
    priceyearlynetto: '44.90',
    quotacharacters: 18000,
    glossaryaccess: true,
    synonymsaccess: true,
    translationaccess: true,
    supportby: ['mail'],
    platformaccess: ['webapp', 'wordaddin'],
    description:
      'Diese Lizenz kann ausschließlich von kleinen Unternehmen mit weniger als 10 Mitarbeitenden oder Übersetzungsbüros von Lebenshilfen & Caritas erworben werden',
    features: [
      {
        availability: true,
        description:
          'Für 18.000 Zeichen Ausgangstext Übersetzungsvorschläge generieren',
      },
      {
        availability: true,
        description: 'Glossar abrufen und eigene Einträge erstellen',
      },
      {
        availability: true,
        description: 'Synonyme suchen',
      },
      {
        availability: true,
        description: 'Zugang über Microsoft Word Add-In oder Website',
      },
      {
        availability: true,
        description: 'E-Mail Support',
      },
    ],
    translatorlicence: true,
    premiumaccess: true,
  },
  {
    id: 3,
    name: 'Premium Paket',
    pricemonthlynetto: '99.00',
    priceyearlynetto: '89.90',
    quotacharacters: 36000,
    glossaryaccess: true,
    synonymsaccess: true,
    translationaccess: true,
    supportby: ['mail', 'phone'],
    platformaccess: ['webapp', 'wordaddin'],
    description:
      'Diese Lizenz kann ausschließlich von kleinen Unternehmen mit weniger als 10 Mitarbeitenden oder Übersetzungsbüros von Lebenshilfen & Caritas erworben werden',
    features: [
      {
        availability: true,
        description:
          'Für 36.000 Zeichen Ausgangstext Übersetzungs-vorschläge generieren',
      },
      {
        availability: true,
        description: 'Glossar abrufen und eigene Einträge erstellen',
      },
      {
        availability: true,
        description: 'Synonyme suchen',
      },
      {
        availability: true,
        description: 'Zugang über Microsoft Word Add-In oder Website',
      },
      {
        availability: true,
        description: 'Telefon-Support',
      },
    ],
    translatorlicence: true,
    premiumaccess: true,
  },
];
