import { createSelector } from 'reselect';

export interface FeaturesReducer {
  loading: boolean;
  featuresList: null | {
    [key: number]: {
      id: number;
      name: string;
      release_date: null | string;
      seen: boolean;
      seen_on: null | string;
      feature_id: number;
    };
  };
}
interface RootState {
  features: FeaturesReducer;
}

// This used to memorize the selector value
export const FeatureSelector = createSelector(
  (state: RootState) => state.features,
  (config: FeaturesReducer) => {
    return {
      loading: config.loading,
      featuresList: config.featuresList,
    };
  }
);
