import { Badge } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  show: boolean;
};

const FeatureComp = ({ children, show }: Props) => {
  return (
    <Badge
      color="error"
      variant="dot"
      invisible={show}
    >
      {children}
    </Badge>
  );
};

export default FeatureComp;
