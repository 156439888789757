import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import ProductCard from './ProductCard';
import OrderForm from './orderForm/OrderForm';
import Cookies from 'universal-cookie';
import { ChevronLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../LoginForm';
import SignupFormMinimal from '../SignupFormMinimal';
import ToggleSwitch from '../../widgets/ToggleSwitch';
import InfoComponent from '../../widgets/InfoComponent';
import HelpButton from './HelpButton';
import styles from './CheckoutPage.module.css';
import { refreshSession } from 'src/redux/supabase/actions';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { loginAction } from 'src/redux/supabase/actions';

const CheckoutPage = () => {
  const cookies = new Cookies();
  const history = useNavigate();
  const dispatch = useDispatch();
  if (!cookies.get('selectedProduct')) {
    history('/summ-products');
  }

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const supabaseUser = useSelector(
    (state) => supabaseSelector(state).user,
    shallowEqual
  );
  const [product, setProduct] = useState(
    cookies.get('selectedProduct') ? cookies.get('selectedProduct') : {}
  );
  const [checked, setChecked] = useState(
    cookies.get('selectedProduct')
      ? product.licencePeriod === 'Jährlich'
      : false
  );

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowOrderForm(supabaseUser ? true : false);
      setLoading(false);
    }, 1000);
  }, [supabaseUser]);

  useEffect(() => {
    // dispatch(refreshSession());
    setShowOrderForm(supabaseUser ? true : false);
    //TODO: fix lint error with more knowledge
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = () => {
    setShowSignupForm(false);
    setError('');
  };

  async function handleLoginSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      dispatch(loginAction(emailRef.current.value, passwordRef.current.value));
      localStorage.setItem('email', emailRef.current.value);
      // if login successful then show whole form
      setShowOrderForm(true);
    } catch {
      setError(
        'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Email und/oder Passwort.'
      );
    }
  }

  const toggleProduct = () => {
    let changingProduct = product;
    if (product.licencePeriod === 'Monatlich') {
      changingProduct = { ...changingProduct, licencePeriod: 'Jährlich' };
      setProduct(changingProduct);
      setChecked(true);
    } else {
      changingProduct = { ...changingProduct, licencePeriod: 'Monatlich' };
      setProduct(changingProduct);
      setChecked(false);
    }
  };

  return cookies.get('selectedProduct') ? (
    <>
      <Container className={styles.baseContainer}>
        <Row className="mt-4">
          <Col md={2}></Col>

          <Col
            md={{ span: 8, offset: 2 }}
            className="text-left"
          ></Col>
        </Row>
        <Row className="mt-5">
          <Col className={styles.productDiv}>
            <Button
              variant=""
              onClick={() => {
                history('/summ-products');
              }}
            >
              <ChevronLeft />
              <span> Zurück zur Produktauswahl</span>
            </Button>
            <div className={styles.toggleDiv}>
              <ToggleSwitch
                leftLabel={'monatlich'}
                rightLabel={'jährlich'}
                rightStyle={{
                  fontWeight: 'bold',
                }}
                checked={checked}
                onClick={toggleProduct}
              />
            </div>
            <br />
            <div className={styles.productCard}>
              <ProductCard
                product={product}
                licencePeriod={product.licencePeriod}
              />
            </div>
          </Col>

          <Col md={5}>
            <h1 className={styles.accountHeading}>
              Bestellvorgang abschließen
            </h1>
            <Alert
              variant="danger"
              show={error.length > 0}
            >
              {error}
            </Alert>

            {supabaseUser && showOrderForm ? (
              <>
                {/* check if email is verified */}
                {supabaseUser?.created_at ? (
                  <OrderForm selectedProduct={product} />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {showLoginForm && (
                  <div className="mt-4">
                    <Button
                      variant=""
                      onClick={() => {
                        setShowLoginForm(false);
                        setError('');
                      }}
                    >
                      <ChevronLeft />
                      <span> Zurück zur Registrierung</span>
                    </Button>

                    <LoginForm
                      handleSubmit={handleLoginSubmit}
                      emailRef={emailRef}
                      passwordRef={passwordRef}
                    />
                  </div>
                )}

                {showSignupForm && (
                  <div
                    className="mt-4"
                    hidden={loading}
                  >
                    <Button
                      variant=""
                      onClick={handleSignup}
                    >
                      <ChevronLeft />
                      <span> Zurück zum Login</span>
                    </Button>
                    <SignupFormMinimal />
                  </div>
                )}
                {!showLoginForm && !showSignupForm && (
                  <div className="mt-4">
                    <p>Haben Sie bereits einen Account?</p>

                    <Row>
                      <Col>
                        <Button
                          className="w-100"
                          onClick={() => setShowLoginForm(true)}
                        >
                          Ja, einloggen
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="w-100"
                          onClick={() => setShowSignupForm(true)}
                        >
                          Nein, neu registrieren
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            )}
          </Col>
          <Col>
            <InfoComponent
              heading="Informationen zur kostenfreien Testphase"
              body={
                <>
                  <span>
                    Für alle Pakete gilt: <b>14 Tage kostenfrei</b> testen -
                    damit Sie sich selbst von SUMM überzeugen können!
                  </span>
                  <span>
                    Wie funktioniert die Testphase? Innerhalb von 14 Tagen nach
                    Freischaltung Ihres Accounts haben Sie die Möglichkeit, alle
                    Funktionen des gewählten Pakets kostenfrei und
                    uneingeschränkt zu verwenden. Wenn Sie ihre Bestellung
                    innerhalb dieses Zeitraums per E-Mail an{' '}
                    <a href="mailto:kuendigung@summ-ai.com">
                      kuendigung@summ-ai.com
                    </a>{' '}
                    unter Angabe Ihrer Kundennummer sowie Ihrer verwendeter
                    E-Mail-Adresse kündigen, endet Ihre Lizenz nach 14 Tagen und
                    Sie zahlen selbstverständlich nichts.
                  </span>
                </>
              }
            />
            <div className={styles.helpButton + ' text-right'}>
              <HelpButton />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default CheckoutPage;
