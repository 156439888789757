export const category = [
  {
    id: 'cat-1',
    text: 'Übersetzer:in',
    value: 'translator',
  },
  {
    id: 'cat-2',
    text: 'Büroleitung',
    value: 'officeManager',
  },
  {
    id: 'cat-3',
    text: 'Andere',
    value: 'other',
  },
];
