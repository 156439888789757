export const generateRTFFromHtml = (
  htmlContent: string,
  filename: string = 'summ-ai'
) => {
  const rtfContent = htmlToRtf(htmlContent);

  // Create a Blob from the HTML string
  const blob = new Blob([rtfContent], {
    type: 'application/rtf',
  });

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.rtf`;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

function htmlToRtf(html: string): string {
  let rtf = '{\\rtf1\\ansi\\deff0 {\\fonttbl {\\f0 Arial;}}';
  const div = document.createElement('div');
  div.innerHTML = html;

  function processNode(node: Node): void {
    switch (node.nodeType) {
      case Node.ELEMENT_NODE:
        rtf += elementToRtf(node as HTMLElement);
        break;
      case Node.TEXT_NODE:
        rtf += textToRtf(node as Text);
        break;
    }
    node.childNodes.forEach((child: Node) => processNode(child));
    if (node.nodeType === Node.ELEMENT_NODE) {
      rtf += closeElement(node as HTMLElement);
    }
  }

  function elementToRtf(element: HTMLElement): string {
    let rtfElement = '';
    switch (element.tagName.toLowerCase()) {
      case 'h1':
        rtfElement += '\\fs48\\b ';
        break;
      case 'p':
        rtfElement += '\\par ';
        break;
      case 'b':
        rtfElement += '\\b ';
        break;
      case 'i':
        rtfElement += '\\i ';
        break;
      case 'a':
        rtfElement += `{\\field{\\*\\fldinst{HYPERLINK "${
          (element as HTMLAnchorElement).href
        }"}}{\\fldrslt `;
        break;
      case 'ul':
        rtfElement += '\\par ';
        break;
      case 'ol':
        rtfElement += '\\par ';
        break;
      case 'li':
        if (element.parentElement?.tagName.toLowerCase() === 'ul') {
          rtfElement += '\\bullet ';
        } else if (element.parentElement?.tagName.toLowerCase() === 'ol') {
          rtfElement += '\\par ';
        }
        break;
      case 'blockquote':
        rtfElement += '\\par \\ql\\li720 ';
        break;
      case 'code':
        rtfElement += '\\f1\\fs20 ';
        break;
    }
    if (element.style.color) {
      rtfElement += `\\cf1 `;
    }
    if (element.style.fontSize) {
      const size = parseInt(element.style.fontSize) * 2;
      rtfElement += `\\fs${size} `;
    }
    if (element.style.fontFamily) {
      rtfElement += `\\f0 `;
    }
    return rtfElement;
  }

  function textToRtf(textNode: Text): string {
    return (
      textNode.nodeValue
        ?.replace(/[\{\}\\]/g, '\\$&')
        .replace(/[^\x00-\x7F]/g, (char) => `\\u${char.charCodeAt(0)}?`) || ''
    );
  }

  function closeElement(element: HTMLElement): string {
    switch (element.tagName.toLowerCase()) {
      case 'h1':
        return '\\b0\\fs24 ';
      case 'p':
        return '\\par ';
      case 'b':
        return '\\b0 ';
      case 'i':
        return '\\i0 ';
      case 'a':
        return '}}';
      case 'ul':
      case 'ol':
        return '\\par ';
      case 'li':
        return '\\par ';
      case 'blockquote':
        return '\\par \\li0 ';
      case 'code':
        return '\\f0\\fs24 ';
    }
    return '';
  }

  processNode(div);
  rtf += '}';
  return rtf;
}
