import { SEND_FEEDBACK, LOADING, STOP_LOADING } from './types';

const initialState: any = {
  feedback: false,
  loading: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case SEND_FEEDBACK:
      return {
        ...state,
        feedback: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
