import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#374d5d',
    },
    secondary: {
      main: '#DCE2E6',
    },
    error: {
      main: '#B32A2A',
    },
    general: {
      silverBlue: '#f8f9fa',
      overlay: 'rgb(0 0 0 / 9%)',
      slateBlue: '#54758e',
      slateBlueOverlay: 'rgb(83 105 138 / 20%)',
      white: '#ffffff',
      paleBlue: '#e9ecef',
      lightSteelBlue: '#ced4da',
      cyanBlue: '#8997A1',
      silverGray: '#b9b9b9',
      lightGray: '#f5f5f5',
      lightBlue: '#dee2e6',
      pattensBlue: '#E3E9ED',
      teal: '#4a7690',
      slateGray: '#6c757d',
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#374d5d',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'revert',
  },
});
