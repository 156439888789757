import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { HandThumbsUp, Pencil, HandThumbsDown } from 'react-bootstrap-icons';
import { FeedbackText } from '../../fixedData';
import ShowFeedbackModal from './ShowFeedbackModal';
import { useTranslation } from 'react-i18next';

type Props = {
  // setShowInfoBorderOutput(value: boolean): void;
  setShowTooltipOutput(value: boolean): void;
  outputRefEditor: any;
  setShowFeedbackButtons(value: boolean): void;
  setShowSubmitAdjustedButton: Dispatch<SetStateAction<boolean>>;
  updateSimpleTextUserEdited: (
    value: FeedbackText,
    userFeedbackText?: string
  ) => void;
};

const FeedbackButtonsComp = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false); // state that controls visibility of negative feedback modal
  const negFeedback: any = useRef();
  const handleAdjustment = () => {
    // show tooltip over output field and highlight box
    // props.setShowInfoBorderOutput(true);
    props.setShowTooltipOutput(true);
    props.outputRefEditor.current.focus();

    setTimeout(() => {
      props.setShowTooltipOutput(false);
    }, 10 * 1000);

    // hide all Feedback buttons
    props.setShowFeedbackButtons(false);

    // show new button for submit
    props.setShowSubmitAdjustedButton(true);
  };

  return (
    <>
      <Form.Group className="feedbackContainer">
        <Button
          className="w-100"
          onClick={() => props.updateSimpleTextUserEdited('positive')}
        >
          {t('feedback.positive.buttonText')} <HandThumbsUp />
        </Button>
        <Button
          className="mt-2 w-100"
          onClick={handleAdjustment}
        >
          {t('feedback.edit.buttonText')} <Pencil />
        </Button>
        <Button
          className="mt-2 w-100"
          onClick={() => setShowFeedbackModal(true)}
        >
          {t('feedback.negative.buttonText')} <HandThumbsDown />
        </Button>
      </Form.Group>
      <ShowFeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
        negFeedback={negFeedback}
        updateSimpleTextUserEdited={props.updateSimpleTextUserEdited}
      />
    </>
  );
};

export default FeedbackButtonsComp;
