export const checkSwitch = () => {
  let switchValue = localStorage.getItem('switchInput-translation-input');
  let switchChange = switchValue
    ? switchValue === 'on'
      ? 'off'
      : 'on'
    : 'off';

  return switchChange;
};
