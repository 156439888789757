export const languagesDetect: { [key: string]: string } = {
  af: 'Afrikaans',
  am: 'Amharic',
  ar: 'Arabic',
  as: 'Assamese',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bn: 'Bengali',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  ceb: 'Cebuano',
  cs: 'Czech',
  cv: 'Chuvash',
  cy: 'Welsh',
  da: 'Danish',
  // de: 'German',
  de: 'Deutsch',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  fi: 'Finnish',
  // fr: 'French',
  fr: 'Français',
  gd: 'Scottish Gaelic',
  ga: 'Irish',
  gl: 'Galician',
  gu: 'Gujarati',
  ht: 'Haitian',
  he: 'Hebrew',
  ha: 'Hausa',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  hy: 'Armenian',
  ilo: 'Iloko',
  id: 'Indonesian',
  is: 'Icelandic',
  it: 'Italian',
  jv: 'Javanese',
  ja: 'Japanese',
  kn: 'Kannada',
  ka: 'Georgian',
  kk: 'Kazakh',
  km: 'Central Khmer',
  ky: 'Kirghiz',
  ko: 'Korean',
  ku: 'Kurdish',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Latvian',
  lt: 'Lithuanian',
  lb: 'Luxembourgish',
  ml: 'Malayalam',
  mt: 'Maltese',
  mr: 'Marathi',
  mk: 'Macedonian',
  mg: 'Malagasy',
  mn: 'Mongolian',
  ms: 'Malay',
  my: 'Burmese',
  ne: 'Nepali',
  new: 'Newari',
  nl: 'Dutch',
  no: 'Norwegian',
  or: 'Oriya',
  om: 'Oromo',
  pa: 'Punjabi',
  pl: 'Polish',
  pt: 'Portuguese',
  ps: 'Pushto',
  qu: 'Quechua',
  ro: 'Romanian',
  ru: 'Russian',
  sa: 'Sanskrit',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  sd: 'Sindhi',
  so: 'Somali',
  es: 'Spanish',
  sq: 'Albanian',
  sr: 'Serbian',
  su: 'Sundanese',
  sw: 'Swahili',
  sv: 'Swedish',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Telugu',
  tg: 'Tajik',
  tl: 'Tagalog',
  th: 'Thai',
  tk: 'Turkmen',
  tr: 'Turkish',
  ug: 'Uighur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zh: 'Chinese (Simplified)',
  'zh-TW': 'Chinese (Traditional)',
  notfound: 'Not found',
};
