import { supabase } from 'src/config/supabase';
import {
  GET_SESSION,
  LOADING,
  LOGIN,
  LOGOUT,
  REFRESH_SESSION,
  RESEND_VERIFY_EMAIL,
  RESET_PASSWORD,
  SET_NEW_PASSWORD,
  STOP_LOADING,
  UPDATE_TOKEN,
  UPDATE_USER,
  VERIFY_OTP,
  VERIFY_SIGNUP_EMAIL,
} from './types';
import { appMessageHandling } from 'src/utils/errorHandler';
import { isEmpty, isNil } from 'lodash';
import Sentry from '../../config/sentryConfig';
import { updateUserExist } from '../user/actions';

export const getStorageRefreshToken = () => {
  const localStorageCheck = localStorage.getItem('token') || '';
  let token = isEmpty(localStorageCheck) ? {} : JSON.parse(localStorageCheck);
  if (token && token.refresh_token) {
    return token.refresh_token;
  }
  return '';
};

export const refreshSession = () => async (dispatch: any, getState: any) => {
  dispatch(loading());
  if (getState().supabase.session) {
    try {
      let refresTokenCheck = getState().supabase.session?.refresh_token ?? null;
      let res = await supabase.auth.refreshSession(
        refresTokenCheck
          ? {
              refresh_token: refresTokenCheck,
            }
          : undefined
      );

      if (res.error) {
        throw res.error;
      }
      return dispatch({
        type: REFRESH_SESSION,
        payload: res,
      });
    } catch (error: any) {
      Sentry.captureMessage(error.message, 'error');
      return appMessageHandling(dispatch, error.message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }
};

export const signUpAction =
  (email: string, password: string) => async (dispatch: any) => {
    try {
      let res = await supabase.auth.signUp({
        email,
        password,
      });
      let checkFail = !res?.data?.user?.identities?.length;
      if (res.error) {
        throw res.error;
      }
      if (!checkFail) {
        localStorage.setItem('SIGNUP_EMAIL', email);
        // dispatch({
        //   type: SIGN_UP,
        //   payload: res,
        // });
      }
      return checkFail;
    } catch (error: any) {
      Sentry.captureMessage(error.message, 'error');
      return appMessageHandling(dispatch, error.message, 'error');
    }
  };

export const loginAction =
  (email: string, password: string) => async (dispatch: any) => {
    try {
      let res = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (res.error) {
        throw res.error;
      }
      dispatch(updateUserExist('true'));
      return dispatch({
        type: LOGIN,
        payload: res,
      });
    } catch (error: any) {
      Sentry.captureMessage(error.message, 'error');
      return appMessageHandling(dispatch, 'error.loginError', 'error');
    }
  };

export const logoutAction = () => async (dispatch: any) => {
  try {
    let res = await supabase.auth.signOut();
    if (res.error) {
      throw res.error;
    }
    // localStorage.clear();
    localStorage.setItem('IN_PURCHASE_FLOW', 'false');
    return dispatch({
      type: LOGOUT,
    });
  } catch (error: any) {
    Sentry.captureMessage(error.message, 'error');
    return appMessageHandling(dispatch, error.message, 'error');
  }
};

export const sendEmailVerificationAction =
  () => async (dispatch: any, getState: any) => {
    try {
      let email = getState().supabase.user.email;
      let res = await supabase.auth.resend({
        type: 'signup',
        email: email,
      });
      if (res.error) {
        throw res.error;
      }
      return dispatch({
        type: VERIFY_SIGNUP_EMAIL,
      });
    } catch (error: any) {
      Sentry.captureMessage(error.message, 'error');
      return appMessageHandling(dispatch, error.message, 'error');
    }
  };

export const resendEmailVerificationAction = () => async (dispatch: any) => {
  try {
    const email = localStorage.getItem('SIGNUP_EMAIL') || '';
    let res = await supabase.auth.resend({
      type: 'signup',
      email: email,
    });
    if (res.error) {
      throw res.error;
    }
    appMessageHandling(
      dispatch,
      'success.resendEmailVerificationSucess',
      'success'
    );
    return dispatch({
      type: RESEND_VERIFY_EMAIL,
    });
  } catch (error: any) {
    Sentry.captureMessage(error.message, 'error');
    return appMessageHandling(
      dispatch,
      error.message || 'error.resendEmailVerificationError',
      'error'
    );
  }
};

export const passwordResetAction = (email: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    let res = await supabase.auth.resetPasswordForEmail(email);
    if (res.error) {
      throw res.error;
    }
    return dispatch({
      type: RESET_PASSWORD,
    });
  } catch (error: any) {
    appMessageHandling(dispatch, error.message, 'error');
    Sentry.captureMessage(error.message, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const getSessionAction = () => async (dispatch: any) => {
  try {
    let res = await supabase.auth.getSession();
    if (!isNil(res.error) || isNil(res.data.session)) {
      throw res.error;
    }
    return dispatch({
      type: GET_SESSION,
      payload: res,
    });
  } catch (error: any) {
    if (error?.message) {
      appMessageHandling(dispatch, error.message, 'error');
      Sentry.captureMessage(error.message, 'error');
    }
  }
};

export const setNewPasswordAction =
  (password: string, email: string) => async (dispatch: any) => {
    try {
      dispatch(loading());
      let res = await supabase.auth.updateUser({
        password,
        email,
      });
      if (res.error) {
        throw res.error;
      }
      appMessageHandling(dispatch, 'success.newPasswordSetSuccess', 'success');
      dispatch(updateUserAction(res.data.user));
      return dispatch({
        type: SET_NEW_PASSWORD,
        payload: res,
      });
    } catch (error: any) {
      appMessageHandling(
        dispatch,
        error.message || 'error.newPasswordSetError',
        'error'
      );
      Sentry.captureMessage(error.message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const verifyOtp =
  ({ email, otp }: { email: string; otp: string }) =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      let res = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });
      if (res.error) {
        throw res.error;
      }
      if (['password-reset'].includes(sessionStorage.getItem('type') || '')) {
        sessionStorage.setItem('type', 'new_password');
      }
      dispatch({
        type: VERIFY_OTP,
        payload: res,
      });

      return res;
    } catch (error: any) {
      appMessageHandling(
        dispatch,
        error.message || 'error.verifyOtpError',
        'error'
      );
      Sentry.captureMessage(error.message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
// No api call and also used for unit testing
export const updateTokenAction =
  (session: object | null) => (dispatch: any) => {
    return dispatch({
      type: UPDATE_TOKEN,
      payload: session,
    });
  };

export const updateUserAction = (user: object | null) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_USER,
    payload: user,
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
