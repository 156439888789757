import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  setDisableFeedbackSubmit: Dispatch<SetStateAction<boolean>>;
  negFeedbackText: any;
};

const ShowNefFeedbackInputComp = ({
  setDisableFeedbackSubmit,
  negFeedbackText,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  return (
    <Form.Control
      className="mt-4"
      ref={negFeedbackText}
      as="textarea"
      rows={2}
      placeholder={t('feedback.negative.giveReason')}
      onChange={() => {
        setDisableFeedbackSubmit(
          negFeedbackText?.current?.value?.trim() === ''
        );
      }}
      aria-label={t('feedback.negative.giveReason')}
    />
  );
};

export default ShowNefFeedbackInputComp;
