import featureService from 'src/services/feature.service';
import { LOADING, STOP_LOADING, GET_FEATURES, UPDATE_FEATURE } from './types';
import Sentry from '../../config/sentryConfig';

export const getFeatures = () => async (dispatch: any) => {
  try {
    const res = await featureService.getFeatures();
    return dispatch({
      type: GET_FEATURES,
      payload: res.data.features,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get Features error: ${e}`, 'error');
  }
};

export const updateFeature = (value: number) => async (dispatch: any) => {
  try {
    await featureService.updateFeature(value);
    return dispatch({
      type: UPDATE_FEATURE,
      payload: value,
    });
  } catch (e: any) {
    Sentry.captureMessage(`update Features error: ${e}`, 'error');
    // return appMessageHandling(dispatch, 'error.update_features_error', 'error');
  }
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
