import { useTranslation } from 'react-i18next';
import MenuListComp from 'src/coreUI/menuComp/MenuListComp';
import { languageMenu } from './navbarComp/fixedData';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LanguageSwitchBtn = () => {
  const { t, i18n } = useTranslation('common');

  const changeLanguage = (language: 'en' | 'de' | 'fr' | string) => {
    if (language === 'de') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'de');
    } else if (language === 'en') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'en');
    } else if (language === 'fr') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'fr');
    }
  };

  return (
    <>
      <MenuListComp
        list={languageMenu.list(changeLanguage, t)}
        menuView={languageMenu.menuView(t('nav.language'), {
          fontSize: '1rem',
        })}
        title={languageMenu.title}
        type="button"
        name={t('nav.language')}
        menuEndIcon={<ArrowDropDownIcon />}
      />
    </>
  );
};

export default LanguageSwitchBtn;
