import { Permission } from '../enums/permission';

export const permissionUtil = (
  allowedPermission: Permission[],
  userPermission: Permission[]
) => {
  let allow = false;
  userPermission?.forEach((per: Permission) => {
    if (allowedPermission.includes(per)) {
      allow = true;
    }
  });

  return allow;
};
