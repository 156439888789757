import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateOrTimeView } from '@mui/x-date-pickers';

type Props = {
  label?: string;
  views?: readonly DateOrTimeView[] | undefined;
  value?: any;
  handleChange: (value: any, context: any) => void;
  handleConfirm: (value?: any) => void;
};

const DateTimePickerComp = ({
  label = '',
  value,
  views = undefined,
  handleChange,
  handleConfirm,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        views={views}
        value={value}
        timezone="system"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderBottom: '1px solid #919191',
            height: '53px',
          },
          '& .MuiInputBase-root': {
            borderRadius: 0,
          },
          '& .MuiOutlinedInput-input': {
            paddingBottom: 0,
            paddingTop: '19px',
          },
          '& .MuiIconButton-root': {
            marginTop: '17px',
          },
        }}
        onChange={handleChange}
        onAccept={handleConfirm}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerComp;
