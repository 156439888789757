/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom';
// import { Debug as DebugIntegration } from '@sentry/integrations';

let isProduction: boolean = process.env.NODE_ENV === 'production';
Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications',
  ],
  tracePropagationTargets: [
    // 'http://localhost:3000/*',
    // 'http://localhost:3001/*',
    // 'http://localhost:8000/*',
    '*.summ-ai.com',
  ],
  integrations: [
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: isProduction ? 0.2 : 1.0, // Capture 100% of the transactions in dev, and 20%!
  sampleRate: 1.0, // Capture 100% of all errors.
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export default Sentry;
