import { IconButton, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

type Props = {
  buttonStyle?: object;
  handleClick: () => void;
  size?: 'inherit' | 'large' | 'medium' | 'small' | undefined;
};

const EditButtonComp = ({
  buttonStyle,
  handleClick,
  size = 'small',
}: Props) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        ...buttonStyle,
      }}
      onClick={handleClick}
      disableFocusRipple
      disableRipple
      disableTouchRipple
    >
      <EditIcon
        fontSize={size}
        sx={{ color: theme.palette.primary.main }}
      />
    </IconButton>
  );
};

export default EditButtonComp;
