import untranslatedWordService from 'src/services/untranslatedWord.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  LOADING,
  STOP_LOADING,
  GET_UNTRANSLATED_WORD,
  DELETE_UNTRANSLATED_WORD,
  CREATE_UNTRANSLATED_WORD,
  SEARCH_UNTRANSLATED_WORD,
  SERACH_LOADING,
  SEARCH_STOP_LOADING,
  RESET_STATE_VALUE,
} from './types';
import Sentry from '../../config/sentryConfig';
import { PartialUntranslatedReducer } from './selector';

export const getWordList = () => async (dispatch: any) => {
  try {
    const res = await untranslatedWordService.getUntranslatedWord();
    dispatch({
      type: GET_UNTRANSLATED_WORD,
      payload: res.data,
    });
  } catch (e: any) {
    return Sentry.captureMessage(`Get untranslated word error: ${e}`, 'error');
  }
};

export const searchWordList =
  (data: { search_string: string }) => async (dispatch: any) => {
    try {
      dispatch(searchLoading());
      const res = await untranslatedWordService.searchUntranslatedWord(data);
      dispatch({
        type: SEARCH_UNTRANSLATED_WORD,
        payload: res.data,
      });
    } catch (e: any) {
      return Sentry.captureMessage(
        `Search untranslated word error: ${e}`,
        'error'
      );
    } finally {
      dispatch(seachStopLoading());
    }
  };

export const deleteWord =
  (data: { id: number; is_owner: string }) => async (dispatch: any) => {
    try {
      await untranslatedWordService.deleteUntranslatedWord(data.id);
      dispatch({
        type: DELETE_UNTRANSLATED_WORD,
        payload: data,
      });
      return appMessageHandling(
        dispatch,
        'success.delete_untranslated_word_success',
        'success'
      );
    } catch (e: any) {
      Sentry.captureMessage(`Delete untranslated word error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.response.data.detail || 'error.delete_untranslated_word_error',
        'error'
      );
    }
  };

export const createunTranslatedWord =
  (data: { word: string; is_owner: string }) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await untranslatedWordService.addUntranslatedWord(data);
      return dispatch({
        type: CREATE_UNTRANSLATED_WORD,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Create untranslated word error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.response.data.detail || 'error.create_untranslated_word_error',
        'error'
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const searchLoading = () => (dispatch: any) => {
  return dispatch({
    type: SERACH_LOADING,
  });
};

export const seachStopLoading = () => (dispatch: any) => {
  return dispatch({
    type: SEARCH_STOP_LOADING,
  });
};

export const emptyStateValueuntranslatedWord =
  (value: PartialUntranslatedReducer) => (dispatch: any) => {
    return dispatch({
      type: RESET_STATE_VALUE,
      payload: value,
    });
  };
