import MaterialSelectComp from '../../coreUI/materialSelect/MaterialSelectComp';
import { SelectChangeEvent } from '@mui/material/Select';
import { dataType } from './ouputTextSetting.types';
import React, { useContext } from 'react';
import { SettingCompContext } from './SettingComp';

type Props = {
  transLangMenu: dataType;
  transLangLabel: string;
  handelSetTransLang: (e: SelectChangeEvent<string>) => void;
  transLangVal: string;
};

const TransLanguageComp = ({
  transLangVal,
  transLangLabel,
  handelSetTransLang,
  transLangMenu,
}: Props) => {
  const { selectStyle } = useContext(SettingCompContext);
  return (
    <MaterialSelectComp
      menuItem={transLangMenu}
      handleChange={handelSetTransLang}
      value={transLangVal}
      label={transLangLabel}
      disabled={false}
      // toolTipsTitle={
      //   disable ? t('newLine.tooltipOutputUpdated') : undefined
      // }
      toolTipPlacement="right"
      name="preferred_translation_language"
      formStyle={{ width: '100%', ...selectStyle }}
    />
  );
};

export default TransLanguageComp;
