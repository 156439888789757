import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'src/redux/supabase/actions';
const LogoutDropdownItem = ({ buttonStyle = {} }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation('common');

  async function handleLogout(e) {
    e.preventDefault();
    try {
      await dispatch(logoutAction());
      history('/');
    } catch {
      console.log('logout error');
    }
  }

  return (
    <Button
      variant="text"
      sx={{ textTransform: 'capitalize', ...buttonStyle }}
      onClick={handleLogout}
      disableFocusRipple
      disableRipple
    >
      {t('logout')}
    </Button>
  );
};

export default LogoutDropdownItem;
