import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { TranslateSelector } from 'src/redux/translation/selector';

type Props = {
  translateLanguage: () => void;
  loadingTranslation: boolean;
};

const TranslateButtonComp = ({
  loadingTranslation,
  translateLanguage,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const emptyInput = useSelector(
    (state: any) => TranslateSelector(state).emptyInput,
    shallowEqual
  );

  return (
    <Button
      variant="primary"
      className="translationButton mb-4"
      onClick={translateLanguage}
      disabled={loadingTranslation || emptyInput}
      style={{ marginTop: '1rem' }}
    >
      {loadingTranslation ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span> {t('translationbutton.loading')}</span>
        </>
      ) : (
        <>{t('translationbutton.default')}</>
      )}
    </Button>
  );
};

export default TranslateButtonComp;
