import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoadingBanner = () => {
  const { t } = useTranslation('common');
  return (
    <div className="d-flex justify-content-center mt-5">
      <Spinner
        animation="border"
        role="status"
      ></Spinner>
      <span className="p-2"> {t('loading')}...</span>
    </div>
  );
};

export default LoadingBanner;
