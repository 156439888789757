import { Container } from 'react-bootstrap';
import FeedbackForm from '../feedbackForm/FeedbackForm';

const FeedbackContainer = () => {
  return (
    <Container className="border rounded-lg h-100">
      <h1>Feedback</h1>
      <FeedbackForm />
      {/* <p>
        In Kürze finden Sie hier ein Formular, um uns Feedback mitzuteilen.
        <br />
        In der Zwischenzeit können Sie uns Feedback gerne an{' '}
        <a href="mailto:feedback@summ-ai.com">feedback@summ-ai.com</a>{' '}
        schreiben.
      </p> */}
    </Container>
  );
};

export default FeedbackContainer;
