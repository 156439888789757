import { FixMe } from 'src/types/types';
import CustomeToolbar from './CustomeToolbar';
import {
  enUS,
  deDE,
  frFR,
  DataGrid,
  GridCallbackDetails,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  deleteGlossaryEntry,
  fIlterValueSetGlossaryEntry,
  getGlossaryEtnries,
  glossaryTagsUpdate,
  updateGlossaryEntry,
} from 'src/redux/glossaryEntries/actions';
import { GlossaryEntriesSelector } from 'src/redux/glossaryEntries/selector';
import { useTheme } from '@mui/material';
import PaginationComp from './PaginationComp';
import { columns } from './columns';
import { FilterValue } from 'src/types/transHistory.types';
import { isEmpty } from 'lodash';
import ConfirmModel from './ConfirmModel';

type Props = {};

const GlossaryTable = (props: Props) => {
  const { t, i18n } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  const [activeId, setActiveId] = useState('');
  const [deleteEntry, setDeleteEntry] = useState<{
    openModel: boolean;
    id: null | number;
  }>({
    openModel: false,
    id: null,
  });
  const dispatch: any = useDispatch();
  const theme = useTheme();

  const tags = useSelector(
    (state: any) => GlossaryEntriesSelector(state).tags,
    shallowEqual
  );

  const glossaryList = useSelector(
    (state: any) => GlossaryEntriesSelector(state).glossaryEntriesList,
    shallowEqual
  );

  const entries = useSelector(
    (state: any) => GlossaryEntriesSelector(state).entries,
    shallowEqual
  );
  const currentPage = useSelector(
    (state: any) => GlossaryEntriesSelector(state).currentPage,
    shallowEqual
  );
  const loading = useSelector(
    (state: any) => GlossaryEntriesSelector(state).loading,
    shallowEqual
  );
  const languageLocaleMapping: FixMe = {
    en: enUS,
    de: deDE,
    fr: frFR,
  };

  const filterValue = useSelector(
    (state: any) => GlossaryEntriesSelector(state).filters,
    shallowEqual
  );

  const updateTags = async (row: FixMe, tags: string[]) => {
    let updatedTags: string | string[] = tags;
    if (Array.isArray(tags)) {
      updatedTags = tags.join();
    }
    dispatch(
      updateGlossaryEntry({
        glossaryID: row.id,
        word: row.word,
        explanation: row.explanation,
        tags: updatedTags,
      })
    );
  };

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>, row: FixMe) {
    if (e.key !== 'Enter') return;
    const value = (e?.target as HTMLInputElement).value;
    if (!value?.trim()) return;
    dispatch(
      glossaryTagsUpdate({ ...tags, [row.id]: [...tags[row.id], value] })
    );
    (e?.target as HTMLInputElement).value = '';
    updateTags(row, [...tags[row.id], value]);
  }

  function removeTag(index: number, row: FixMe) {
    var temp = tags[row.id];
    temp = temp.filter((_el, i) => i !== index);
    dispatch(glossaryTagsUpdate({ ...tags, [row.id]: temp }));
    updateTags(row, temp);
  }

  // delete glossary entry on click
  const handleDeleteGlossaryEntry = async (id: number, confirm: boolean) => {
    // ask for confirmation
    // if (!window.confirm(t('glossaryEntries.deleteEntryQuestion'))) {
    //   return;
    // }
    if (!confirm) {
      return setDeleteEntry({ id: null, openModel: false });
    }
    await dispatch(deleteGlossaryEntry(id));
    dispatch(
      getGlossaryEtnries(
        {
          page: currentPage,
          page_size: Number(entries),
          ...(filterValue ? filterValue : {}),
        },
        false
      )
    );
    setDeleteEntry({ id: null, openModel: false });
  };

  const onDeleteEntryTrigger = (id: number) => {
    setDeleteEntry({ id, openModel: true });
  };

  function onFilterChange(
    model: GridFilterModel,
    details: GridCallbackDetails<'filter'>
  ): void {
    if (!isEmpty(filterValue?.value) || !isEmpty(filterValue?.actualValue)) {
      dispatch(
        fIlterValueSetGlossaryEntry({
          field: '',
          value: '',
          operation: '',
          actualValue: '',
        })
      );
    }
  }

  const handleConfirmFIlter = (data: FilterValue) => {
    dispatch(fIlterValueSetGlossaryEntry(data));
  };

  const handleClearFilter = () => {
    if (!isEmpty(filterValue?.value) && !isEmpty(filterValue?.actualValue)) {
      dispatch(
        fIlterValueSetGlossaryEntry({
          ...filterValue,
          value: '',
          actualValue: '',
        })
      );
    }
  };

  return (
    <div
      style={{ width: '100%', backgroundColor: theme.palette.general.white }}
    >
      {deleteEntry.id && deleteEntry.openModel && (
        <ConfirmModel
          id={deleteEntry.id}
          deleteAlert={deleteEntry.openModel}
          onDeleteEntry={handleDeleteGlossaryEntry}
          setDeleteAlert={(value: boolean) =>
            setDeleteEntry({ ...deleteEntry, openModel: value })
          }
        />
      )}
      <DataGrid
        localeText={
          languageLocaleMapping[i18n.language].components.MuiDataGrid
            .defaultProps.localeText
        }
        aria-label="Tabelle mit Glossareinträgen"
        autoHeight
        slots={{ toolbar: CustomeToolbar, pagination: PaginationComp }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              deleteIconProps: {
                onClick: handleClearFilter,
              },
            },
          },
        }}
        rows={Object.values(glossaryList)?.reverse()}
        columns={columns(
          activeId,
          setActiveId,
          filterValue,
          t,
          handleConfirmFIlter,
          handleKeyDown,
          removeTag,
          onDeleteEntryTrigger
        )}
        rowHeight={100}
        getRowHeight={({ id }) => {
          if (id === activeId) {
            return 330;
          } else {
            return null;
          }
        }}
        onRowClick={(params, event: FixMe) => {
          if (!event.ignore) {
            setActiveId(params.row.id);
          }
        }}
        // initialState={{
        //   pagination: {
        //     paginationModel: { pageSize: Number(entries), page: currentPage },
        //   },
        // }}
        loading={loading}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowSelection={true}
        disableColumnMenu
        onFilterModelChange={onFilterChange}
      />
    </div>
  );
};

export default GlossaryTable;
