import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';

type Props = {
  value: string;
  buttonStyle?: {};
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
};

const CopyButtomComp = ({
  value,
  buttonStyle = {},
  placement = 'top',
  size = undefined,
}: Props) => {
  const { t } = useTranslation('common');
  const [showClipboardTooltipCopy, setShowClipboardTooltipCopy] =
    useState(true);
  const clipboardButton = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (!showClipboardTooltipCopy) {
      setTimeout(() => {
        setShowClipboardTooltipCopy(true);
      }, 1500);
    }
  }, [showClipboardTooltipCopy]);

  const copyToClipboard = async (textForClipboard: string) => {
    const result = await navigator.permissions.query({
      name: 'clipboard-write' as PermissionName,
    });
    if (result.state === 'granted') {
      return await navigator?.clipboard?.writeText(textForClipboard);
    }
  };
  return (
    <Tooltip
      title={showClipboardTooltipCopy ? t('copy') : t('copied')}
      placement={placement}
    >
      <IconButton
        aria-label={t('copy')}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          ...buttonStyle,
        }}
        onClick={() => {
          setShowClipboardTooltipCopy(false);
          copyToClipboard(value);
        }}
        ref={clipboardButton}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <ContentCopyIcon
          fontSize={size}
          sx={{ color: theme.palette.primary.main }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButtomComp;
