import LoadingButton from '@mui/lab/LoadingButton';
import { isEmpty } from 'lodash';
import React, { RefObject, useRef, useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUpAction } from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { appMessageHandling } from 'src/utils/errorHandler';
import { setMatomoUid } from 'src/utils/matomo';

type Props = {};

const SignUpField = (props: Props) => {
  const { t } = useTranslation('common');
  const history = useNavigate();
  const [passType, setPassType] = useState('password');
  const [passHintType, setPassHintType] = useState('password');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const [error, setError] = useState('');

  const supabaseError = useSelector(
    (state: any) => supabaseSelector(state).error,
    shallowEqual
  );

  const emailRef: RefObject<any> = useRef();
  const passwordRef: RefObject<any> = useRef();
  const passwordConfirmRef: RefObject<any> = useRef();
  const termsOfUseAndDataPrivacyCheckboxRef: RefObject<any> = useRef();

  async function handleSubmit() {
    let pattern = /(?=.*[0-9a-zA-Z]).{6,}/;

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError(t('signupForm.passwordsDontMatch'));
    } else if (!pattern.test(passwordRef.current.value)) {
      return setError(t('signupForm.minPasswordLength'));
    }
    const email = emailRef.current.value;
    if (
      termsOfUseAndDataPrivacyCheckboxRef.current.checked &&
      !isEmpty(email) &&
      !isEmpty(passwordRef.current.value)
    ) {
      setLoading(true);
      let failedSignup = await dispatch(
        signUpAction(email.toLocaleLowerCase(), passwordRef.current.value)
      );
      if (failedSignup || supabaseError) {
        appMessageHandling(dispatch, 'error.notCreatedError', 'error');
      } else {
        sessionStorage.setItem('email', email.toLocaleLowerCase());

        // Check if user in purches flow or in signup flow
        history('/confirm');
        if (localStorage.getItem('IN_PURCHASE_FLOW') !== 'true') {
          localStorage.setItem('signup_form', 'true');
        }
        setMatomoUid(email.toLocaleLowerCase());
      }
      setLoading(false);
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                id="email"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>{t('signupForm.password')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passType}
                  ref={passwordRef}
                  required
                  id="password"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    //   type="text"
                    aria-label={t('showPassword')}
                    role="button"
                    onClick={() =>
                      passType === 'password'
                        ? setPassType('text')
                        : setPassType('password')
                    }
                    style={{ height: '100%' }}
                  >
                    {passType === 'password' ? <EyeFill /> : <EyeSlashFill />}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="password-confirm">
              <Form.Label>{t('signupForm.passwordConfirm')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passHintType}
                  ref={passwordConfirmRef}
                  required
                  id="password-confirm"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    //   type="button"
                    aria-label={t('showPassword')}
                    role="button"
                    onClick={() =>
                      passHintType === 'password'
                        ? setPassHintType('text')
                        : setPassHintType('password')
                    }
                    style={{ height: '100%' }}
                  >
                    {passHintType === 'password' ? (
                      <EyeFill />
                    ) : (
                      <EyeSlashFill />
                    )}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="agreePrivacyPolicy">
              <Form.Check
                type="checkbox"
                ref={termsOfUseAndDataPrivacyCheckboxRef}
                required
                id="agreePrivacyPolicy"
                style={{ display: 'inline-block', verticalAlign: 'top' }}
              />
              <Form.Label
                dangerouslySetInnerHTML={{
                  __html: t('signupForm.readPrivacyPolicy', {
                    interpolation: { escapeValue: false },
                  }),
                }}
                style={{ width: '90%' }}
              ></Form.Label>
            </Form.Group>

            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              sx={{ textTransform: 'capitalize', p: '8px 3em' }}
              id="otp-submit"
              onClick={handleSubmit}
            >
              {t('signupForm.register')}
            </LoadingButton>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpField;
