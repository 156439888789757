export const tableHeader: (t: any) => { id: string; content: string }[] = (
  t
) => [
  {
    id: 'name',
    content: t('account.name'),
  },
  {
    id: 'prefix',
    content: t('secretKey.key'),
  },
  {
    id: 'created',
    content: t('secretKey.created'),
  },
];
