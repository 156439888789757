import React, { useContext } from 'react';
import MaterialSelectComp from '../../coreUI/materialSelect/MaterialSelectComp';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { dataType } from './ouputTextSetting.types';
import { SettingCompContext } from './SettingComp';

type Props = {
  newLineLanguage: dataType;
  newLineLabel: string;
  newLineVal: string;
  handelSetNewLine: (e: SelectChangeEvent) => void;
};

const NewLine = ({
  newLineLanguage,
  newLineLabel,
  newLineVal,
  handelSetNewLine,
}: Props) => {
  const { selectStyle } = useContext(SettingCompContext);
  const handleSetNewLine = (e: SelectChangeEvent) => {
    handelSetNewLine(e);
  };

  return (
    <Grid
      item
      xs={12}
    >
      <MaterialSelectComp
        menuItem={newLineLanguage}
        handleChange={handleSetNewLine}
        value={newLineVal}
        label={newLineLabel}
        disabled={false}
        // toolTipsTitle={
        //   disable ? t('newLine.tooltipOutputUpdated') : undefined
        // }
        toolTipPlacement="right"
        formStyle={{ width: '100%', ...selectStyle }}
        name={'preferred_new_lines'}
      />
    </Grid>
  );
};

export default NewLine;
