import {
  TRIGGER_ALERT,
  REMOVE_ALERT,
  SIDE_BAR_TAB,
  RESIZE_TEXTAREA_BOX,
  // TRIGGER_FIRST_TIME,
} from './types';

export const triggerAlert =
  (msg: string, variant: string, alertOption?: object) =>
  (dispatch: any, getState: any) => {
    dispatch({
      type: TRIGGER_ALERT,
      payload: { msg, variant, options: alertOption },
    });

    setTimeout(() => {
      dispatch(removeAlert());
    }, 5000);
  };

export const sideBarTab = (tab: number | null) => (dispatch: any) => {
  return dispatch({
    type: SIDE_BAR_TAB,
    payload: tab,
  });
};
export const removeAlert = (duration?: number) => (dispatch: any) => {
  return dispatch({
    type: REMOVE_ALERT,
    payload: duration,
  });
};

export const resizeTextAreaSync =
  (data: { width: number; height: number }) => (dispatch: any) => {
    return dispatch({
      type: RESIZE_TEXTAREA_BOX,
      payload: data,
    });
  };
