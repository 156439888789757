import { getRequest, postRequest, multipartPostRequest } from './requests';

const exportedApi = {
  getUserData: (token, onSuccess, onError) => {
    getRequest('user/details/', token, onSuccess, onError);
  },
  getGlossaryTags: (token, onSuccess, onError) => {
    getRequest('glossary/tags', token, onSuccess, onError);
  },
  searchGlossary: (token, data, onSuccess, onError) => {
    postRequest('glossary/searchtext/', token, data, onSuccess, onError);
  },
  searchAIGlossary: (token, data, onSuccess, onError) => {
    postRequest('glossary/ai/search/', token, data, onSuccess, onError);
  },
  createGlossary: (token, data, onSuccess, onError) => {
    postRequest('glossary/newentry/', token, data, onSuccess, onError);
  },
  extractTextFromFile: (token, data, onSuccess, onError) => {
    multipartPostRequest(
      'translate/txtfromfile/',
      token,
      data,
      onSuccess,
      onError
    );
  },
};

export default exportedApi;
