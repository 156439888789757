import { useDispatch } from 'react-redux';
import { updateTransHistory } from 'src/redux/translationHistory/actions';
import { useTranslation } from 'react-i18next';
import { optionList } from '../fixedData';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
  params: any;
};

const Category = ({ params }: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const [paramValue, setParamValue] = useState(params.row);
  useEffect(() => {
    setParamValue(params.row);
  }, [params.row]);

  const updateCategory = async (category: any) => {
    if (category !== 'others')
      setParamValue({ ...paramValue, value: category });
    dispatch(
      updateTransHistory({
        id: paramValue.id,
        simpleTextUserEdited: paramValue.simpletextuseredited,
        userFeedback: paramValue.userfeedback,
        category: category,
        tags: paramValue.tags ? paramValue.tags : '',
      })
    );
    // }
  };
  const handleChange = (e: SelectChangeEvent<any>) => {
    updateCategory(e?.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id={`${t('history.selectTextTypeFor')}: '${paramValue.input_text}'`}
        size={'small'}
      >
        {t('history.selectTextTypeFor')}
      </InputLabel>
      <Select
        labelId={`${t('history.selectTextTypeFor')}: '${
          paramValue.input_text
        }'`}
        id={'textType-' + paramValue.id}
        value={paramValue.category}
        label={t('history.selectTextTypeFor')}
        onChange={handleChange}
        sx={{ textAlign: 'left' }}
        size="small"
      >
        <MenuItem value="">{t('none')}</MenuItem>
        {optionList.map(({ value, name }) => (
          <MenuItem
            value={value}
            key={value}
          >
            {t(name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Category;
