import { closeSnackbar, VariantType } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import theme from '../theme/themeConfig';
import i18next from 'i18next';

const action = (snackbarId: any) => {
  return (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      aria-label={i18next.t('close_alert', { ns: 'A11y' })}
      sx={{ position: 'absolute', top: '-5px', right: '-5px' }}
      disableFocusRipple
      disableRipple
      disableTouchRipple
    >
      <CloseIcon
        style={{ color: theme.palette.primary.main }}
        fontSize="small"
      />
    </IconButton>
  );
};

const configSetup: any = (variant: VariantType, options: object) => {
  return {
    // autoHideDuration: 7000,
    preventDuplicate: true,
    variant,
    action,
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    ...options,
  };
};

export default configSetup;
