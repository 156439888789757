import type { LexicalEditor } from 'lexical';
import { $patchStyleText } from '@lexical/selection';

import { $getSelection, $isRangeSelection } from 'lexical';
import { useCallback } from 'react';

import { FONT_FAMILY_OPTIONS, FONT_SIZE_OPTIONS } from '../fixedData';
import DropdownMenuComp from '../../dropdownMenuComp/DropdownMenuComp';

const FontDropDown = ({
  editor,
  value,
  type,
  selectStyle = {},
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  type: 'font-family' | 'font-size';
  selectStyle: object;
  disabled?: boolean;
}): JSX.Element => {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [type]: option,
          });
        }
      });
    },
    [editor, type]
  );

  const buttonAriaLabel =
    type === 'font-family'
      ? 'Formatting options for font family'
      : 'Formatting options for font size';

  return (
    <DropdownMenuComp
      value={value}
      options={type === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS}
      handleClick={handleClick}
      label={buttonAriaLabel}
      classname={type === 'font-family' ? 'icon block-type font-family' : ''}
      selectStyle={selectStyle}
      itemStyle={{
        '& .MuiTypography-root': { fontSize: '0.8rem' },
      }}
      disabled={disabled}
    />
  );
};

export default FontDropDown;
