import { createSelector } from 'reselect';

export interface SynonymsReducer {
  loading: boolean;
  synonymsList: [] | null;
}
interface RootState {
  synonyms: SynonymsReducer;
}

// This used to memorize the selector value
export const SynonymsSelector = createSelector(
  (state: RootState) => state.synonyms,
  (config: SynonymsReducer) => {
    return {
      loading: config.loading,
      synonymsList: config.synonymsList,
    };
  }
);
