import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';
import { initReactI18next } from 'react-i18next';

let i18nextLng = localStorage.getItem('i18nextLng');
i18next
  .use(Cache)
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init(
    {
      debug: process.env.NODE_ENV === 'development' ? false : false,
      fallbackLng: i18nextLng || 'de',
      supportedLngs: ['en', 'de', 'fr'],
      ns: [
        'alert',
        'account',
        'common',
        'dashboard',
        'easy_language',
        'glossary',
        'synonym',
        'gallery',
        'static_words',
        '404Page',
      ],
      defaultNS: 'alert',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        order: ['localStorage', 'cookie'],
        cache: ['localStorage', 'cookie'],
      },
      react: { useSuspense: false },
      interpolation: { escapeValue: false },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    }
  );

export default i18next;
