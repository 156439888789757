import { configureStore } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootreducers from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: rootreducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      sentry: sentryReduxEnhancer,
      serializableCheck: false,
    }),
});

export default store;
