import { useEffect, useState } from 'react';
import DropdownMenuComp from '../../dropdownMenuComp/DropdownMenuComp';
import {
  blockTypeToBlockName,
  reflectValue,
  supportedBlockTypes,
  // supportedBlockTypes,
} from '../fixedData';
import { useTranslation } from 'react-i18next';

// function dropDownActiveClass(active: boolean) {
//   if (active) return 'active dropdown-item-active';
//   else return '';
// }

const BlockOptionsDropdownList = ({
  editor,
  blockType,
  disabled,
}: {
  editor: any;
  blockType: string;
  disabled: boolean;
}) => {
  const { t } = useTranslation('easy_language');
  const [selectedValue, setSelectedValue] = useState<string>('paragraph');

  const handleClick = (value: string) => {
    let options: any = blockTypeToBlockName(t, editor, blockType);
    if (options && value) {
      options[`${value}`].handleClickMethod();
    }
    setSelectedValue(reflectValue[value]);
  };

  useEffect(() => {
    setSelectedValue(reflectValue[supportedBlockTypes[blockType]]);
  }, [blockType]);
  return (
    <DropdownMenuComp
      value={selectedValue}
      options={Object.values(blockTypeToBlockName(t, editor, blockType))}
      handleClick={handleClick}
      label={'extra-options'}
      selectStyle={{
        width: '5em',
        height: 'auto',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '& .MuiOutlinedInput-input': {
          fontSize: '.8rem',
          padding: '5px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '&:focus': {
          outline: 'blue',
        },
      }}
      itemStyle={{
        '& .MuiTypography-root': { fontSize: '0.8rem' },
      }}
      disabled={disabled}
    />
  );
};

export default BlockOptionsDropdownList;
