export const REFRESH_SESSION = 'REFRESH_SESSION';
export const SIGN_UP = 'SIGN_UP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY_SIGNUP_EMAIL = 'VERIFY_SIGNUP_EMAIL';
export const RESEND_VERIFY_EMAIL = 'RESEND_VERIFY_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_SESSION = 'GET_SESSION';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const VERIFY_OTP = 'VERIFY_OTP';

// No api call
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_USER = 'UPDATE_USER';
export const LOADING = 'LOADING_SUPABASE';
export const STOP_LOADING = 'STOP_LOADING_SUPABASE';
