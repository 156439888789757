import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { SynonymsSourceProps } from './synonyms.types';

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

const SynonymsSource = ({ sourceText }: SynonymsSourceProps) => {
  return (
    <Box
      sx={{
        typography: 'body1',
        fontSize: '10px',
      }}
      onClick={preventDefault}
    >
      {sourceText}:{' '}
      <Link
        href="https://www.openthesaurus.de"
        target="_blank"
        rel="noreferrer"
        sx={{color:'#1b252d !important'}}
      >
        OpenThesaurus
      </Link>{' '}
      (
      <Link
        href="https://www.gnu.org/licenses/old-licenses/lgpl-2.1.html"
        target="_blank"
        rel="noreferrer"
        sx={{color:'#1b252d !important'}}
      >
        LGPL Lizenz
      </Link>
      )
    </Box>
  );
};

export default SynonymsSource;
