// import axios from 'axios';
// import { API_ROOT } from './constants';
import http from '../config/httpComman';

export const getRequest = async (url, token, onSuccess, onError) => {
  await http
    .get(`${url}`)
    .then((response) => {
      return onSuccess(response.data);
    })
    .catch((error) => {
      //   console.log(error);
      return onError(error);
    });
};

export const postRequest = async (url, token, data, onSuccess, onError) => {
  await http
    .post(`${url}`, data)
    .then((response) => {
      return onSuccess(response.data);
    })
    .catch((error) => {
      //   console.log(error);
      return onError(error);
    });
};

export const multipartPostRequest = async (
  url,
  token,
  formData,
  onSuccess,
  onError
) => {
  let data = new FormData();
  for (let name in formData) {
    data.append(name, formData[name]);
  }
  http.defaults.headers['Content-Type'] = 'multipart/form-data';
  await http
    .post(`${url}`, data)
    .then((response) => {
      return onSuccess(response.data);
    })
    .catch((error) => {
      //   console.log(error);
      return onError(error);
    });
};
