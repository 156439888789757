import React from 'react';
import { Form } from 'react-bootstrap';

const FormField = React.forwardRef(
  (
    {
      // register
      name,
      onChange,
      onBlur,
      // custom
      label,
      placeholder,
      defaultValue,
      error,
      feedback,
      id,
    },
    ref
  ) => (
    <>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control
        type="text"
        className={feedback ? '' : 'mb-3'}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isInvalid={error}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        id={id}
      />
      {feedback ? (
        <Form.Control.Feedback
          className="mb-3"
          type="invalid"
        >
          {feedback}
        </Form.Control.Feedback>
      ) : (
        <></>
      )}
    </>
  )
);

export default FormField;
