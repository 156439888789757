import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const FormButton = (props) => {
  return (
    <Button
      className="float-right"
      type={props.type}
      onClick={props.onClick}
      disabled={props.loading}
    >
      {props.loading ? (
        <Spinner
          animation="border"
          role="status"
        >
          <span className="visually-hidden"></span>
        </Spinner>
      ) : (
        <>{props.label}</>
      )}
    </Button>
  );
};

export default FormButton;
