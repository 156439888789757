import htmlDocx from 'html-docx-js/dist/html-docx';

export const generateDocxFromHtml = (
  htmlContent: string,
  filename: string = 'summ-ai'
) => {
  // Define the header and footer for the Word document
  const header = `
   <html xmlns:o='urn:schemas-microsoft-com:office:office'
         xmlns:w='urn:schemas-microsoft-com:office:word'
         xmlns='http://www.w3.org/TR/REC-html40'>
   <head><meta charset='utf-8'><title>Document</title><style> 
   table {
      border: none;
      border-color:#fff;
      }

    table td, table th {
      border-color:#fff !important;
      }
        </style></head><body>`;
  const footer = `</body></html>`;

  // Combine the header, content, and footer
  const fullHtml = header + htmlContent + footer;

  // Create a Blob from the HTML string
  const blob = htmlDocx.asBlob(fullHtml, fullHtml);

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.docx`;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
