import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const Imprint = () => {
  return (
    <>
      <Container style={{ textAlign: 'left', paddingTop: '1em' }}>
        <Row>
          <Col>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>
              SUMM AI GmbH
              <br />
              Tal 44
              <br />
              80331 München
            </p>

            <p>
              Registergericht: Amtsgericht M&uuml;nchen
              <br />
              Registernummer: HRB 275514
            </p>

            <p>
              <strong>Vertreten durch:</strong>
              <br />
              Flora Geske
              <br />
              Vanessa Theel
              <br />
              Nicholas Wolf
            </p>

            <h2>Kontakt</h2>
            <p>
              Telefon: 0178 3674624
              <br />
              E-Mail: team@summ-ai.com
            </p>

            <h2>Umsatzsteuer-ID</h2>
            <p>
              Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
              Umsatzsteuergesetz:
              <br />
              DE352814545
            </p>

            <h2>
              Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
            </h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>

            <p>
              Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
            </p>

            <p>
              <Link to="/dashboard">Zurück</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Imprint;
