import React from 'react';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import Grid from '@mui/material/Grid';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadButton from './UploadButton';

type Props = {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ImagePlaceholder = ({ handleFileUpload }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('easy_language');

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        position: 'absolute',
        top: '30%',
      }}
    >
      <Grid
        item
        xs={12}
      >
        <InsertPhotoOutlinedIcon
          sx={{ fontSize: '7em', color: theme.palette.general.lightSteelBlue }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="subtitle1"
          fontSize={'1.3rem'}
          color={theme.palette.primary.main}
        >
          {t('noImage')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <UploadButton handleFileUpload={handleFileUpload} />
      </Grid>
    </Grid>
  );
};

export default ImagePlaceholder;
