export const InsertToString = (
  str: string,
  value: { [key: string]: string | number }
): string => {
  let result = str;
  Object.keys(value)?.forEach((key, index: number) => {
    const valueParams = ['field', 'value', 'operation'].includes(key)
      ? `${key}=["${value[key]}"]`
      : `${key}=${value[key]}`;
    result = result + (index === 0 ? `?${valueParams}` : `&${valueParams}`);
  });
  return result;
};
