import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import bannerLogo from '../../../assets/logo_dark.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClick: () => void;
};

const UpdateAppPopup: FC<Props> = (props: Props) => {
  const { t } = useTranslation(['dashboard', 'common']);
  return (
    <Modal
      show={props.show}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
      className="text-center"
    >
      <Modal.Body className="p-4">
        <img
          src={bannerLogo}
          alt="Logo SUMM"
        />
        <h4 className="mt-1">
          <span
            dangerouslySetInnerHTML={{
              __html: t('updateAppAlert', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </h4>
        <Button
          id="closeWhatsnewPopup"
          onClick={props.onClick}
          className="mt-5 mb-5"
        >
          {t('update', { ns: 'common' })}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAppPopup;
