import { Grid, Pagination, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImageListItemView from 'src/coreUI/ImageListItemView/ImageListItemView';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  deleteGalleryImage,
  fetchMyGallery,
} from 'src/redux/myGallery/actions';
import { MyGallerySelector } from 'src/redux/myGallery/selector';
import {
  addImageUpload,
  downloadUploadedImage,
  setImageUploadList,
} from 'src/redux/imageUpload/actions';
import OverlayLoadingHOC from 'src/coreUI/overlayLoadingHOC/OverlayLoadingHOC';
import ImagePlaceholder from './ImagePlaceholder';
import { UploadImageSelector } from 'src/redux/imageUpload/selector';
import UploadButton from './UploadButton';
import { enqueueSnackbar } from 'notistack';
type Props = {};

const ImagePageWrapper = (props: Props) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const matchmd = theme.breakpoints.down('md');
  const galleryList = useSelector(
    (state: any) => MyGallerySelector(state).galleryList,
    shallowEqual
  );
  const count = useSelector(
    (state: any) => MyGallerySelector(state).count,
    shallowEqual
  );
  const currentPage = useSelector(
    (state: any) => MyGallerySelector(state).currentPage,
    shallowEqual
  );
  const loading = useSelector(
    (state: any) => MyGallerySelector(state).loading,
    shallowEqual
  );

  const tempImageListObj = useSelector(
    (state: any) => UploadImageSelector(state).tempImageList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchMyGallery());
  }, [dispatch]);

  const handleNavigation = (page: number) => {
    dispatch(
      fetchMyGallery({
        page: page,
        page_size: '10',
      })
    );
  };

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let myFiles: FileList | null = e.target.files;
      let tempImageList = {};
      let files: File[] = [];
      if (myFiles) {
        for (let i = 0; i < myFiles?.length; i++) {
          tempImageList = {
            ...tempImageList,
            [myFiles[i].name]: { name: myFiles[i].name },
          };
          files.push(myFiles[i]);
        }
      }
      dispatch(addImageUpload(files, 'gallery'));
      dispatch(setImageUploadList({ ...tempImageListObj, ...tempImageList }));
      enqueueSnackbar({
        variant: 'reportComplete',
        autoHideDuration: null,
        preventDuplicate: true,
      });
    },
    [dispatch, tempImageListObj]
  );

  return (
    <Grid
      item
      container
      xs={12}
      spacing={4}
      mt={'1em'}
      p={'1em'}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontSize: '2rem' }}
        >
          {t('nav.myGallery')}
        </Typography>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sx={{
          justifyContent: 'start',
          display: 'flex',
        }}
      >
        <InputComponent
          value={searchVal}
          handleChange={(e) => setSearchVal(e?.target.value)}
          endIcon={
            <SearchIcon sx={{ color: theme.palette.general.paleBlue }} />
          }
          style={{
            width: '20%',
          }}
          placeholder={t('search')}
        />
      </Grid> */}
      {Object.values(galleryList?.images ?? {}).length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <UploadButton handleFileUpload={handleFileUpload} />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        pt={'0 !important'}
      >
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative',
            padding: '1em',
            mt: '1em',
            mb: '1em',
            minHeight: '60vh',
            border: `1px solid ${theme.palette.general.paleBlue}`,
            borderRadius: '5px',
          }}
        >
          {count > 0 ? (
            <OverlayLoadingHOC loading={loading}>
              <ImageListItemView
                imageItem={galleryList}
                handleDelete={(id: string) => dispatch(deleteGalleryImage(id))}
                cols={matchmd ? 5 : 2}
                handleDownload={(id: string) =>
                  dispatch(downloadUploadedImage(id))
                }
                curentPage={currentPage}
              />
            </OverlayLoadingHOC>
          ) : (
            <ImagePlaceholder handleFileUpload={handleFileUpload} />
          )}
        </Grid>
        {count > 0 && (
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'end'}
          >
            <Pagination
              count={Math.ceil(count / Number(10))}
              onChange={(_, page) => handleNavigation(page)}
              page={currentPage}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ImagePageWrapper;
