import { createSelector } from 'reselect';

export interface MyGalleryReducer {
  loading: boolean;
  galleryList: null | { images: { uuid: string }[]; is_owner: boolean };
  count: number;
  nextPage: null | string;
  previousPage: null | string;
  currentPage: number;
  entries: string;
}
interface RootState {
  myGallery: MyGalleryReducer;
}

// This used to memorize the selector value
export const MyGallerySelector = createSelector(
  (state: RootState) => state.myGallery,
  (config: MyGalleryReducer) => {
    return {
      loading: config.loading,
      galleryList: config.galleryList,
      count: config.count,
      nextPage: config.nextPage,
      previousPage: config.previousPage,
      currentPage: config.currentPage,
      entries: config.entries,
    };
  }
);
