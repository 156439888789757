import TextField from '@mui/material/TextField';
import React from 'react';

type Props = {
  id?: string;
  value: string;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlekeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  variant?: 'outlined' | 'filled' | undefined;
  label?: string;
  placeholder?: string;
  style?: object;
  endIcon?: React.ReactNode | null;
  disable?: boolean;
  name?: string;
  require?: boolean;
  error?: boolean;
};

const InputComponent = ({
  id = 'outlined-basic',
  value,
  handleChange,
  variant = 'outlined',
  label,
  style = {},
  endIcon,
  disable = false,
  name,
  require,
  error,
  handlekeyUp,
  placeholder,
}: Props) => {
  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      variant={variant}
      value={value}
      onChange={handleChange}
      sx={style}
      size="small"
      InputProps={{
        endAdornment: endIcon,
      }}
      disabled={disable}
      name={name}
      required={require}
      error={error}
      onKeyUp={handlekeyUp}
    />
  );
};

export default InputComponent;
