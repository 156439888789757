import { useEffect } from 'react';
import { setTabIndex } from '../utils/setTabindex';

const useTabIndex = () => {
  useEffect(() => {
    // Initial setting of tabindex
    setTabIndex();

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(() => {
      setTabIndex();
    });

    // Start observing the document body for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);
};

export default useTabIndex;
