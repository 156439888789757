import { Pagination } from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { GlossaryEntriesSelector } from 'src/redux/glossaryEntries/selector';
import { getGlossaryEtnries } from 'src/redux/glossaryEntries/actions';

type Props = {};

const PaginationComp = (props: Props) => {
  const dispatch: any = useDispatch();

  const currentpage = useSelector(
    (state: any) => GlossaryEntriesSelector(state).currentPage,
    shallowEqual
  );

  const entries = useSelector(
    (state: any) => GlossaryEntriesSelector(state).entries,
    shallowEqual
  );

  const filterValue = useSelector(
    (state: any) => GlossaryEntriesSelector(state).filters,
    shallowEqual
  );

  const handleNavigation = (page: number) => {
    dispatch(
      getGlossaryEtnries({
        page: page,
        page_size: Number(entries),
        ...(filterValue ? filterValue : {}),
      })
    );
  };

  const count = useSelector(
    (state: any) => GlossaryEntriesSelector(state).count,
    shallowEqual
  );
  return (
    <Pagination
      count={Math.ceil(count / Number(entries))}
      onChange={(_, page) => handleNavigation(page)}
      page={currentpage}
    />
  );
};

export default PaginationComp;
